import React from 'react'
import { isNil } from 'ramda'
import { Typography, Grid } from '@mui/material'
import BeslutChip from '@local/Components/BeslutChip'
import { useIsBelowMediumScreens, usePickEpiContent } from '@local/Utils/Hooks'
import { IAnstallningProps } from '@local/Views/UppdragDetaljvy/AnsokanCard/Anstallning/Anstallning.types'
import { IUppdragDetaljvyContent } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import { formatPercentageNumber } from '@local/Utils/Helpers'

const Medarbetare = ({ ansokan }: IAnstallningProps): JSX.Element => {
  const { anstallningsinformation, header } =
    usePickEpiContent<IUppdragDetaljvyContent>()
  const isBelowMediumScreens = useIsBelowMediumScreens()

  const getBooleanString = (value: boolean) =>
    value ? anstallningsinformation.ja : anstallningsinformation.nej

  return (
    <Grid item md={4}>
      {isBelowMediumScreens && (
        <>
          <Grid item xs={12} py={2}>
            <Typography variant="subtitle1">{header.personnummer}</Typography>
            <Typography variant="subtitle2">
              {ansokan.personuppgifter.personnummer}
            </Typography>
          </Grid>
          <Grid item xs={12} py={2}>
            <Typography variant="subtitle1">
              {header.beslutRadgivning}
            </Typography>
            <Typography variant="subtitle2">
              <BeslutChip beslut={ansokan.beslutRadgivning} />
            </Typography>
          </Grid>
          <Grid item xs={12} py={2}>
            <Typography variant="subtitle1">{header.beslutAge}</Typography>
            <Typography variant="subtitle2">
              <BeslutChip beslut={ansokan.beslutAge} />
            </Typography>
          </Grid>
          <Grid item xs={12} py={2}>
            <Typography variant="subtitle1">{header.inskickadAv}</Typography>
            <Typography variant="subtitle2">{ansokan.inskickadAv}</Typography>
          </Grid>
        </>
      )}

      <Grid item xs={12} py={2}>
        <Typography variant="subtitle1">
          {anstallningsinformation.epostadress}
        </Typography>
        <Typography variant="subtitle2">
          {ansokan.personuppgifter.epostadress}
        </Typography>
      </Grid>

      <Grid item xs={12} py={2}>
        <Typography variant="subtitle1">
          {anstallningsinformation.isTjansteman}
        </Typography>
        <Typography variant="subtitle2">
          {!isNil(ansokan.anstallningsuppgifter.isTjansteman) &&
            getBooleanString(ansokan.anstallningsuppgifter.isTjansteman)}
        </Typography>
      </Grid>
      <Grid item xs={12} py={2}>
        <Typography variant="subtitle1">
          {anstallningsinformation.isVdEllerUndantagsgrupp}
        </Typography>
        <Typography variant="subtitle2">
          {!isNil(ansokan.anstallningsuppgifter.isVdEllerUndantagsgrupp) &&
            getBooleanString(
              ansokan.anstallningsuppgifter.isVdEllerUndantagsgrupp
            )}
        </Typography>
      </Grid>
      {!isNil(ansokan.anstallningsuppgifter.agarandel) && (
        <Grid item xs={12} py={2}>
          <Typography variant="subtitle1">
            {anstallningsinformation.agarandel}
          </Typography>
          <Typography variant="subtitle2">
            {formatPercentageNumber(ansokan.anstallningsuppgifter.agarandel)}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default Medarbetare
