import React, { useCallback } from 'react'
import Spinner from '@local/Components/Spinner'
import Gutter from '@local/Components/Gutter'
import WysiwygEditor from '@local/Components/WysiwygEditor'
import { Button, Grid, Typography } from '@mui/material'
import { usePickEpiContent, useSignalRConnectToGroup } from '@local/Utils/Hooks'
import Header from '@local/Views/DinaUppdrag/Header'
import { IDinaUppdragContent } from '@local/Views/DinaUppdrag/DinaUppdrag.types'
import Uppdrag from '@local/Views/DinaUppdrag/Uppdrag'
import useGetDinaUppdrag from '@local/Views/DinaUppdrag/useGetDinaUppdrag'
import { SignalRRoom } from '@local/App.types'
import { useUser } from '@trr/app-shell-data'
import Alert from '@local/Components/Alert'

const DinaUppdrag = (): JSX.Element => {
  const {
    skapaNyttUppdrag,
    ingress,
    nagotGickFel,
    arbetsgivaransokanLinks: { skapaUppdragUrl },
  } = usePickEpiContent<IDinaUppdragContent>()

  const { data: dinaUppdrag, isLoading, isError, refetch } = useGetDinaUppdrag()
  const { kundProperties } = useUser()

  useSignalRConnectToGroup({
    room: SignalRRoom.UppdragUpdated,
    group: kundProperties?.foretag?.organizationNumber,
    onEventReceived: useCallback(() => {
      void refetch()
    }, [refetch]),
  })

  if (isLoading) {
    return <Spinner centered />
  }

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  return (
    <section>
      <Grid container>
        <Grid item md={8}>
          <Typography variant="preamble">
            <WysiwygEditor htmlFormattedString={ingress.mainBody} />
          </Typography>
        </Grid>
      </Grid>

      <Gutter offset_xs={40} />

      <Header />

      <Gutter offset_xs={48} />

      <Button href={skapaUppdragUrl}>{skapaNyttUppdrag.heading}</Button>

      <Gutter offset_xs={40} />

      {dinaUppdrag?.map((uppdrag, index) => (
        <article key={uppdrag.id} aria-label="Uppdragskort">
          <Uppdrag uppdrag={uppdrag} />

          {dinaUppdrag?.length - 1 !== index && <Gutter offset_xs={24} />}
        </article>
      ))}
    </section>
  )
}

export default DinaUppdrag
