import React, { useEffect, useState } from 'react'
import { Box, useTheme } from '@mui/material'
import {
  IDetaljvyContent,
  IUppdragsAnsokanApiModel,
} from '@local/Views/Detaljvy/Detaljvy.types'
import OmAnstallningen from '@local/Views/Detaljvy/AnsokanList/AnsokanCard/OmAnstallningen'
import Divider from '@local/Components/Divider'
import OmUppsagningen from '@local/Views/Detaljvy/AnsokanList/AnsokanCard/OmUppsagningen'
import Ersattning from '@local/Views/Detaljvy/AnsokanList/AnsokanCard/Ersattning'
import Kontaktuppgifter from '@local/Views/Detaljvy/AnsokanList/AnsokanCard/Kontaktuppgifter'
import { useGetAnsokanQuery } from '@local/services/API/skapaAnsokanApi'
import Toaster from '@local/Components/Toaster'
import { usePickEpiContent } from '@local/Utils/Hooks'
import useGetUppdrag from '@local/Views/UppdragDetaljvy/useGetUppdrag'
import { OrsakerTillUppsagning } from '@local/Common.types'

const AnsokanCard = ({
  row,
}: {
  row: IUppdragsAnsokanApiModel
}): JSX.Element => {
  const theme = useTheme()

  const { nagotGickFel } = usePickEpiContent<IDetaljvyContent>()

  const [toasterOpen, setToasterOpen] = useState(false)
  const { isError } = useGetAnsokanQuery(row.id)

  const { data: uppdrag, error: isErrorUppdrag } = useGetUppdrag()

  const isTioTbAnsokan =
    uppdrag?.orsakTillUppsagning ===
    OrsakerTillUppsagning.TidsbegransadAnstallning

  useEffect(() => {
    if (isError || isErrorUppdrag) {
      setToasterOpen(true)
    } else {
      setToasterOpen(false)
    }
  }, [isError, isErrorUppdrag])

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.surface?.grey,
        padding: '24px 48px',
      }}
    >
      <OmAnstallningen row={row} />

      <Divider offset_xs={32} />

      {!isTioTbAnsokan && (
        <>
          <OmUppsagningen row={row} />
          <Divider offset_xs={32} />
        </>
      )}

      <Ersattning row={row} />

      <Divider offset_xs={32} />

      <Kontaktuppgifter row={row} />

      <Toaster
        message={nagotGickFel.heading}
        open={toasterOpen}
        setOpen={setToasterOpen}
      />
    </Box>
  )
}
export default AnsokanCard
