import React, { useCallback, useMemo, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import {
  GridActionsCellItem,
  GridApiPro,
  GridColDef,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid-pro'
import {
  useGetDateCells,
  useGetDropdownCells,
  useGetNumberCells,
  useGetTextCells,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/hooks/columnSettings'
import { Typography } from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import {
  ISkapaAnsokningarContent,
  ISkapaAnsokningarFormValues,
} from '@local/Views/SkapaAnsokningar/types/types'
import { useFormikContext } from 'formik'
import { equals, isNil } from 'ramda'
import { usePutFilAnsokanMutation } from '@local/services/API/filAnsokanApi'
import { UUIDRegex, matchStringByRegex } from '@local/Utils/Helpers'
import { getMappedFilAnsokanFormValues } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/FilAnsokanFormValuesMapper'
import { waitForElementToRender } from '@local/Utils/Helpers/waitForElementToRender'
import { useSelector } from 'react-redux'
import { selectErrorStepper } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/ErrorStepper/errorStepperSlice'

const useGetColumnHeaders = (apiRef?: React.MutableRefObject<GridApiPro>) => {
  const [taBortAnsokanModalState, setTaBortAnsokanModalState] = useState({
    open: false,
    currentRowId: null,
  })
  const [filansokanErrorToaster, setFilansokanErrorToaster] = useState<{
    open: boolean
    message: string | null
  }>({
    open: false,
    message: null,
  })
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})

  const [putFilAnsokan] = usePutFilAnsokanMutation()

  const { values, initialValues, setValues, errors } =
    useFormikContext<ISkapaAnsokningarFormValues>()

  const { errors: stepperErrors } = useSelector(selectErrorStepper)

  const {
    sammanfattning: { columnHeaders: columnHeadersContent },
    nagotGickFel,
  } = usePickEpiContent<ISkapaAnsokningarContent>()

  const uppdragsId = matchStringByRegex(window.location.pathname, UUIDRegex)

  const setRowModeView = useCallback(
    (id: GridRowId) => {
      setRowModesModel({
        [id]: { mode: GridRowModes.View },
      })
    },
    [setRowModesModel]
  )

  const isAnyRowInEditMode = Object.values(rowModesModel).some(
    (row) => row.mode === GridRowModes.Edit
  )

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      const row = values.filansokningar.find(
        (filansokan) => filansokan.id === id
      )

      const isError =
        errors?.filansokningar && !isNil(errors.filansokningar[row.index])

      if (isError) {
        const errorsSorted = Object.keys(errors.filansokningar[row.index]).sort(
          (a, b) =>
            stepperErrors.indexOf(
              stepperErrors.find((error) => error.id === a)
            ) -
            stepperErrors.indexOf(stepperErrors.find((error) => error.id === b))
        )

        const errorToHighlight = errorsSorted[0]

        const colIndex = apiRef?.current.getColumnIndex(errorToHighlight)

        apiRef?.current.scrollToIndexes({
          rowIndex: row.index,
          colIndex: colIndex,
        })

        const elementId = `[data-rowindex="${row.index}"] [data-field="${errorToHighlight}"]`

        waitForElementToRender(elementId, () => {
          const elementToFocus =
            document.querySelector<HTMLInputElement>(elementId)
          elementToFocus.focus()
        })

        return
      }

      if (equals(initialValues.filansokningar, values.filansokningar)) {
        setRowModeView(id)
        return
      }

      putFilAnsokan({
        uppdragsId: uppdragsId,
        fileId: values.file.id,
        rowId: row.id,
        rowData: getMappedFilAnsokanFormValues(row),
      })
        .unwrap()
        .then(() => {
          setRowModeView(id)
        })
        .catch((err) => {
          setFilansokanErrorToaster({
            open: true,
            message: nagotGickFel.heading,
          })
          console.log(err)
        })
    },
    [
      apiRef,
      errors.filansokningar,
      nagotGickFel.heading,
      putFilAnsokan,
      setRowModeView,
      uppdragsId,
      values.file,
      values.filansokningar,
      initialValues,
      stepperErrors,
    ]
  )

  const handleDeleteClick = useCallback(
    (id: GridRowId) => () => {
      setTaBortAnsokanModalState({ open: true, currentRowId: id })
    },
    []
  )

  const handleEditClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ [id]: { mode: GridRowModes.Edit } })
    },
    []
  )

  const handleCancelClick = useCallback(
    (id: GridRowId) => () => {
      const previousValues: ISkapaAnsokningarFormValues = {
        ...values,
        filansokningar: values.filansokningar.map((filansokan) => {
          if (filansokan.id === id) {
            return {
              ...initialValues.filansokningar[filansokan.index],
              namn: values.filansokningar[filansokan.index].namn,
            }
          } else {
            return filansokan
          }
        }),
      }

      setValues(previousValues).catch((err) => console.log(err))

      setRowModeView(id)
    },
    [initialValues, setRowModeView, setValues, values]
  )

  const { namn, personnummer, epostadress } = useGetTextCells()

  const {
    arbetstimmarPerVecka,
    anstallningsgrad,
    uppsagningsgrad,
    manadslon,
    provisionTantiemBelopp,
    skiftJourOBTillaggBelopp,
    avgangsvederlag,
  } = useGetNumberCells()

  const {
    anstallningsdatum,
    uppsagningsdatum,
    sistaAnstallningsdatum,
    lonSenastAndrad,
  } = useGetDateCells()

  const { hasTjanstemannaavtal, isVdEllerUndantagsgrupp, sjukersattningsgrad } =
    useGetDropdownCells()

  const columnHeaders: GridColDef[] = useMemo(
    () => [
      namn,
      personnummer,
      epostadress,
      hasTjanstemannaavtal,
      isVdEllerUndantagsgrupp,
      anstallningsdatum,
      uppsagningsdatum,
      sistaAnstallningsdatum,
      arbetstimmarPerVecka,
      anstallningsgrad,
      sjukersattningsgrad,
      uppsagningsgrad,
      manadslon,
      lonSenastAndrad,
      provisionTantiemBelopp,
      skiftJourOBTillaggBelopp,
      avgangsvederlag,
      {
        field: 'action',
        type: 'actions',
        headerName: columnHeadersContent.actions,
        minWidth: 110,
        cellClassName: 'actions',
        renderHeader: () => (
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            {columnHeadersContent.actions}
          </Typography>
        ),
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: 'primary.main',
                }}
                key="save-edit-row"
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                color="inherit"
                key="cancel-edit-row"
                onClick={handleCancelClick(id)}
              />,
            ]
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              color="inherit"
              key="edit-row"
              disabled={isAnyRowInEditMode}
              onClick={handleEditClick(id)}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              color="inherit"
              key="delete-row"
              disabled={isAnyRowInEditMode}
              onClick={handleDeleteClick(id)}
            />,
          ]
        },
        align: 'left',
      },
    ],
    [
      anstallningsdatum,
      anstallningsgrad,
      arbetstimmarPerVecka,
      avgangsvederlag,
      columnHeadersContent.actions,
      epostadress,
      handleCancelClick,
      handleDeleteClick,
      handleSaveClick,
      hasTjanstemannaavtal,
      isAnyRowInEditMode,
      isVdEllerUndantagsgrupp,
      lonSenastAndrad,
      manadslon,
      namn,
      personnummer,
      provisionTantiemBelopp,
      rowModesModel,
      handleEditClick,
      sistaAnstallningsdatum,
      sjukersattningsgrad,
      skiftJourOBTillaggBelopp,
      uppsagningsdatum,
      uppsagningsgrad,
    ]
  )

  return {
    columnHeaders,
    rowModesModel,
    setRowModesModel,
    handleCancelClick,
    handleSaveClick,
    handleDeleteClick,
    handleEditClick,
    taBortAnsokanModalState,
    setTaBortAnsokanModalState,
    filansokanErrorToaster,
    setFilansokanErrorToaster,
    isAnyRowInEditMode,
  }
}

export default useGetColumnHeaders
