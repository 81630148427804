import { AnsokanKeys } from '@local/Common.types'
import { booleanToRadioChoice } from '@local/Utils/Helpers/Forms/Forms.helpers'
import { IMedarbetareFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Medarbetare/Medarbetare.types'
import { IAnsokan } from '@local/services/API/ansokan/types'
import { IUniqueFile } from '@local/services/API/bilagor/types'

export const mapAnsokanToMedarbetareFormValues = (
  ansokan: IAnsokan,
  bilagor: IUniqueFile[]
): IMedarbetareFormValues => ({
  [AnsokanKeys.Epostadress]: ansokan.personuppgifter.epostadress,
  [AnsokanKeys.HasTjanstemannaavtal]: booleanToRadioChoice(
    ansokan.anstallningsuppgifter.isTjansteman
  ),
  [AnsokanKeys.IsVdEllerUndantagsgrupp]: booleanToRadioChoice(
    ansokan.anstallningsuppgifter.isVdEllerUndantagsgrupp
  ),
  [AnsokanKeys.OverenskommelseBilagor]: bilagor,
})
