import * as Yup from 'yup'
import { ILonFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Lon/Lon.types'
import { getYupCurrentSchemaValues } from '@local/Utils/Helpers/Forms/Forms.helpers'
import {
  AnsokanKeys,
  ICommonAnsokanValideringContent,
  ICommonDatumValideringContent,
  RadioChoice,
} from '@local/Common.types'
import { lonTioTbValidationSchema } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Lon/LonTioTb.schema'
import { lonRiskValidationSchema } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Lon/LonRisk.schema'
import { ISkapaAnsokanFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import { toTrrDateOrDefault } from '@local/Utils/Helpers/formatDate'
import { isAfter, isBefore, isValid, subDays } from 'date-fns'
import {
  dayAfterDate,
  dayBeforeDate,
  startDate,
  tomorrow,
} from '@local/Utils/Helpers'

export const lonValidationSchema = (
  ansokanValidering: ICommonAnsokanValideringContent,
  datumValidering: ICommonDatumValideringContent,
  isRisk: boolean,
  isTB: boolean
): Yup.ObjectSchema<ILonFormValues> => {
  const lonSchema = Yup.object().shape({
    [AnsokanKeys.Manadslon]: Yup.number()
      .nullable()
      .typeError(ansokanValidering.manadslonSaknas)
      .required(ansokanValidering.manadslonSaknas)
      .positive(ansokanValidering.manadslonMinAmountRequired)
      .max(999999, ansokanValidering.manadslonMaxAmountExceeded)
      .integer(ansokanValidering.manadslonInvalidFormat),
    [AnsokanKeys.LonSenastAndrad]: Yup.date()
      .typeError(ansokanValidering.lonSenastAndradSaknas)
      .required(ansokanValidering.lonSenastAndradSaknas)
      .test('validateMinDate', datumValidering.warningMinDate, (date) =>
        isAfter(date, subDays(startDate, 1))
      )
      .test(
        'minDateCanNotOccurBeforeAnstallningsdatum',
        datumValidering.warningMinDate,
        (date, context) => {
          const schema =
            getYupCurrentSchemaValues<ISkapaAnsokanFormValues>(context)

          return isValid(schema.anstallningsdatum)
            ? isAfter(
                date,
                dayBeforeDate(toTrrDateOrDefault(schema.anstallningsdatum))
              )
            : true
        }
      )
      .test(
        'maxDateCanNotOccurAfterSistaAnstallningsdatum',
        datumValidering.warningMaxDate,
        (date, context) => {
          const schema =
            getYupCurrentSchemaValues<ISkapaAnsokanFormValues>(context)

          return isValid(schema.sistaAnstallningsdatum)
            ? isBefore(
                date,
                dayAfterDate(toTrrDateOrDefault(schema.sistaAnstallningsdatum))
              )
            : true
        }
      )
      .test(
        'maxDateCanNotOccurAfterToday',
        datumValidering.warningMaxDate,
        (date) => isBefore(date, tomorrow)
      ),
    [AnsokanKeys.ProvisionTantiemBelopp]: Yup.number()
      .nullable()
      .min(0, ansokanValidering.provisionTantiemBeloppMinAmountRequired)
      .max(999999, ansokanValidering.provisionTantiemBeloppMaxAmountExceeded)
      .integer(ansokanValidering.provisionTantiemBeloppInvalidFormat),
    [AnsokanKeys.SkiftJourOBTillaggBelopp]: Yup.number()
      .nullable()
      .min(0, ansokanValidering.skiftJourOBTillaggBeloppMinAmountRequired)
      .max(999999, ansokanValidering.skiftJourOBTillaggBeloppMaxAmountExceeded)
      .integer(ansokanValidering.skiftJourOBTillaggBeloppInvalidFormat),
    [AnsokanKeys.HasAvgangsvederlag]: Yup.mixed<RadioChoice>()
      .nullable()
      .required(ansokanValidering.avgangsvederlagSaknas),
    [AnsokanKeys.Avgangsvederlag]: Yup.number()
      .nullable()
      .typeError(ansokanValidering.avgangsvederlagBeloppSaknas)
      .required(ansokanValidering.avgangsvederlagBeloppSaknas)
      .positive(ansokanValidering.avgangsvederlagMinAmountRequired)
      .integer(ansokanValidering.avgangsvederlagInvalidFormat)
      .max(99999999, ansokanValidering.avgangsvederlagMaxAmountExceeded)
      .when(['hasAvgangsvederlag'], {
        is: RadioChoice.Nej,
        then: (value: Yup.NumberSchema) => value.notRequired(),
      }),
  })

  if (isRisk) {
    const riskSchema = lonRiskValidationSchema(lonSchema)
    return lonSchema.concat(riskSchema)
  }

  if (isTB) {
    const tioTbSchema = lonTioTbValidationSchema(lonSchema)
    return lonSchema.concat(tioTbSchema)
  }

  return lonSchema
}
