import {
  AnsokanKeys,
  FormikWatcherValues,
  ISelectOption,
  RadioChoice,
} from '@local/Common.types'
import { trrDateUTC } from '@local/Utils/Helpers/formatDate'
import { IMiddlewareField } from '@local/Utils/Hooks/useFormikWatch'
import { ISkapaAnsokanApiModel } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import { isEmpty, isNil } from 'ramda'

//Middleware to be used by formik watcher to transform data before patching to API
export const skapaAnsokanWatcherMiddleware = (
  field: IMiddlewareField<FormikWatcherValues>,
  next: (
    field: IMiddlewareField<FormikWatcherValues>
  ) => Partial<ISkapaAnsokanApiModel>
): Partial<ISkapaAnsokanApiModel> => {
  switch (field.id as AnsokanKeys) {
    case AnsokanKeys.OverenskommelseBilagor:
      return

    case AnsokanKeys.Anstallningsdatum:
    case AnsokanKeys.SistaAnstallningsdatum:
    case AnsokanKeys.Uppsagningsdatum:
      return { [field.id]: trrDateUTC(field.value as Date).toISOString() }

    case AnsokanKeys.HasSjukersattningsgrad: {
      if (field.value === RadioChoice.Nej)
        return {
          sjukersattningsgrad: null,
          hasSjukersattningsgrad: false,
        }
      else return { hasSjukersattningsgrad: true }
    }

    case AnsokanKeys.Sjukersattningsgrad: {
      if (!isNil(field.value))
        return {
          sjukersattningsgrad: (field.value as ISelectOption).value as number,
        }
      else return
    }

    case AnsokanKeys.HasAvgangsvederlag: {
      if (field.value === RadioChoice.Nej)
        return { avgangsvederlag: null, hasAvgangsvederlag: false }
      else return { hasAvgangsvederlag: true }
    }

    case AnsokanKeys.Avgangsvederlag: {
      if (!isNil(field.value))
        return {
          avgangsvederlag: field.value as number,
        }
      else return
    }
    case AnsokanKeys.ProvisionTantiemBelopp:
      return {
        provisionBelopp: isEmpty(field.value) ? null : (field.value as number),
      }

    case AnsokanKeys.SkiftJourOBTillaggBelopp:
      return {
        jourBelopp: isEmpty(field.value) ? null : (field.value as number),
      }

    case AnsokanKeys.LonSenastAndrad:
      return {
        lonerevisionsdatum: trrDateUTC(field.value as Date).toISOString(),
      }
    default:
      return next(field)
  }
}
