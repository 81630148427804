import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@mui/material'
import { IUppdragDetaljvyContent } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import { ITaBortModalOpenProps } from '@local/Views/UppdragDetaljvy/AnsokanCard/TaBortAnsokanModal/TaBortAnsokanModal.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import Alert from '@local/Components/Alert'

const TaBortAnsokanModal = ({
  ansokanId,
  taBortModalOpen,
  handleTaBort,
  setTaBortModalOpen,
  isError,
}: ITaBortModalOpenProps): JSX.Element => {
  const { taBortModal, nagotGickFel } =
    usePickEpiContent<IUppdragDetaljvyContent>()
  const handleTaBortFunction = () => {
    handleTaBort(ansokanId).catch((error) => {
      console.error('Error when deleting ansokan', error)
    })
  }
  return (
    <Dialog open={taBortModalOpen}>
      <DialogTitle>{taBortModal.heading}</DialogTitle>
      <DialogContent>
        <DialogContentText>{taBortModal.description}</DialogContentText>
        {isError && <Alert severity="error">{nagotGickFel.heading}</Alert>}
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={() => setTaBortModalOpen(false)}>
          {taBortModal.cancelText}
        </Button>
        <Button
          variant="text"
          disabled={isError}
          onClick={handleTaBortFunction}
        >
          {taBortModal.confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TaBortAnsokanModal
