import React from 'react'
import { isNil } from 'ramda'
import { Typography } from '@mui/material'
import Gutter from '@local/Components/Gutter'
import Tooltip from '@local/Components/Tooltip/Tooltip'
import { IUppsagningsgradProps } from '@local/Components/Uppsagningsgrad/types'
import NumberInput from '@local/Components/NumberInput/NumberInput'
import Alert from '@local/Components/Alert'

const Uppsagningsgrad = ({
  value,
  formikName,
  formikContext,
  uppsagningsgrad,
  errorMsg,
  error,
}: IUppsagningsgradProps): JSX.Element => (
  <div>
    <Tooltip
      variant="subtitle1"
      heading={uppsagningsgrad.heading}
      tooltipContent={
        <Typography variant="body1">{uppsagningsgrad.tooltip}</Typography>
      }
    />

    <Typography variant="body2">{uppsagningsgrad.label}</Typography>

    <Gutter offset_xs={16} />

    <NumberInput
      formikContext={formikContext}
      label={uppsagningsgrad.heading}
      placeholder={uppsagningsgrad.placeholder}
      name={formikName}
      value={value}
      error={error}
      errorMsg={errorMsg}
    />

    {isNil(errorMsg) && !isNil(value) && value < 100 && (
      <>
        <Gutter offset_xs={16} />
        <Alert severity="warning">{uppsagningsgrad.warning}</Alert>
        <Gutter offset_xs={16} />
      </>
    )}
  </div>
)

export default Uppsagningsgrad
