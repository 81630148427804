import React from 'react'
import useGetUppdragIsRisk from '@local/Views/UppdragDetaljvy/useGetUppdragIsRisk'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IUppdragDetaljvyContent } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import HTMLMapper from '@local/Components/HTMLMapper'
import Gutter from '@local/Components/Gutter'

const KompletteraRisk = (): JSX.Element => {
  const isRisk = useGetUppdragIsRisk()
  const { kompletteraRiskansokan } =
    usePickEpiContent<IUppdragDetaljvyContent>()

  if (!isRisk) {
    return null
  }
  return (
    <div>
      <Gutter offset_xs={32} />
      <HTMLMapper body={kompletteraRiskansokan.mainBody} />
    </div>
  )
}

export default KompletteraRisk
