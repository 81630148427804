import React, { useState } from 'react'
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import FormErrorMessage from '@local/Components/FormErrorMessage/FormErrorMessage'
import { ISelectChipInput } from '@local/Components/SelectChipInput/types'
import { ISelectOption } from '@local/Common.types'
import CloseIcon from '@mui/icons-material/Close'

const SelectChipInput = ({
  selectOptions,
  label,
  name,
  formikContext,
  errorMsg,
  isError,
  value,
  disabled = false,
  handleDelete,
}: ISelectChipInput): JSX.Element => {
  const { errors, setFieldValue, setFieldTouched } = formikContext

  const defaultValues =
    (value as ISelectOption[])?.map((v) => String(v.value)) ?? []

  const [values, setValues] = useState<string[]>(defaultValues)

  const handleChange = (e: SelectChangeEvent<string[]>) => {
    setValues(e.target.value as string[])
    setFieldValue(
      name,
      selectOptions.filter((option) =>
        e.target.value.includes(option.value as string)
      )
    )?.catch(err => console.log(err))
    setFieldTouched(name, true, false)?.catch(err => console.log(err))
  }

  const onDelete = (value: string) => {
    setValues(values.filter((s) => s !== value))
    handleDelete(value)
  }

  return (
    <FormControl fullWidth error={isError} disabled={disabled}>
      <InputLabel id={`${name}-select-chip-label`}>{label}</InputLabel>
      <Select
        multiple
        labelId={`${name}-select-chip-label`}
        id={`${name}-select-chip`}
        value={values}
        name={name}
        label={label}
        onChange={handleChange}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        }}
        renderValue={(selected: string[]) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected?.map((value) => (
              <Chip
                key={value}
                label={selectOptions.find((x) => x.value === value)?.label}
                onDelete={() => onDelete(value)}
                deleteIcon={
                  <CloseIcon onMouseDown={(event) => event.stopPropagation()} />
                }
              />
            ))}
          </Box>
        )}
      >
        {selectOptions?.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{ whiteSpace: 'normal' }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>

      {isError && (
        <FormErrorMessage>
          {errorMsg ?? String(errors?.[name])}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export default SelectChipInput
