import React from 'react'
import Spinner from '@local/Components/Spinner'
import Gutter from '@local/Components/Gutter'
import { Card, Grid2 as Grid, Typography, useTheme } from '@mui/material'
import { IKompletteraRiskansokanContent } from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokan.types'
import { formatIdentityNumber } from '@local/Utils/helpers'
import useGetAnsokan from '@local/Views/SkapaAnsokan/useGetAnsokan'
import { usePickEpiContent } from '@local/Utils/hooks'
import Alert from '@local/Components/Alert'

const PersonuppgifterCard = (): JSX.Element => {
  const theme = useTheme()
  const { personuppgifter, nagotGickFel } =
    usePickEpiContent<IKompletteraRiskansokanContent>()
  const { data: ansokan, isLoading, isError } = useGetAnsokan()

  if (isLoading) {
    return <Spinner centered />
  }

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  return (
    <Grid container>
      <Grid size={{ xs: 'grow', md: 8 }}>
        <Typography variant="body1">{personuppgifter.heading}</Typography>

        <Gutter offset_xs={8} />

        <Card variant="outlined" sx={{ py: 2, px: 3 }}>
          <Grid
            container
            rowGap={1}
            alignItems="center"
            minHeight={theme.spacing(6)}
          >
            <Grid size={{ xs: 12, sm: 'grow' }}>
              <Typography variant="preamble">
                {ansokan.personuppgifter.fornamn}{' '}
                {ansokan.personuppgifter.efternamn}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 'auto' }}>
              <Typography variant="preamble">
                {formatIdentityNumber(ansokan.personuppgifter.personnummer)}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

export default PersonuppgifterCard
