import React from 'react'

const CompressedList = ({
  list,
  maxNumberOfItems,
}: {
  list: string[]
  maxNumberOfItems: number
}) => {
  const maxLengthExceeded = list.length > maxNumberOfItems
  const firstPartOfList = list.slice(0, maxNumberOfItems).join(', ')
  const fullList = list.join(', ')
  const lengthOfRemainingList = list.slice(maxNumberOfItems).length

  return (
    <span>
      {maxLengthExceeded ? firstPartOfList : fullList}{' '}
      {maxLengthExceeded && `+${lengthOfRemainingList}`}
    </span>
  )
}

export default CompressedList
