import React from 'react'
import { useFormikContext } from 'formik'
import CommonOverenskommelseBilaga from '@local/Components/OverenskommelseBilaga'
import { IKompletteraRiskansokanFormValues } from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokanForm/KompletteraRiskansokanForm.types'
import { IKompletteraRiskansokanContent } from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokan.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import HTMLMapper from '@local/Components/HTMLMapper'
import { generateErrorMessage } from '@local/Utils/Helpers/Forms/Forms.helpers'
import { getUppdragKraverOverenskommelseBilaga } from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokanForm/KompletteraRiskansokanForm.helpers'
import { Grid } from '@mui/material'

const OverenskommelseBilaga = (): JSX.Element => {
  const { errors, touched, values, setFieldValue } =
    useFormikContext<IKompletteraRiskansokanFormValues>()

  const { laddaUppBilaga, bifogaOverenskommelse, nagotGickFel } =
    usePickEpiContent<IKompletteraRiskansokanContent>()

  if (!getUppdragKraverOverenskommelseBilaga(values)) {
    return null
  }

  return (
    <Grid container role="status">
      <Grid item xs md={6}>
        <HTMLMapper body={bifogaOverenskommelse.mainBody} />

        <CommonOverenskommelseBilaga
          content={laddaUppBilaga}
          formikFieldName="overenskommelseBilagor"
          files={values.overenskommelseBilagor}
          errorMessage={generateErrorMessage({
            touched: touched.overenskommelseBilagor !== undefined,
            errorMsg: errors.overenskommelseBilagor as string,
          })}
          setFieldValue={setFieldValue}
          nagotGickFel={nagotGickFel}
        />
      </Grid>
    </Grid>
  )
}

export default OverenskommelseBilaga
