import React, { useCallback } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import Alert from '@local/Components/Alert'
import {
  ISkapaAnsokningarContent,
  ISkapaAnsokningarFormValues,
} from '@local/Views/SkapaAnsokningar/types/types'
import {
  useDeleteFilAnsokanMutation,
  useGetFilAnsokanQuery,
} from '@local/services/API/filansokan/filAnsokanApi'
import { UUIDRegex, matchStringByRegex } from '@local/Utils/regexes'
import { IAvbrytFilansokanModal } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarHeader/AvbrytFilansokanModal/types/types'
import { useHistory } from 'react-router-dom'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import { useIsFeatureEnabled } from '@trr/app-shell-data'
import { useAppDispatch } from '@local/Store/configureStore'
import { useFormikContext } from 'formik'
import { isNil } from 'ramda'
import { setActiveStep } from '@local/Views/SkapaAnsokningar/skapaAnsokningarStepperSlice'
import { setExcelShouldBePreset } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/laggTillMedarbetareSlice'
import { setErrors } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/ErrorStepper/errorStepperSlice'
import { setIsAnyRowBeingEdited } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/sammanfattningSlice'

const AvbrytFilansokanModal = ({
  modalOpen,
  setModalOpen,
}: IAvbrytFilansokanModal): JSX.Element => {
  const dispatch = useAppDispatch()
  const shouldTrack = useIsFeatureEnabled('gtm.enabled.kundansokan')
  const history = useHistory()
  const trackButtonClick = useTrackCustomClick()
  const uppdragsId = matchStringByRegex(location.pathname, UUIDRegex)
  const { data: file, isLoading } = useGetFilAnsokanQuery(uppdragsId)

  const setActiveStepCallback = useCallback(
    (step: number) => {
      dispatch(setActiveStep(step))
      window.scroll({ top: 0 })
    },
    [dispatch]
  )

  const { resetForm } = useFormikContext<ISkapaAnsokningarFormValues>()

  const { avbrytFilansokanModal, nagotGickFel } =
    usePickEpiContent<ISkapaAnsokningarContent>()

  const [deleteFile, { isError }] = useDeleteFilAnsokanMutation()

  const handleResetForm = useCallback(() => {
    dispatch(setExcelShouldBePreset(false))
    dispatch(setErrors([]))
    dispatch(setIsAnyRowBeingEdited(false))

    if (shouldTrack) {
      trackButtonClick('Modal: Radera filansökan', {
        label: 'Ja, radera ansökan',
      })
    }
    setModalOpen(false)

    const firstStep = new URLSearchParams({ step: '0' })

    setActiveStepCallback(0)
    history.push({
      pathname: location.pathname,
      search: firstStep.toString(),
    })

    resetForm()
  }, [
    dispatch,
    history,
    resetForm,
    setActiveStepCallback,
    setModalOpen,
    shouldTrack,
    trackButtonClick,
  ])

  const handleRemoveFilansokan = useCallback(() => {
    void (async () => {
      await deleteFile({ uppdragsId, fileId: file.fileId })
        .unwrap()
        .then(() => handleResetForm())
        .catch((err) => {
          console.log(err)
        })
    })()
  }, [deleteFile, file, handleResetForm, uppdragsId])

  if (isLoading) {
    return
  }

  return (
    <Dialog open={modalOpen}>
      <DialogTitle>{avbrytFilansokanModal.heading}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {avbrytFilansokanModal.description}
        </DialogContentText>
        {isError && <Alert severity="error">{nagotGickFel.heading}</Alert>}
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={() => setModalOpen(false)}>
          {avbrytFilansokanModal.cancelText}
        </Button>
        <Button
          variant="text"
          disabled={isError}
          onClick={
            isNil(file) || isNil(file?.fileId)
              ? handleResetForm
              : handleRemoveFilansokan
          }
        >
          {avbrytFilansokanModal.confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AvbrytFilansokanModal
