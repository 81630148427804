import React from 'react'
import { styled } from '@mui/material/styles'
import {
  DataGridPro as MuiDataGrid,
  GridEventListener,
  GridRowEditStopReasons,
} from '@mui/x-data-grid-pro'
import { IDataGrid } from '@local/Components/DataGrid/types'

const StyledDataGrid = styled(MuiDataGrid)(({ theme }) => ({
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-cell': {
    display: 'flex',
    alignItems: 'center',
    border: `0.5px solid ${theme.palette.grey[100]}`,

    '&.is-error': {
      background: theme.palette.error.light,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiDataGrid-columnHeader--pinnedRight, & .MuiDataGrid-cell--pinnedRight, & .MuiDataGrid-columnHeader--pinnedLeft, & .MuiDataGrid-cell--pinnedLeft, & .MuiDataGrid-scrollbarFiller--pinnedRight, & .MuiDataGrid-scrollbarFiller--pinnedLeft':
    {
      backgroundColor: `${theme.palette.grey[100]} !important`,
    },
  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    width: '100%',
    justifyContent: 'center',
  },
  '& .MuiDataGrid-pinnedColumns--right .actions > .MuiDataGrid-actionsCell': {
    flex: 1,
    justifyContent: 'space-between',
  },
}))

const DataGrid = ({
  columns,
  rows,
  rowModesModel,
  loading,
  pinnedColumns,
  setRowModesModel,
  onRowModesModelChange,
  getRowClassName,
  ...other
}: IDataGrid) => {
  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  return (
    <StyledDataGrid
      loading={loading}
      disableRowSelectionOnClick
      disableColumnMenu
      editMode="row"
      rowModesModel={rowModesModel}
      onRowModesModelChange={onRowModesModelChange}
      onRowEditStop={handleRowEditStop}
      slotProps={{
        ...other.slotProps,
        toolbar: { setRowModesModel },
      }}
      initialState={{
        ...other.initialState,
        pinnedColumns: pinnedColumns ?? { right: ['action'] },
      }}
      columns={columns}
      rows={rows}
      getRowClassName={getRowClassName}
      autosizeOnMount
      {...other}
    />
  )
}

export default DataGrid
