import React from 'react'
import HTMLMapper from '@local/Components/HTMLMapper'
import { usePickEpiContent } from '@local/Utils/hooks'
import { IUppdragContent } from '@local/Views/Uppdrag/Uppdrag.types'
import { UUIDRegex, matchStringByRegex } from '@local/Utils/regexes'
import { useGetAnsokanListQuery } from '@local/services/API/ansokan/skapaAnsokanApi'
import { AnsokanState } from '@local/Common.types'
import { isNil } from 'ramda'

const OmUppsagningenVerkstalls = (): JSX.Element => {
  const { omUppsagningenVerkstalls } = usePickEpiContent<IUppdragContent>()

  const currentUppdragsId = matchStringByRegex(location.pathname, UUIDRegex)
  const { data: ansokningar, isLoading } = useGetAnsokanListQuery({
    uppdragId: currentUppdragsId,
  })

  const hasInskickadeAnsokningar = !isNil(
    ansokningar?.find(
      (ansokan) => ansokan.ansokanState === AnsokanState.KompletteraRisk
    )
  )

  if (isLoading) {
    return null
  }

  return (
    hasInskickadeAnsokningar && (
      <HTMLMapper body={omUppsagningenVerkstalls.mainBody} />
    )
  )
}

export default OmUppsagningenVerkstalls
