import React from 'react'
import Spinner from '@local/Components/Spinner'
import Gutter from '@local/Components/Gutter'
import { Typography, Grid, Link, Button } from '@mui/material'
import { IUppdragDetaljvyContent } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import FackligaKontakterItem from '@local/Views/DinaUppdrag/Uppdrag/FackligaKontakterItem'
import useGetUppdrag from '@local/Views/UppdragDetaljvy/useGetUppdrag'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { appendComma } from '@local/Utils/Helpers'
import EditIcon from '@mui/icons-material/Edit'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AnsokanCard from '@local/Components/AnsokanCard/AnsokanCard'
import Alert from '@local/Components/Alert'
import { useCanAccessSkapaAnsokningar } from '@local/Views/UppdragDetaljvy/hooks'

const UppdragCard = (): JSX.Element => {
  const canAccessSkapaAnsokningar = useCanAccessSkapaAnsokningar()

  const {
    uppdragDetaljer,
    orsakTillUppsagning,
    nagotGickFel,
    skapaAnsokningarLank,
    arbetsgivaransokanLinks: { redigeraUppdragUrl, laggTillMedarbetareUrl },
  } = usePickEpiContent<IUppdragDetaljvyContent>()

  const { data: uppdrag, isLoading, isError } = useGetUppdrag()

  const getFormattedArbetsstalle = () =>
    appendComma(uppdrag.arbetsstalle.ort) +
    appendComma(uppdrag.arbetsstalle.adress) +
    uppdrag.arbetsstalle.namn

  if (isLoading) {
    return <Spinner centered />
  }

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }
  const UppdragColumnItem = ({
    header,
    body,
  }: {
    header?: string
    body?: string | JSX.Element
  }): JSX.Element => (
    <Grid item xs={12} sm={6} md={4}>
      <Typography variant="subtitle1">{header}</Typography>
      <Typography variant="body2">{body}</Typography>
    </Grid>
  )
  return (
    <AnsokanCard
      title={uppdrag.namn}
      subtitle={
        <Grid container>
          <Grid item mr={1}>
            <Typography variant="subtitle1">
              {uppdragDetaljer.uppdragsnummerLabel}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">{uppdrag.nummer}</Typography>
          </Grid>
        </Grid>
      }
      actionAlign="center"
      action={
        <Grid container alignItems="center" gap={2}>
          <Link
            href={`${redigeraUppdragUrl}${uppdrag.id}/`}
            variant="subtitle2"
          >
            <Grid container alignItems={'center'} gap={1}>
              <Grid item>
                <EditIcon color="primary" sx={{ fontSize: 28 }} />
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">
                  {uppdragDetaljer.redigeraUppdragLabel}
                </Typography>
              </Grid>
            </Grid>
          </Link>
          <Link
            href={
              canAccessSkapaAnsokningar
                ? `${skapaAnsokningarLank.url}${uppdrag.id}/`
                : `${laggTillMedarbetareUrl}${uppdrag.id}/`
            }
            variant="subtitle2"
          >
            <Grid container alignItems={'center'} gap={1} mr={2}>
              <Grid item>
                <AddCircleIcon color="primary" sx={{ fontSize: 28 }} />
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">
                  {uppdragDetaljer.laggTillMedarbetareLabel}
                </Typography>
              </Grid>
            </Grid>
          </Link>
        </Grid>
      }
      actionMobile={
        <>
          <Button
            sx={{ width: '100%' }}
            variant="outlined"
            href={`${redigeraUppdragUrl}${uppdrag.id}/`}
            startIcon={<EditIcon sx={{ fontSize: 28 }} />}
          >
            {uppdragDetaljer.redigeraUppdragLabel}
          </Button>
          <Gutter offset_xs={16} />
          <Button
            sx={{ width: '100%' }}
            variant="outlined"
            href={`${laggTillMedarbetareUrl}${uppdrag.id}/`}
            startIcon={<AddCircleIcon sx={{ fontSize: 28 }} />}
          >
            {uppdragDetaljer.laggTillMedarbetareLabel}
          </Button>
        </>
      }
    >
      <Grid px={1} container rowGap={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <UppdragColumnItem
          header={uppdragDetaljer.arbetsplatsLabel}
          body={getFormattedArbetsstalle()}
        />
        <UppdragColumnItem
          header={uppdragDetaljer.uppsagningsorsakLabel}
          body={orsakTillUppsagning[uppdrag.orsakTillUppsagning]}
        />
        <FackligaKontakterItem
          label={uppdragDetaljer.fackligKontaktLabel}
          uppdrag={uppdrag}
        />
      </Grid>
    </AnsokanCard>
  )
}

export default UppdragCard
