import React from 'react'
import { Grid, Typography } from '@mui/material'
import { useGetAnsokanQuery } from '@local/services/API/ansokan/skapaAnsokanApi'
import { usePickEpiContent } from '@local/Utils/Hooks'
import {
  IDetaljvyContent,
  IUppdragsAnsokanApiModel,
} from '@local/Views/Detaljvy/Detaljvy.types'
import { isNil } from 'ramda'
import { trrFormat } from '@local/Utils/Helpers/formatDate'
import Gutter from '@local/Components/Gutter'
import { formatDecimal, formatPercentageNumber } from '@local/Utils/Helpers'
import KeyValuePair from '@local/Views/Detaljvy/AnsokanList/AnsokanCard/KeyValuePair'
import useGetUppdrag from '@local/Views/UppdragDetaljvy/useGetUppdrag'
import { OrsakerTillUppsagning } from '@local/Common.types'

const OmAnstallningen = ({
  row,
}: {
  row: IUppdragsAnsokanApiModel
}): JSX.Element => {
  const {
    ansokanDetaljer: { omAnstallningen, omUppsagningen, common },
  } = usePickEpiContent<IDetaljvyContent>()

  const { data: ansokan, isLoading, isError } = useGetAnsokanQuery(row.id)

  const {
    data: uppdrag,
    isError: isErrorUppdrag,
    isLoading: isLoadingUppdrag,
  } = useGetUppdrag()

  const isTioTbAnsokan =
    uppdrag?.orsakTillUppsagning ===
    OrsakerTillUppsagning.TidsbegransadAnstallning

  const getBooleanString = (value: boolean) => (value ? common.ja : common.nej)

  const shouldShowSkeleton =
    isLoading || isError || !ansokan || isErrorUppdrag || isLoadingUppdrag

  return (
    <>
      <Typography variant="subtitle2">{omAnstallningen.heading}</Typography>

      <Gutter offset_xs={16} />

      <Grid container spacing={2}>
        <KeyValuePair
          title={omAnstallningen.anstallningsdatum}
          value={
            !isNil(ansokan?.anstallningsuppgifter?.anstallningsdatum) &&
            trrFormat(ansokan?.anstallningsuppgifter?.anstallningsdatum)
          }
          isLoading={shouldShowSkeleton}
        />

        {isTioTbAnsokan && (
          <KeyValuePair
            title={omUppsagningen.sistaAnstallningsdag}
            value={
              !isNil(ansokan?.anstallningsuppgifter?.sistaAnstallningsdatum) &&
              trrFormat(ansokan?.anstallningsuppgifter?.sistaAnstallningsdatum)
            }
            isLoading={shouldShowSkeleton}
          />
        )}

        <KeyValuePair
          title={omAnstallningen.anstallningsgrad}
          value={
            !isNil(ansokan?.anstallningsuppgifter?.anstallningsgrad) &&
            formatPercentageNumber(
              ansokan?.anstallningsuppgifter?.anstallningsgrad
            )
          }
          isLoading={shouldShowSkeleton}
        />

        <KeyValuePair
          title={omAnstallningen.tjansteman}
          value={
            !isNil(ansokan?.anstallningsuppgifter?.isTjansteman) &&
            getBooleanString(ansokan?.anstallningsuppgifter?.isTjansteman)
          }
          isLoading={shouldShowSkeleton}
        />

        <KeyValuePair
          title={omAnstallningen.vd}
          value={
            !isNil(ansokan?.anstallningsuppgifter?.isVdEllerUndantagsgrupp) &&
            getBooleanString(
              ansokan?.anstallningsuppgifter?.isVdEllerUndantagsgrupp
            )
          }
          isLoading={shouldShowSkeleton}
        />

        <KeyValuePair
          title={omAnstallningen.arbetstimmar}
          value={
            !isNil(ansokan?.anstallningsuppgifter?.arbetstimmarPerVecka) &&
            `${formatDecimal(ansokan?.anstallningsuppgifter?.arbetstimmarPerVecka)} ${common.timmarSuffix}`
          }
          isLoading={shouldShowSkeleton}
        />

        {!isNil(ansokan?.anstallningsuppgifter?.agarandel) && (
          <KeyValuePair
            title={omAnstallningen.agarandel}
            value={formatPercentageNumber(
              ansokan?.anstallningsuppgifter?.agarandel
            )}
            isLoading={shouldShowSkeleton}
          />
        )}
      </Grid>
    </>
  )
}

export default OmAnstallningen
