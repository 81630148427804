import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Alert, Button, Grid2 as Grid } from '@mui/material'
import StickyFooter from '@local/Components/StickyMuiFooter/StickyFooter'
import { selectSkapaAnsokningarStepper } from '@local/Views/SkapaAnsokningar/skapaAnsokningarStepperSlice'
import { useIsFeatureFlagEnabled, usePickEpiContent } from '@local/Utils/hooks'
import {
  ISkapaAnsokningarContent,
  SkapaAnsokningarStep,
} from '@local/Views/SkapaAnsokningar/types/types'
import Spinner from '@local/Components/Spinner'
import { isEmpty } from 'ramda'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import SkickaInModal from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/SkapaAnsokningarFormSubmit/SkickaInModal'
import { UUIDRegex, matchStringByRegex } from '@local/Utils/regexes'
import { selectSammanfattning } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/sammanfattningSlice'
import { selectErrorStepper } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/ErrorStepper/errorStepperSlice'
import { useStepperActions } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/SkapaAnsokningarStepper/hooks'
import { useGetUppdrag } from '@local/Utils/hooks/useGetUppdrag'

const SkapaAnsokningarFormSubmit = (): JSX.Element => {
  const { isAnyRowBeingEdited } = useSelector(selectSammanfattning)
  const {
    shouldShowErrorMessage,
    setShouldShowErrorMessage,
    goToNextStep,
    goToPreviousStep,
    validateStep,
  } = useStepperActions()
  const { errors } = useSelector(selectErrorStepper)
  const currentUppdragsId = matchStringByRegex(location.pathname, UUIDRegex)
  const [skickaInModal, setSkickaInModal] = useState(false)
  const shouldTrack = useIsFeatureFlagEnabled('gtm.enabled.kundansokan')
  const trackButtonClick = useTrackCustomClick()

  const { data: uppdrag, isLoading, isError } = useGetUppdrag()
  const {
    sidfot,
    nagotGickFel,
    arbetsgivaransokanLinks: { uppdragUrl },
  } = usePickEpiContent<ISkapaAnsokningarContent>()

  const history = useHistory()

  const { activeStep, steps } = useSelector(selectSkapaAnsokningarStepper)
  const isLastStep = (activeStep as number) === steps.length - 1

  useEffect(() => {
    if (isEmpty(errors) && !isAnyRowBeingEdited) {
      setShouldShowErrorMessage(false)
    }
  }, [errors, isAnyRowBeingEdited, setShouldShowErrorMessage])

  const navigateHandler = useCallback(
    (callback: () => void) => {
      if (isAnyRowBeingEdited) {
        setShouldShowErrorMessage(true)
      } else {
        callback()
      }
    },
    [isAnyRowBeingEdited, setShouldShowErrorMessage]
  )

  const handleFinish = useCallback(() => {
    validateStep(() => {
      if (shouldTrack) {
        trackButtonClick('Medarbetaransökningar', {
          label: 'Slutför',
        })
      }
      setSkickaInModal(true)
    })
  }, [shouldTrack, trackButtonClick, validateStep])

  const handleCancelModal = useCallback(() => {
    setSkickaInModal(false)
  }, [setSkickaInModal])

  if (isLoading) {
    return <Spinner centered />
  }

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  return (
    <StickyFooter
      position="fixed"
      isError={shouldShowErrorMessage}
      errorMsg={
        isAnyRowBeingEdited ? sidfot.sparaUppgifter : sidfot.felmeddelande
      }
      leftElements={
        <div>
          <Grid container columnSpacing={{ xs: 1, sm: 2 }}>
            <Grid size={{ xs: 6, sm: 'auto' }}>
              <Button
                disabled={
                  activeStep === SkapaAnsokningarStep.LaggTillMedarbetare
                }
                variant="outlined"
                onClick={() => navigateHandler(goToPreviousStep)}
              >
                {sidfot.tillbaka}
              </Button>
            </Grid>

            <Grid size={{ xs: 6, sm: 'auto' }}>
              {isLastStep && (
                <>
                  <Button
                    variant="contained"
                    onClick={handleFinish}
                    data-trackable="false"
                  >
                    {sidfot.slutfor}
                  </Button>
                  <SkickaInModal
                    modalOpen={skickaInModal}
                    closeModal={handleCancelModal}
                    uppdragId={uppdrag.id}
                  />
                </>
              )}
              {!isLastStep && (
                <Button
                  variant="contained"
                  onClick={() => navigateHandler(goToNextStep)}
                >
                  {sidfot.nastaSteg}
                </Button>
              )}
            </Grid>

            <Grid size={{ xs: 6, sm: 'auto' }}>
              <Button
                variant="text"
                onClick={() =>
                  history.push(`${uppdragUrl}${currentUppdragsId}/`)
                }
              >
                {sidfot.avsluta}
              </Button>
            </Grid>
          </Grid>
        </div>
      }
    />
  )
}

export default SkapaAnsokningarFormSubmit
