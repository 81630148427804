import React from 'react'
import { Grid, Typography } from '@mui/material'
import { useGetAnsokanQuery } from '@local/services/API/skapaAnsokanApi'
import { usePickEpiContent } from '@local/Utils/Hooks'
import {
  IDetaljvyContent,
  IUppdragsAnsokanApiModel,
} from '@local/Views/Detaljvy/Detaljvy.types'
import Gutter from '@local/Components/Gutter'
import KeyValuePair from '@local/Views/Detaljvy/AnsokanList/AnsokanCard/KeyValuePair'

const Kontaktuppgifter = ({
  row,
}: {
  row: IUppdragsAnsokanApiModel
}): JSX.Element => {
  const {
    ansokanDetaljer: { kontaktuppgifter },
  } = usePickEpiContent<IDetaljvyContent>()
  const { data: ansokan, isLoading, isError } = useGetAnsokanQuery(row.id)

  const shouldShowSkeleton = isLoading || isError || !ansokan

  return (
    <>
      <Typography variant="subtitle2">{kontaktuppgifter.heading}</Typography>

      <Gutter offset_xs={16} />

      <Grid container spacing={2}>
        <KeyValuePair
          title={kontaktuppgifter.epost}
          value={ansokan?.personuppgifter?.epostadress}
          isLoading={shouldShowSkeleton}
        />
      </Grid>
    </>
  )
}

export default Kontaktuppgifter
