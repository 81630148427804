import React from 'react'
import { useDirtyCheckPrompt } from '@trr/react-use-dirty-check-prompt'
import PersonuppgifterCard from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokanForm/Personuppgifter'
import Divider from '@local/Components/Divider'
import Gutter from '@local/Components/Gutter'
import ValjUppdrag from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokanForm/ValjUppdrag'
import KompletteringAvUppgifter from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokanForm/KompletteringAvUppgifter'
import { FormikProps, withFormik } from 'formik'
import {
  IKompletteraRiskansokanFormProps,
  IKompletteraRiskansokanFormValues,
} from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokanForm/KompletteraRiskansokanForm.types'
import {
  initialKompletteraRiskansokanValues,
  skapaKompletteraRiskansokanValidationSchema,
} from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokanForm/KompletteraRiskansokanForm.schema'
import KompletteraRiskansokanFormSubmit from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokanForm/KompletteraRiskansokanFormSubmit'
import OverenskommelseBilaga from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokanForm/OverenskommelseBilaga'

const KompletteraRiskansokanForm = ({
  dirty,
  content,
}: IKompletteraRiskansokanFormProps &
  FormikProps<IKompletteraRiskansokanFormValues>) => {
  const { routerPrompt } = useDirtyCheckPrompt(
    dirty,
    content.varningLamnaSidan.description
  )

  return (
    <>
      {routerPrompt}

      <PersonuppgifterCard />

      <Divider offset_xs={64} />

      <Gutter offset_xs={32} />

      <ValjUppdrag />

      <Divider offset_xs={64} />

      <Gutter offset_xs={32} />

      <KompletteringAvUppgifter />

      <Gutter offset_xs={32} />

      <OverenskommelseBilaga />

      <Gutter offset_xs={32} />

      <KompletteraRiskansokanFormSubmit />
    </>
  )
}

const KompletteraRiskansokanFormik = withFormik<
  IKompletteraRiskansokanFormProps,
  IKompletteraRiskansokanFormValues
>({
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: () => initialKompletteraRiskansokanValues,
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  validationSchema: ({
    content,
    ansokan,
  }: IKompletteraRiskansokanFormProps &
    FormikProps<IKompletteraRiskansokanFormValues>) =>
    skapaKompletteraRiskansokanValidationSchema(content, ansokan),
  displayName: 'KompletteraRiskansokanForm',
})(KompletteraRiskansokanForm)

export default KompletteraRiskansokanFormik
