import { useAppDispatch } from '@local/Store/configureStore'
import {
  selectErrorStepper,
  setCurrentErrorIndex,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/ErrorStepper/errorStepperSlice'
import allowedKeydowns, {
  KeydownKeys,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/helpers/allowedKeydowns'
import { IFilAnsokanRowFormik } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/types/types'
import {
  GridCellParams,
  GridEventListener,
  GridRowId,
  GridRowModesModel,
  MuiEvent,
} from '@mui/x-data-grid-pro'
import { useSelector } from 'react-redux'

const useGridHandlers = (
  rowModesModel: GridRowModesModel,
  isAnyRowInEditMode: boolean,
  handleCancelClick: (id: GridRowId) => () => void,
  handleSaveClick: (id: GridRowId) => () => void,
  handleEditClick: (id: GridRowId) => () => void
) => {
  const dispatch = useAppDispatch()

  const { errors: stepperErrors } = useSelector(selectErrorStepper)

  const handleCellKeyDown: GridEventListener<'cellKeyDown'> = (
    params,
    event
  ) => {
    const eventKey = event.key as KeydownKeys

    if (!allowedKeydowns.includes(eventKey)) {
      event.defaultMuiPrevented = true
    }

    const rowBeingEditedIsSafeToHandle = Object.keys(rowModesModel).includes(
      String(params.id)
    )

    if (eventKey === KeydownKeys.Escape) {
      event.defaultMuiPrevented = true

      if (rowBeingEditedIsSafeToHandle) {
        handleCancelClick(params.id)()
      }
    }

    if (eventKey === KeydownKeys.Enter) {
      event.defaultMuiPrevented = true

      if (rowBeingEditedIsSafeToHandle) {
        handleSaveClick(params.id)()
      } else if (!isAnyRowInEditMode) {
        handleEditClick(params.id)()
      }
    }

    if (eventKey === KeydownKeys.Backspace) {
      event.defaultMuiPrevented = true

      if (!isAnyRowInEditMode) {
        handleEditClick(params.id)()
      }
    }
  }

  const handleCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>
  ) => {
    event.defaultMuiPrevented = true

    const cellWithError = stepperErrors?.find(
      (stepperError) =>
        stepperError.id === params.field &&
        stepperError.rowIndex === (params.row as IFilAnsokanRowFormik).index
    )

    if (cellWithError) {
      return dispatch(
        setCurrentErrorIndex(stepperErrors.indexOf(cellWithError))
      )
    }
  }

  const handleCellDoubleClick = (
    _params: GridCellParams,
    event: MuiEvent<React.MouseEvent>
  ) => {
    if (isAnyRowInEditMode) {
      event.defaultMuiPrevented = true
    }
  }

  const handleCellMouseDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.detail > 1 && !isAnyRowInEditMode) {
      event.preventDefault()
    }
  }

  return {
    handleCellKeyDown,
    handleCellClick,
    handleCellDoubleClick,
    handleCellMouseDown,
  }
}

export default useGridHandlers
