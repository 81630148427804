import { AnsokanKeys, FilAnsokanKeys } from '@local/Common.types'
import {
  getFilansokanKeyValue,
  getFilansokanKeyValueDate,
  getFilansokanKeyValueNumber,
  getFilansokanKeyValuePercentageNumber,
  getFilansokanKeyValueRadioChoice,
  getFilansokanKeyValueSelectOption,
  getFilansokanKeyValueSjukersattningOption,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/helpers/common'
import {
  IFilAnsokanRow,
  ISammanfattningFormValues,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/types/types'

export const mapFilAnsokanDraftToSammanfattningFormValues = (
  rows: IFilAnsokanRow[]
): ISammanfattningFormValues => ({
  filansokningar: rows
    ?.map((row, index) => ({
      id: row.id,
      index,
      excelRowIndex: row.index,
      [AnsokanKeys.Anstallningsdatum]: getFilansokanKeyValueDate(
        row,
        FilAnsokanKeys.Anstallningsdatum
      ),
      [AnsokanKeys.Anstallningsgrad]: getFilansokanKeyValuePercentageNumber(
        row,
        FilAnsokanKeys.Anstallningsgrad
      ),
      [AnsokanKeys.ArbetstimmarPerVecka]: getFilansokanKeyValueNumber(
        row,
        FilAnsokanKeys.ArbetstimmarPerVecka
      ),
      [AnsokanKeys.Avgangsvederlag]: getFilansokanKeyValueNumber(
        row,
        FilAnsokanKeys.Avgangsvederlag
      ),
      [AnsokanKeys.Epostadress]: getFilansokanKeyValue(
        row,
        FilAnsokanKeys.Epost
      ),
      [AnsokanKeys.HasMedarbetareAnsokanAtCurrentUppdrag]:
        getFilansokanKeyValueRadioChoice(
          row,
          FilAnsokanKeys.HasMedarbetareAnsokanAtCurrentUppdrag
        ),
      [AnsokanKeys.HasTjanstemannaavtal]: getFilansokanKeyValueSelectOption(
        row,
        FilAnsokanKeys.Tjanstemannaavtal
      ),
      [AnsokanKeys.IsVdEllerUndantagsgrupp]: getFilansokanKeyValueSelectOption(
        row,
        FilAnsokanKeys.Vd
      ),
      [AnsokanKeys.LonSenastAndrad]: getFilansokanKeyValueDate(
        row,
        FilAnsokanKeys.LonSenastAndrad
      ),
      [AnsokanKeys.Manadslon]: getFilansokanKeyValueNumber(
        row,
        FilAnsokanKeys.Manadslon
      ),
      [AnsokanKeys.Namn]: getFilansokanKeyValue(row, FilAnsokanKeys.Namn),
      [AnsokanKeys.Personnummer]: getFilansokanKeyValue(
        row,
        FilAnsokanKeys.Personnummer
      ),
      [AnsokanKeys.ProvisionTantiemBelopp]: getFilansokanKeyValueNumber(
        row,
        FilAnsokanKeys.BonusProvisionTantiem
      ),
      [AnsokanKeys.SistaAnstallningsdatum]: getFilansokanKeyValueDate(
        row,
        FilAnsokanKeys.SistaAnstallningsdag
      ),
      [AnsokanKeys.Sjukersattningsgrad]:
        getFilansokanKeyValueSjukersattningOption(
          row,
          FilAnsokanKeys.Sjukersattningsgrad
        ),
      [AnsokanKeys.SkiftJourOBTillaggBelopp]: getFilansokanKeyValueNumber(
        row,
        FilAnsokanKeys.SkiftJourOb
      ),
      [AnsokanKeys.Uppsagningsdatum]: getFilansokanKeyValueDate(
        row,
        FilAnsokanKeys.Uppsagningsdatum
      ),
      [AnsokanKeys.Uppsagningsgrad]: getFilansokanKeyValuePercentageNumber(
        row,
        FilAnsokanKeys.Uppsagningsgrad
      ),
    }))
    .sort((a, b) => Number(a.index) - Number(b.index)),
})
