import React from 'react'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { ISkapaAnsokningarFormValues } from '@local/Views/SkapaAnsokningar/types/types'
import Gutter from '@local/Components/Gutter'
import { useFormikContext } from 'formik'
import { LaggTillMedarbetareFormTypes } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/types/types'
import RadioButtonCards from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/RadioButtonCards'
import LaddaUppFil from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/LaddaUppFil'
import SaGorDuEnAnsokan from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/SaGorDuEnAnsokan'

const LaggTillMedarbetare = (): JSX.Element => {
  const { breakpoints } = useTheme()
  const isMediumScreenOrBelow = useMediaQuery(breakpoints.down('lg'))

  const { values } = useFormikContext<ISkapaAnsokningarFormValues>()

  return (
    <>
      <Gutter offset_xs={16} />

      <Grid
        container
        justifyContent="space-between"
        columnSpacing={isMediumScreenOrBelow ? 4 : 0}
        rowSpacing={6}
      >
        <Grid item xs={12} md={8} lg={6}>
          <RadioButtonCards />

          {values.laggTillMedarbetareChoice ===
            LaggTillMedarbetareFormTypes.LaggTillMedarbetareExcel && (
            <>
              <Gutter offset_xs={40} />
              <LaddaUppFil />
            </>
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <SaGorDuEnAnsokan />
        </Grid>
      </Grid>
    </>
  )
}

export default LaggTillMedarbetare
