import {
  IDateTimestamp,
  IDateTimestring,
  RadioChoice,
  ISelectOption,
  IKeyValue,
} from '@local/Common.types'
import { FormikErrors, FormikTouched, FormikValues } from 'formik'
import { isNil } from 'ramda'
import * as Yup from 'yup'

export const generateErrorMessage = ({
  errorMsg,
  touched,
}: {
  /** The error provided from Formik */
  errorMsg: string | FormikErrors<FormikValues>
  /** The touched object provided by Formik */
  touched: boolean | FormikTouched<FormikValues>
}): string | FormikErrors<FormikValues> => (touched && errorMsg) || ''

export type IBrokenSelectOption = ISelectOption | string | number

/**
 * The dropdown component is weird and is using ISelectOption as value,
 * so this will extract the value within
 */
export const getDropdownSelectOptionValue = (
  dropdownValue: IBrokenSelectOption
) => (dropdownValue as ISelectOption).value

/**
 * Same as above, but will extract label instead
 */
export const getDropdownSelectOptionLabel = (
  dropdownValue: IBrokenSelectOption
) => (dropdownValue as ISelectOption).label

/**
 * The typings for Yup are missing some fields,
 * so extend it to include the needed fields
 */
interface TestContextExtended<T> extends Omit<Yup.TestContext, 'options'> {
  from: {
    schema: Yup.AnyObjectSchema
    value: T
  }[]
  options: {
    index: number
  }
  parent: T
}
export const getYupTestContext = <T>(
  testContext: Yup.TestContext
): TestContextExtended<T> => testContext as TestContextExtended<T>

export const getYupFormValues = <T>(
  testContext: Yup.TestContext
): {
  index: number
  formValues: T
} => {
  const context = getYupTestContext<T>(testContext)
  const index = context.options.index
  const formValues = context.from[context.from.length - 1].value

  return { index, formValues }
}

export const getYupCurrentSchemaValues = <T>(
  testContext: Yup.TestContext
): T => {
  const context = getYupTestContext<T>(testContext)
  const formValues = context.parent

  return formValues
}

export const getYupSuperiorSchemaValues = <T>(
  testContext: Yup.TestContext
): T => {
  const context = getYupTestContext<T>(testContext)
  const formValues = context.from[1].value

  return formValues
}

export const booleanToRadioChoice = (value: boolean): RadioChoice => {
  if (!isNil(value)) {
    return value ? RadioChoice.Ja : RadioChoice.Nej
  }
  return null
}

export const numberToRadioChoice = (value: number): RadioChoice => {
  if (!isNil(value)) {
    return value > 0 ? RadioChoice.Ja : RadioChoice.Nej
  }
  return null
}

export const getDateTimestamp = (value: IDateTimestring): IDateTimestamp =>
  value ? new Date(value).valueOf() : null

export const trimString = (str: string) => str.replace(/\s/g, '')

export const handleChangeWithTrim = (
  e: React.ChangeEvent<HTMLInputElement>,
  handleChange: (e: React.ChangeEvent<unknown>) => void
) => {
  e.currentTarget.value = trimString(e.currentTarget.value)

  handleChange(e)
}

export const isFieldRequired = (
  fieldName: string,
  schema: Yup.ObjectSchema<Yup.AnyObject, Yup.AnyObject, unknown, ''>
): boolean => {
  const field = schema.describe().fields[fieldName]
  if (!field) throw new Error(`Field '${fieldName}' not found in schema`)
  return !(field as Yup.SchemaDescription).optional
}

export const isFieldErrorAndTouched = (
  errors: FormikErrors<IKeyValue>,
  touched: FormikTouched<IKeyValue>,
  fieldName: string
): boolean => {
  const fieldError = errors?.[fieldName]
  const fieldTouched = touched?.[fieldName]
  if (typeof fieldError === 'string' && fieldTouched === true) {
    return true
  } else if (
    typeof fieldError === 'object' &&
    typeof fieldTouched === 'object'
  ) {
    // Check for nested fields
    const nestedFieldNames = Object.keys(fieldError)
    return nestedFieldNames.some((nestedField) =>
      isFieldErrorAndTouched(
        fieldError as FormikErrors<IKeyValue>,
        fieldTouched as FormikTouched<IKeyValue>,
        nestedField
      )
    )
  }
  return false
}
