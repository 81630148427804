import React from 'react'
import { Grid2 as Grid, List, ListItemText, Typography } from '@mui/material'
import { usePickEpiContent } from '@local/Utils/hooks'
import {
  ISkapaAnsokningarContent,
  ISkapaAnsokningarFormValues,
} from '@local/Views/SkapaAnsokningar/types/types'
import { getFackligaKontakterFlatList } from '@local/Utils/helpers/FackligKontakt.helpers'
import { useFormikContext } from 'formik'
import Gutter from '@local/Components/Gutter'
import { useGetUppdrag } from '@local/Utils/hooks/useGetUppdrag'

const GranskaSkickain = (): JSX.Element => {
  const { data: uppdrag, isLoading } = useGetUppdrag()
  const {
    sammanfattning: { orsakTillUppsagning },
    granskaSkickaIn: { ingress, uppdragsdetaljer },
  } = usePickEpiContent<ISkapaAnsokningarContent>()

  const {
    values: { filansokningar },
  } = useFormikContext<ISkapaAnsokningarFormValues>()

  const fackligaKontakterFlatList = getFackligaKontakterFlatList(
    uppdrag.fackligaKontakter
  )

  const flatAdministratorerList = uppdrag.administratorer.map(
    (admin) => admin.namn
  )
  const flatFackligaKontakterList = fackligaKontakterFlatList.map(
    (kontakt) => `${kontakt.fornamn} ${kontakt.efternamn} `
  )

  const addedMedarbetareString = `${filansokningar?.length} ${uppdragsdetaljer.medarbetareUnit}`

  const ListItemTextArray = ({
    primary,
    secondary,
  }: {
    primary: string
    secondary: string | string[]
  }) => {
    if (Array.isArray(secondary)) {
      return (
        <>
          <ListItemText primary={primary} />
          {secondary.map((item) => (
            <ListItemText secondary={item} key={`key-${item}`} />
          ))}
          <Gutter offset_xs={16} />
        </>
      )
    }
    return (
      <>
        <ListItemText primary={primary} secondary={secondary} />
        <Gutter offset_xs={16} />
      </>
    )
  }

  if (isLoading) {
    return null
  }

  return (
    <>
      <Gutter offset_xs={24} />
      <Grid container>
        <Grid size={{ xs: 6 }}>
          <Typography variant="h6" pb={2}>
            {ingress.heading}
          </Typography>
          <Typography variant="body1">{ingress.preamble}</Typography>
        </Grid>
      </Grid>
      <Gutter offset_xs={16} />

      <List>
        <ListItemTextArray
          primary={uppdragsdetaljer.uppsagningsorsak}
          secondary={orsakTillUppsagning[uppdrag.orsakTillUppsagning]}
        />

        <ListItemTextArray
          primary={uppdragsdetaljer.fackligaKontakter}
          secondary={flatFackligaKontakterList}
        />

        <ListItemTextArray
          primary={uppdragsdetaljer.arbetsplats}
          secondary={uppdrag.arbetsstalle.namn}
        />

        <ListItemTextArray
          primary={uppdragsdetaljer.administratorer}
          secondary={flatAdministratorerList}
        />

        <ListItemTextArray
          primary={uppdragsdetaljer.antalTillagdaMedarbetare}
          secondary={addedMedarbetareString}
        />
      </List>
    </>
  )
}

export default GranskaSkickain
