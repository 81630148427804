import React, { useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import {
  useDeleteFilAnsokanMutation,
  useGetFilAnsokanQuery,
} from '@local/services/API/filAnsokanApi'
import { UUIDRegex, matchStringByRegex } from '@local/Utils/Helpers'
import TaBortModal from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/LaddaUppFil/FileUploader/TaBortModal'
import { ISkapaAnsokningarContent } from '@local/Views/SkapaAnsokningar/types/types'
import { useIsFeatureFlagEnabled, usePickEpiContent } from '@local/Utils/Hooks'
import { useTrackCustomClick } from '@trr/gtm-tracking'

const FileNamePreview = () => {
  const uppdragsId = matchStringByRegex(location.pathname, UUIDRegex)
  const shouldTrack = useIsFeatureFlagEnabled('gtm.enabled.kundansokan')
  const trackButtonClick = useTrackCustomClick()

  const [taBortModalOpen, setTaBortModalOpen] = useState(false)
  const {
    laggTillMedarbetare: { laddaUppFil, taBortFilModal },
  } = usePickEpiContent<ISkapaAnsokningarContent>()

  const { data: uploadedFile } = useGetFilAnsokanQuery(uppdragsId)
  const [deleteFile] = useDeleteFilAnsokanMutation({
    fixedCacheKey: 'shared-filAnsokan-delete',
  })

  const handleRemove = () => {
    if (uploadedFile.filnamn) {
      deleteFile({ uppdragsId, fileId: uploadedFile.fileId }).catch((error) => {
        console.error('Fel vid borttagning av fil', error)
      })
      if (shouldTrack) {
        trackButtonClick('Ladda upp filansokningar', {
          label: 'Ta bort',
        })
      }
    }
    setTaBortModalOpen(false)
  }
  const handleModalClose = () => {
    setTaBortModalOpen(false)
    if (shouldTrack) {
      trackButtonClick('Ladda upp filansokningar', {
        label: 'Avbryt',
      })
    }
  }

  if (!uploadedFile.filnamn) return
  return (
    <>
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ backgroundColor: 'surface.grey', padding: 2, borderRadius: 2 }}
      >
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 40,
              height: 40,
              borderRadius: '50%',
              backgroundColor: 'success.main',
              color: 'white',
            }}
          >
            <AttachFileIcon
              sx={{
                fontSize: 24,
              }}
            />
          </Box>
        </Grid>
        <Grid item flex={1} sx={{ marginX: 2 }}>
          <Typography variant="subtitle1">{uploadedFile.filnamn}</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="text"
            onClick={() => setTaBortModalOpen(true)}
            data-trackable="false"
          >
            {laddaUppFil.clearButtonText}
          </Button>
        </Grid>
      </Grid>
      <TaBortModal
        modalOpen={taBortModalOpen}
        closeModal={handleModalClose}
        handleRemove={handleRemove}
        taBortModalContent={taBortFilModal}
      />
    </>
  )
}

export default FileNamePreview
