import React from 'react'
import Gutter from '@local/Components/Gutter'
import SistaAnstallningsdatum from '@local/Components/SistaAnstallningsdatum'
import Uppsagningsdatum from '@local/Components/Uppsagningsdatum'
import { Typography } from '@mui/material'
import {
  startDate,
  getEarliestOccuringDate,
  dayBeforeDate,
  yesterday,
} from '@local/Utils/Helpers'
import { useFormikContext } from 'formik'
import { toTrrDateOrDefault } from '@local/Utils/Helpers/formatDate'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ISkapaAnsokanFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import { ISkapaAnsokanContent } from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'
import useGetAnsokan from '@local/Views/SkapaAnsokan/useGetAnsokan'
import DateInput from '@local/Components/DateInput/DateInput'
import { isNil } from 'ramda'
import Alert from '@local/Components/Alert'
import { AnsokanKeys, AnsokanType } from '@local/Common.types'

const Datum = (): JSX.Element => {
  const formikContext = useFormikContext<ISkapaAnsokanFormValues>()
  const { errors, touched, values } = formikContext

  const {
    anstallning: {
      anstallningsdatum: datumContent,
      riskDatum,
      tooltipAnstallningsdatum,
    },
    nagotGickFel,
  } = usePickEpiContent<ISkapaAnsokanContent>()

  const { data: ansokan, isError } = useGetAnsokan()
  const isTioTbAnsokan =
    ansokan?.type === AnsokanType.omstallningTidsbegransadAnstallning

  const renderAnstallningsdatum = (
    <>
      <Typography gutterBottom variant="h5">
        {datumContent.heading}
      </Typography>

      <DateInput
        value={values.anstallningsdatum}
        error={touched?.anstallningsdatum && !isNil(errors?.anstallningsdatum)}
        errorMsg={errors?.anstallningsdatum as string}
        tooltipContent={tooltipAnstallningsdatum}
        tooltipVariant="subtitle1"
        formikFieldName={AnsokanKeys.Anstallningsdatum}
        formikContext={formikContext}
        minDate={startDate}
        maxDate={getEarliestOccuringDate([
          yesterday,
          dayBeforeDate(values.uppsagningsdatum),
          dayBeforeDate(values.sistaAnstallningsdatum),
          toTrrDateOrDefault(values.lonSenastAndrad),
        ])}
        title={datumContent.anstallningsdatum}
      />
    </>
  )

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  if (ansokan.isRisk) {
    return renderAnstallningsdatum
  }

  return (
    <div>
      {renderAnstallningsdatum}

      <Gutter offset_xs={32} />

      {!isTioTbAnsokan && (
        <>
          <Uppsagningsdatum
            value={values.uppsagningsdatum}
            anstallningsdatum={values.anstallningsdatum}
            sistaAnstallningsdatum={values.sistaAnstallningsdatum}
            formikName={AnsokanKeys.Uppsagningsdatum}
            formikContext={formikContext}
            errorMsg={errors?.uppsagningsdatum as string}
            touched={touched?.uppsagningsdatum as boolean}
            datumContent={riskDatum}
          />

          <Gutter offset_xs={32} />
        </>
      )}

      <SistaAnstallningsdatum
        value={values.sistaAnstallningsdatum}
        anstallningsdatum={values.anstallningsdatum}
        uppsagningsdatum={values.uppsagningsdatum}
        lonSenastAndrad={values.lonSenastAndrad}
        formikName={AnsokanKeys.SistaAnstallningsdatum}
        formikContext={formikContext}
        errorMsg={errors?.sistaAnstallningsdatum as string}
        touched={touched?.sistaAnstallningsdatum as boolean}
        isTioTbAnsokan={isTioTbAnsokan}
        datumContent={riskDatum}
      />
    </div>
  )
}

export default Datum
