import React, { useCallback, useEffect, useState } from 'react'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import {
  IDetaljvyContent,
  IUppdragsAnsokanApiModel,
} from '@local/Views/Detaljvy/Detaljvy.types'
import { useGetAnsokanListQuery } from '@local/services/API/skapaAnsokanApi'
import { UUIDRegex, matchStringByRegex } from '@local/Utils/Helpers'
import Gutter from '@local/Components/Gutter'
import AnsokanTabs from '@local/Views/Detaljvy/AnsokanList/AnsokanTabs'
import AnsokanCard from '@local/Views/Detaljvy/AnsokanList/AnsokanCard/'
import IngaRaderOverlay from '@local/Views/Detaljvy/AnsokanList/IngaRaderOverlay'
import {
  useGetColumnHeaders,
  useSkickaInModal,
} from '@local/Views/Detaljvy/AnsokanList/hooks'
import { AnsokanState } from '@local/Common.types'
import {
  GridRowParams,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import MarkeradeAnsokningar from '@local/Views/Detaljvy/AnsokanList/MarkeradeAnsokningar'
import SkickaInModal from '@local/Views/Detaljvy/AnsokanList/SkickaInModal'
import { StyledDataGrid } from '@local/Views/Detaljvy/AnsokanList/styles'
import { getRowsPerTab } from '@local/Views/Detaljvy/AnsokanList/helpers'
import RedigeraInskickadAnsokanModal from '@local/Views/Detaljvy/AnsokanList/RedigeraInskickadAnsokanModal'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { useHistory } from 'react-router-dom'

const AnsokanList = (): JSX.Element => {
  const apiRef = useGridApiRef()
  const { arbetsgivaransokanLinks } = usePickEpiContent<IDetaljvyContent>()

  const [markeradeAnsokningar, setMarkeradeAnsokningar] = useState<
    IUppdragsAnsokanApiModel[]
  >([])
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)
  const [redigeraModalOpen, setRedigeraModalOpen] = useState<boolean>(false)
  const [currentAnsokanId, setCurrentAnsokanId] = useState<string>(null)

  const currentUppdragsId = matchStringByRegex(location.pathname, UUIDRegex)
  const { data: rows, isLoading } = useGetAnsokanListQuery({
    uppdragId: currentUppdragsId,
  })

  const history = useHistory()

  const [activeTab, setActiveTab] = useState<AnsokanState>(
    AnsokanState.Utkast as AnsokanState
  )
  const [ansokningarPerTab, setAnsokningarPerTab] = useState<Record<
    string,
    number
  > | null>(null)

  const {
    skickaInModalIsOpen,
    openSkickaInModal,
    shouldResetModalContent,
    closeSkickaInModal,
  } = useSkickaInModal()

  const getAnsokningarPerTab = useCallback(() => {
    if (rows) {
      return getRowsPerTab(rows)
    }
    return {}
  }, [rows])

  useEffect(() => {
    const counted = getAnsokningarPerTab()
    setAnsokningarPerTab(counted)
  }, [getAnsokningarPerTab, setAnsokningarPerTab])

  const redigeraButtonCallback = useCallback(
    (ansokan: IUppdragsAnsokanApiModel) => {
      setCurrentAnsokanId(ansokan.id)
      if (ansokan.ansokanState === AnsokanState.Inskickad) {
        setRedigeraModalOpen(true)
      } else {
        history.push(`${arbetsgivaransokanLinks.skapaAnsokanUrl}${ansokan.id}/`)
      }
    },
    [arbetsgivaransokanLinks.skapaAnsokanUrl, history]
  )

  const { columnHeaders } = useGetColumnHeaders(redigeraButtonCallback)

  const onRowsSelectionHandler = useCallback(
    (ids: GridRowSelectionModel) => {
      const selectedRowsData = ids.map((id) =>
        rows.find((row) => row.id === id)
      )

      setMarkeradeAnsokningar(selectedRowsData)
    },
    [rows]
  )

  useEffect(() => {
    if (markeradeAnsokningar.length > 0 && !skickaInModalIsOpen) {
      setSnackbarOpen(true)
    } else {
      setSnackbarOpen(false)
    }
  }, [markeradeAnsokningar, skickaInModalIsOpen])

  if (!rows) return

  return (
    <>
      <AnsokanTabs
        activeTab={activeTab}
        setActiveTabCallback={setActiveTab}
        ansokningarPerTab={ansokningarPerTab}
      />
      <Gutter offset_xs={24} />

      <StyledDataGrid
        apiRef={apiRef}
        loading={isLoading}
        rows={rows}
        columns={columnHeaders}
        checkboxSelection={activeTab === AnsokanState.Utkast}
        checkboxSelectionVisibleOnly
        isRowSelectable={(params: GridRowParams<IUppdragsAnsokanApiModel>) =>
          params.row.ansokanState !== AnsokanState.Inskickad
        }
        onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
        pagination
        pageSizeOptions={[5, 10, 25, 50]}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
          columns: {
            columnVisibilityModel: { ansokanState: false },
          },
        }}
        slots={{
          detailPanelExpandIcon: ExpandMoreRoundedIcon,
          detailPanelCollapseIcon: ExpandLessRoundedIcon,
          noRowsOverlay: () => <IngaRaderOverlay />,
        }}
        filterModel={{
          items: [
            { field: 'ansokanState', operator: 'contains', value: activeTab },
          ],
        }}
        getDetailPanelContent={({ row }: { row: IUppdragsAnsokanApiModel }) => (
          <AnsokanCard row={row} />
        )}
        getDetailPanelHeight={() => 'auto'}
        autosizeOnMount={false}
        autoHeight
      />

      <MarkeradeAnsokningar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        markeradeAnsokningar={markeradeAnsokningar}
        openSkickaInModal={openSkickaInModal}
      />

      <SkickaInModal
        apiRef={apiRef}
        skickaInModalIsOpen={skickaInModalIsOpen}
        shouldResetModalContent={shouldResetModalContent}
        markeradeAnsokningar={markeradeAnsokningar}
        setMarkeradeAnsokningar={setMarkeradeAnsokningar}
        closeSkickaInModal={closeSkickaInModal}
      />

      <RedigeraInskickadAnsokanModal
        modalOpen={redigeraModalOpen}
        setModalOpen={setRedigeraModalOpen}
        ansokanId={currentAnsokanId}
      />
    </>
  )
}

export default AnsokanList
