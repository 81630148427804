import React from 'react'
import { Typography } from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import Gutter from '@local/Components/Gutter'
import { ISkapaAnsokningarContent } from '@local/Views/SkapaAnsokningar/types/types'
import FileUploader from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/LaddaUppFil/FileUploader'

const LaddaUppFil = (): JSX.Element => {
  const {
    laggTillMedarbetare: { laddaUppFil },
  } = usePickEpiContent<ISkapaAnsokningarContent>()

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {laddaUppFil.heading}
      </Typography>

      <Typography variant="body1">{laddaUppFil.description}</Typography>

      <Gutter offset_xs={24} />

      <FileUploader />
    </>
  )
}

export default LaddaUppFil
