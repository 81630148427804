import React, { useEffect, useState } from 'react'
import { Box, useTheme } from '@mui/material'
import { IUppdragContent } from '@local/Views/Uppdrag/Uppdrag.types'
import OmAnstallningen from '@local/Views/Uppdrag/AnsokanList/AnsokanCard/OmAnstallningen'
import Divider from '@local/Components/Divider'
import OmUppsagningen from '@local/Views/Uppdrag/AnsokanList/AnsokanCard/OmUppsagningen'
import Ersattning from '@local/Views/Uppdrag/AnsokanList/AnsokanCard/Ersattning'
import Kontaktuppgifter from '@local/Views/Uppdrag/AnsokanList/AnsokanCard/Kontaktuppgifter'
import { useGetAnsokanQuery } from '@local/services/API/ansokan/skapaAnsokanApi'
import Toaster from '@local/Components/Toaster'
import { usePickEpiContent } from '@local/Utils/hooks'
import { AnsokanState, OrsakerTillUppsagning } from '@local/Common.types'
import OmRisk from '@local/Views/Uppdrag/AnsokanList/AnsokanCard/OmRisk'
import { IAnsokanCard } from '@local/Views/Uppdrag/AnsokanList/AnsokanCard/types'
import { useSelector } from 'react-redux'
import { selectAnsokanList } from '@local/Views/Uppdrag/AnsokanList/ansokanListSlice'
import { useGetUppdrag } from '@local/Utils/hooks/useGetUppdrag'

const AnsokanCard = ({
  row,
  redigeraButtonCallback,
}: IAnsokanCard): JSX.Element => {
  const theme = useTheme()

  const { activeTab } = useSelector(selectAnsokanList)

  const { nagotGickFel } = usePickEpiContent<IUppdragContent>()

  const [toasterOpen, setToasterOpen] = useState(false)
  const { isError } = useGetAnsokanQuery(row.id)

  const { data: uppdrag, error: isErrorUppdrag } = useGetUppdrag()

  const isTioTbAnsokan =
    uppdrag?.orsakTillUppsagning ===
    OrsakerTillUppsagning.TidsbegransadAnstallning

  const isRisk = uppdrag?.orsakTillUppsagning === OrsakerTillUppsagning.Risk

  useEffect(() => {
    if (isError || isErrorUppdrag) {
      setToasterOpen(true)
    } else {
      setToasterOpen(false)
    }
  }, [isError, isErrorUppdrag])

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.surface?.grey,
        padding: '24px 48px',
      }}
    >
      <OmAnstallningen row={row} />

      <Divider offset_xs={32} />

      {!isTioTbAnsokan && (
        <>
          <OmUppsagningen row={row} />
          <Divider offset_xs={32} />
        </>
      )}

      <Ersattning row={row} />

      <Divider offset_xs={32} />

      <Kontaktuppgifter row={row} />

      {isRisk && activeTab === AnsokanState.KompletteraRisk && (
        <>
          <Divider offset_xs={32} />

          <OmRisk row={row} redigeraButtonCallback={redigeraButtonCallback} />
        </>
      )}

      <Toaster
        message={nagotGickFel.heading}
        open={toasterOpen}
        setOpen={setToasterOpen}
      />
    </Box>
  )
}
export default AnsokanCard
