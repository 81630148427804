import React from 'react'
import Gutter from '@local/Components/Gutter'
import { usePickEpiContent } from '@local/Utils/hooks'
import { useFormikContext } from 'formik'
import { IUppdragFormWrapperContent } from '@local/Views/UppdragFormWrapper/UppdragFormWrapper.types'
import { IUppdragFormValues } from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.types'
import {
  useIsBegransadRedigering,
  useShouldShowFackligKontaktSaknas,
} from '@local/Views/UppdragFormWrapper/UppdragForm/hooks'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid2 as Grid,
  Typography,
} from '@mui/material'

const FackligKontaktSaknas = (): JSX.Element => {
  const { values, handleChange, handleBlur } =
    useFormikContext<IUppdragFormValues>()
  const { fackligKontaktSaknas } =
    usePickEpiContent<IUppdragFormWrapperContent>()

  const isBegransadRedigering = useIsBegransadRedigering()
  const shouldShowFackligKontaktSaknas = useShouldShowFackligKontaktSaknas()

  return (
    shouldShowFackligKontaktSaknas && (
      <Grid container>
        <Grid size={{ md: 8 }}>
          <FormControl variant="standard" fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={isBegransadRedigering}
                  checked={values.fackligRepSaknas}
                  color="primary"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="fackligRepSaknas"
                />
              }
              label={fackligKontaktSaknas.heading}
            />

            {values.fackligRepSaknas && (
              <>
                <Gutter offset_xs={8} />
                <Typography variant="subtitle2">
                  {fackligKontaktSaknas.preamble}
                </Typography>
              </>
            )}
          </FormControl>
        </Grid>
      </Grid>
    )
  )
}

export default FackligKontaktSaknas
