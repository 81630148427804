import React, { useCallback } from 'react'
import Header from '@local/Views/Detaljvy/Header'
import AnsokanList from '@local/Views/Detaljvy/AnsokanList/AnsokanList'
import Gutter from '@local/Components/Gutter'
import { useSignalRConnectToGroup } from '@local/Utils/Hooks'
import { IAnsokanUpdatedResponseModel, SignalRRoom } from '@local/App.types'
import { UUIDRegex, matchStringByRegex } from '@local/Utils/regexes'
import { baseApi } from '@local/services/API/baseApi'
import { useAppDispatch } from '@local/Store/configureStore'

const Detaljvy = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const currentUppdragsId = matchStringByRegex(location.pathname, UUIDRegex)

  useSignalRConnectToGroup({
    room: SignalRRoom.AnsokanUpdated,
    group: currentUppdragsId,
    onEventReceived: useCallback(
      (response: IAnsokanUpdatedResponseModel) => {
        dispatch(
          baseApi.util.invalidateTags([
            { type: 'ansokan', id: 'LIST' },
            { type: 'ansokan', id: response.AnsokanId },
          ])
        )
      },
      [dispatch]
    ),
  })

  return (
    <>
      <Header />

      <Gutter offset_xs={32} />

      <AnsokanList />
    </>
  )
}

export default Detaljvy
