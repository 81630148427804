import React from 'react'
import AnsokningarInvalid from '@local/Views/Uppdrag/AnsokanList/SkickaInModal/AnsokningarInvalid'
import ForsakraUppgifter from '@local/Views/Uppdrag/AnsokanList/SkickaInModal/ForsakraUppgifter'
import { ISkickaInModal } from '@local/Views/Uppdrag/AnsokanList/SkickaInModal/SkickaInModal.types'
import { Dialog } from '@mui/material'
import { ArbetsgivaransokanStatus } from '@local/Common.types'

const SkickaInModal = ({
  markeradeAnsokningar,
  shouldResetModalContent,
  skickaInModalIsOpen,
  apiRef,
  setMarkeradeAnsokningar,
  closeSkickaInModal,
}: ISkickaInModal): JSX.Element => {
  const hasInvalidAnsokningar = markeradeAnsokningar.some(
    (ansokan) =>
      ansokan.arbetsgivaransokanStatus === ArbetsgivaransokanStatus.EjKomplett
  )

  return (
    <>
      <Dialog open={skickaInModalIsOpen} onClose={closeSkickaInModal}>
        {hasInvalidAnsokningar && (
          <AnsokningarInvalid
            markeradeAnsokningar={markeradeAnsokningar}
            setMarkeradeAnsokningar={setMarkeradeAnsokningar}
            closeSkickaInModal={closeSkickaInModal}
            apiRef={apiRef}
          />
        )}

        {!hasInvalidAnsokningar && (
          <ForsakraUppgifter
            markeradeAnsokningar={markeradeAnsokningar}
            shouldResetModalContent={shouldResetModalContent}
            closeModal={closeSkickaInModal}
          />
        )}
      </Dialog>
    </>
  )
}

export default SkickaInModal
