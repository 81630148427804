import { useAppDispatch } from '@local/Store/configureStore'
import { usePickEpiContent } from '@local/Utils/hooks'
import { useGetUppdrag } from '@local/Utils/hooks/useGetUppdrag'
import { LaggTillMedarbetareFormTypes } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/types/types'
import { selectErrorStepper } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/ErrorStepper/errorStepperSlice'
import {
  goToNext,
  goToPrevious,
  selectSkapaAnsokningarStepper,
} from '@local/Views/SkapaAnsokningar/skapaAnsokningarStepperSlice'
import {
  ISkapaAnsokningarContent,
  ISkapaAnsokningarFormValues,
  SkapaAnsokningarStep,
} from '@local/Views/SkapaAnsokningar/types/types'
import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik'
import { isNil, omit } from 'ramda'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const useStepperActions = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState(false)

  const { activeStep } = useSelector(selectSkapaAnsokningarStepper)
  const { errors } = useSelector(selectErrorStepper)

  const { data: uppdrag } = useGetUppdrag()

  const {
    arbetsgivaransokanLinks: { laggTillMedarbetareUrl },
  } = usePickEpiContent<ISkapaAnsokningarContent>()

  const {
    values,
    errors: formikErrors,
    validateForm,
    setTouched,
  } = useFormikContext<ISkapaAnsokningarFormValues>()

  const validateLaggTillMedarbetare = useCallback(
    (callback: () => void) => {
      validateForm(omit(['filansokningar'], values))
        .then((validationErrors) => {
          const formIsValid =
            isNil(validationErrors.file) &&
            isNil(validationErrors.laggTillMedarbetareChoice)

          if (formIsValid) {
            callback()
          } else {
            setTouched(
              setNestedObjectValues<FormikTouched<ISkapaAnsokningarFormValues>>(
                validationErrors,
                true
              )
            )?.catch((err) => console.log(err))
            setShouldShowErrorMessage(true)
          }
        })
        .catch((e) => console.log(e))
    },
    [setTouched, validateForm, values]
  )

  const validateStepperErrors = useCallback(
    (callback: () => void) => {
      if (errors.length <= 0) {
        callback()
      } else {
        setTouched(
          setNestedObjectValues<FormikTouched<ISkapaAnsokningarFormValues>>(
            formikErrors,
            true
          )
        )?.catch((err) => console.log(err))
        setShouldShowErrorMessage(true)
      }
    },
    [errors, formikErrors, setTouched]
  )

  const validateGranskaSkickaIn = useCallback(
    (callback: () => void) => {
      validateStepperErrors(callback)
    },
    [validateStepperErrors]
  )

  const validateSammanfattning = useCallback(
    (callback: () => void) => {
      validateStepperErrors(callback)
    },
    [validateStepperErrors]
  )

  const validateStep = useCallback(
    (callback: () => void) => {
      switch (activeStep) {
        case SkapaAnsokningarStep.LaggTillMedarbetare:
          return validateLaggTillMedarbetare(callback)
        case SkapaAnsokningarStep.Sammanfattning:
          return validateSammanfattning(callback)
        case SkapaAnsokningarStep.GranskaSkickaIn:
          return validateGranskaSkickaIn(callback)
        default:
          return callback
      }
    },
    [
      activeStep,
      validateGranskaSkickaIn,
      validateLaggTillMedarbetare,
      validateSammanfattning,
    ]
  )

  const goToNextStep = useCallback(() => {
    validateStep(() => {
      if (
        values.laggTillMedarbetareChoice ===
        LaggTillMedarbetareFormTypes.LaggTillMedarbetareFormular
      ) {
        history.push(`${laggTillMedarbetareUrl}${uppdrag?.id}/`)
      } else {
        dispatch(goToNext())
        window.scroll({ top: 0 })
      }
    })
  }, [
    dispatch,
    history,
    uppdrag,
    laggTillMedarbetareUrl,
    values?.laggTillMedarbetareChoice,
    validateStep,
  ])

  const goToPreviousStep = useCallback(() => {
    dispatch(goToPrevious())
    window.scroll({ top: 0 })
  }, [dispatch])

  return {
    goToNextStep,
    goToPreviousStep,
    validateStep,
    shouldShowErrorMessage,
    setShouldShowErrorMessage,
  }
}

export default useStepperActions
