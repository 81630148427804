import React, { useState } from 'react'
import {
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@mui/material'
import Spinner from '@local/Components/Spinner'
import { usePickEpiContent } from '@local/Utils/hooks'
import { useIsFeatureEnabled } from '@trr/app-shell-data'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import Alert from '@local/Components/Alert'
import { ITaBortAnsokningar } from '@local/Views/Uppdrag/AnsokanList/TaBortModal/TaBortAnsokningar/TaBortAnsokningar.types'
import { useDeleteAnsokningarMutation } from '@local/services/API/ansokan/skapaAnsokanApi'
import {
  IDeleteAnsokanError,
  IDeleteAnsokningarRequest,
  IUppdragContent,
} from '@local/Views/Uppdrag/Uppdrag.types'
import { replaceEpiVariables } from '@local/Utils/helpers'

const TaBortAnsokningar = ({
  markeradeAnsokningar,
  closeModal,
}: ITaBortAnsokningar): JSX.Element => {
  const { taBortModal } = usePickEpiContent<IUppdragContent>()

  const shouldTrack = useIsFeatureEnabled('gtm.enabled.kundansokan')
  const trackButtonClick = useTrackCustomClick()

  const [errorMessage, setErrorMessage] = useState(
    taBortModal.errorRequestMessage
  )
  const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState(false)

  const [deleteAnokningar, { isLoading: isLoadingDeleteAnsokningar }] =
    useDeleteAnsokningarMutation()

  const handleDelete = async (): Promise<void> => {
    const ansokningarToDelete: IDeleteAnsokningarRequest = {
      ansokanId: markeradeAnsokningar.map((ansokan) => ansokan.id),
    }
    await deleteAnokningar(ansokningarToDelete).unwrap()
  }

  const handleOnConfirm = (): void => {
    setShouldShowErrorMessage(false)

    if (shouldTrack) {
      trackButtonClick('Modal: Ta bort ansökningar', {
        label: 'Ta bort ansökningar',
      })
    }
    handleDelete()
      .then(() => {
        closeModal()
      })
      .catch((error: { status: number; data: IDeleteAnsokanError[] }) => {
        console.log(error)
        const failedAnsokanIds = error.data.map((e) => e.entityId)
        setErrorMessage(
          replaceEpiVariables(taBortModal.errorRequestMessage, {
            failedAnsokanCount: String(failedAnsokanIds.length),
          })
        )
        setShouldShowErrorMessage(true)
      })
  }

  const handleOnClose = () => {
    if (shouldTrack) {
      trackButtonClick('Modal: Ta bort ansökningar', {
        label: 'Avbryt',
      })
    }
    closeModal()
  }

  return (
    <>
      <DialogTitle>{taBortModal.heading}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!shouldShowErrorMessage && taBortModal.description}

          {isLoadingDeleteAnsokningar && <Spinner centered />}
          {shouldShowErrorMessage && (
            <Alert severity="error">{errorMessage}</Alert>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={handleOnClose}
          disabled={isLoadingDeleteAnsokningar}
        >
          {taBortModal.cancelLabel}
        </Button>

        <Button
          variant="text"
          onClick={handleOnConfirm}
          disabled={isLoadingDeleteAnsokningar}
        >
          {taBortModal.confirmLabel}
        </Button>
      </DialogActions>
    </>
  )
}

export default TaBortAnsokningar
