import React, { useCallback, useEffect } from 'react'
import Spinner from '@local/Components/Spinner'
import Gutter from '@local/Components/Gutter'
import { Typography, Grid } from '@mui/material'
import EjInskickade from '@local/Views/UppdragDetaljvy/EjInskickade'
import EjFullstandiga from '@local/Views/UppdragDetaljvy/EjFullstandiga'
import Inskickade from '@local/Views/UppdragDetaljvy/Inskickade'
import useGetGroupedAnsokningar from '@local/Views/UppdragDetaljvy/useGetGroupedAnsokningar'
import useGetUppdrag from '@local/Views/UppdragDetaljvy/useGetUppdrag'
import SkickaIn from '@local/Views/UppdragDetaljvy/SkickaIn'
import UppdragCard from '@local/Views/UppdragDetaljvy/UppdragCard/UppdragCard'
import Header from '@local/Views/UppdragDetaljvy/Header'
import KompletteraRisk from '@local/Views/UppdragDetaljvy/KompletteraRisk'
import FackligaKontaktpersoner from '@local/Views/UppdragDetaljvy/FackligaKontaktpersoner'
import Administratorer from '@local/Views/UppdragDetaljvy/Administratorer'
import { IUppdragDetaljvyContent } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import { usePickEpiContent, useSignalRConnectToGroup } from '@local/Utils/Hooks'
import { SignalRRoom } from '@local/App.types'
import InskickadAnsokanUnderRedigering from '@local/Views/UppdragDetaljvy/InskickadAnsokanUnderRedigering'
import Alert from '@local/Components/Alert'

const UppdragDetaljvy = (): JSX.Element => {
  const [checkedState, setCheckedState] = React.useState<boolean[]>([])
  const { nagotGickFel, uppdateringProcessas } =
    usePickEpiContent<IUppdragDetaljvyContent>()

  const {
    data: groupedAnsokningar,
    isLoading: isLoadingGroupedAnsokningar,
    isSuccess: groupedAnsokningarIsSuccess,
    error: isErrorAnsokningar,
  } = useGetGroupedAnsokningar()

  const {
    data: uppdrag,
    isLoading: isLoadingUppdrag,
    error: isErrorUppdrag,
    refetch,
  } = useGetUppdrag()

  useSignalRConnectToGroup({
    room: SignalRRoom.UppdragUpdated,
    group: uppdrag?.id,
    onEventReceived: useCallback(() => {
      void refetch()
    }, [refetch]),
  })

  useEffect(() => {
    if (groupedAnsokningarIsSuccess) {
      setCheckedState(
        Array.from(
          { length: groupedAnsokningar?.fullstandiga?.length },
          () => false
        )
      )
    }
  }, [groupedAnsokningar, groupedAnsokningarIsSuccess])

  const handleCheckedOnChange = (index: number) => {
    const newState = [...checkedState]
    newState[index] = !newState[index]

    setCheckedState(newState)
  }

  const handleCheckAll = () => {
    const allAreChecked =
      checkedState.every((checked) => checked) && checkedState?.length > 0

    const updatedCheckedState = Array.from(
      { length: groupedAnsokningar?.fullstandiga?.length },
      () => !allAreChecked
    )

    setCheckedState(updatedCheckedState)
  }

  if (isLoadingGroupedAnsokningar || isLoadingUppdrag) {
    return <Spinner centered />
  }

  if (isErrorAnsokningar || isErrorUppdrag) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  return (
    <section>
      <UppdragCard />

      <KompletteraRisk />

      <InskickadAnsokanUnderRedigering />

      {uppdrag.isLocked ? (
        <Grid container direction="row" alignItems="center">
          <Spinner size={24} />
          <Typography variant="subtitle2" ml={1.5}>
            {uppdateringProcessas.heading}
          </Typography>
        </Grid>
      ) : (
        <SkickaIn checkedState={checkedState} />
      )}

      <Gutter offset_xs={32} />

      <Header checkedState={checkedState} handleCheck={handleCheckAll} />

      <Gutter offset_xs={32} />

      <EjInskickade
        checkedState={checkedState}
        handleCheckedOnChange={handleCheckedOnChange}
      />

      <Gutter offset_xs={32} />

      <EjFullstandiga />

      <Gutter offset_xs={32} />

      <Inskickade />

      <Gutter offset_xs={32} />

      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Administratorer />
        </Grid>
        <Grid item md={6} xs={12}>
          <FackligaKontaktpersoner />
        </Grid>
      </Grid>
    </section>
  )
}

export default UppdragDetaljvy
