import React from 'react'
import { Grid, Typography } from '@mui/material'
import { useGetAnsokanQuery } from '@local/services/API/ansokan/skapaAnsokanApi'
import { usePickEpiContent } from '@local/Utils/Hooks'
import {
  IDetaljvyContent,
  IUppdragsAnsokanApiModel,
} from '@local/Views/Detaljvy/Detaljvy.types'
import { isNil } from 'ramda'
import { trrFormat } from '@local/Utils/Helpers/formatDate'
import Gutter from '@local/Components/Gutter'
import { formatPercentageNumber } from '@local/Utils/Helpers'
import KeyValuePair from '@local/Views/Detaljvy/AnsokanList/AnsokanCard/KeyValuePair'
import useGetUppdragIsRisk from '@local/Views/UppdragDetaljvy/useGetUppdragIsRisk'
import useGetUppdrag from '@local/Views/UppdragDetaljvy/useGetUppdrag'
import { OrsakerTillUppsagning } from '@local/Common.types'

const OmUppsagningen = ({
  row,
}: {
  row: IUppdragsAnsokanApiModel
}): JSX.Element => {
  const {
    ansokanDetaljer: { omUppsagningen, common },
  } = usePickEpiContent<IDetaljvyContent>()
  const { data: ansokan, isLoading, isError } = useGetAnsokanQuery(row.id)

  const {
    data: uppdrag,
    isError: isErrorUppdrag,
    isLoading: isLoadingUppdrag,
  } = useGetUppdrag()

  const isTioTbAnsokan =
    uppdrag?.orsakTillUppsagning ===
    OrsakerTillUppsagning.TidsbegransadAnstallning

  const isRisk = useGetUppdragIsRisk()

  const shouldShowSkeleton =
    isLoading || isError || !ansokan || isErrorUppdrag || isLoadingUppdrag

  return (
    <>
      <Typography variant="subtitle2">{omUppsagningen.heading}</Typography>

      <Gutter offset_xs={16} />

      <Grid container spacing={2}>
        {!isTioTbAnsokan && (
          <KeyValuePair
            title={omUppsagningen.uppsagningsdatum}
            value={
              isRisk
                ? common.ejFaststalltLabel
                : !isNil(ansokan?.anstallningsuppgifter?.uppsagningsdatum) &&
                  trrFormat(ansokan?.anstallningsuppgifter?.uppsagningsdatum)
            }
            isLoading={shouldShowSkeleton}
          />
        )}

        {!isTioTbAnsokan && (
          <KeyValuePair
            title={omUppsagningen.uppsagningsgrad}
            value={
              isRisk
                ? common.ejFaststalltLabel
                : !isNil(ansokan?.anstallningsuppgifter?.uppsagningsgrad) &&
                  formatPercentageNumber(
                    ansokan?.anstallningsuppgifter?.uppsagningsgrad
                  )
            }
            isLoading={shouldShowSkeleton}
          />
        )}

        <KeyValuePair
          title={omUppsagningen.sistaAnstallningsdag}
          value={
            isRisk
              ? common.ejFaststalltLabel
              : !isNil(
                  ansokan?.anstallningsuppgifter?.sistaAnstallningsdatum
                ) &&
                trrFormat(
                  ansokan?.anstallningsuppgifter?.sistaAnstallningsdatum
                )
          }
          isLoading={shouldShowSkeleton}
        />
      </Grid>
    </>
  )
}

export default OmUppsagningen
