import React from 'react'
import { Chip } from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { getBeslutColorAndIcon } from '@local/Utils/Helpers/Uppdrag.helpers'
import { IUppdragDetaljvyContent } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import { Beslut } from '@local/Common.types'
import { styled } from '@mui/material/styles'

const StyledStatusChip = styled('div')(({ theme }) => ({
  userSelect: 'none',
  pointerEvents: 'none',
  cursor: 'pointer',

  '&:nth-child(2)': {
    marginLeft: theme.spacing(1),
  },

  display: 'flex',
  '.MuiChip-colorError .MuiSvgIcon-root': {
    color: theme.palette.error.main,
  },
  '.MuiChip-colorSuccess .MuiSvgIcon-root': {
    color: theme.palette.success.main,
  },
  '.MuiChip-colorInfo .MuiSvgIcon-root': {
    color: theme.palette.info.main,
  },
  '.MuiChip-colorWarning .MuiSvgIcon-root': {
    color: theme.palette.warning.main,
  },
  '&.MuiChip-colorPrimary .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
  '.MuiChip-colorSecondary .MuiSvgIcon-root': {
    color: theme.palette.secondary.main,
  },
}))

const BeslutChip = ({
  beslut,
  size = 'small',
}: {
  beslut: Beslut
  size?: 'small' | 'medium'
}): JSX.Element => {
  const { beslut: beslutContent } =
    usePickEpiContent<IUppdragDetaljvyContent>()
    
  if (!beslut) {
    return null
  }
  const { color: chipColor, icon: chipIcon } = getBeslutColorAndIcon(beslut)

  return (
    <StyledStatusChip>
      <Chip
        size={size}
        color={chipColor}
        icon={chipIcon}
        label={beslutContent[beslut]}
      />
    </StyledStatusChip>
  )
}

export default BeslutChip
