import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import { IUppdragFormWrapperContent } from '@local/Views/UppdragFormWrapper/UppdragFormWrapper.types'
import UppdragForm from '@local/Views/UppdragFormWrapper/UppdragForm'
import { useCreateUppdragMutation } from '@local/services/API/uppdrag/uppdragApi'
import { usePickEpiContent } from '@local/Utils/Hooks'
import Gutter from '@local/Components/Gutter'
import Alert from '@local/Components/Alert'

const SkapaUppdrag = (): JSX.Element => {
  const content = usePickEpiContent<IUppdragFormWrapperContent>()

  const [, { isError, reset }] = useCreateUppdragMutation({
    fixedCacheKey: 'shared-create-uppdrag',
  })

  useEffect(
    () => () => {
      reset()
    },
    [reset]
  )

  return (
    <>
      {isError && (
        <Grid container>
          <Grid item md={8}>
            <Alert severity="error">{content.nagotGickFel.heading}</Alert>
            <Gutter offset_xs={16} />
          </Grid>
        </Grid>
      )}

      <UppdragForm content={content} uppdrag={null} />
    </>
  )
}

export default SkapaUppdrag
