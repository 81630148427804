import React, { useEffect } from 'react'
import FileUpload from '@local/Components/FileUpload'
import { matchStringByRegex, UUIDRegex } from '@local/Utils/helpers'
import {
  useUploadOverenskommelseBilagaMutation,
  useDeleteOverenskommelseBilagaMutation,
} from '@local/services/API/bilagor/overenskommelseBilagaApi'
import useGetAnsokan from '@local/Views/SkapaAnsokan/useGetAnsokan'
import Gutter from '@local/Components/Gutter'
import Alert from '@local/Components/Alert'
import { IUniqueFile } from '@local/services/API/bilagor/types'

import { IOverenskommelseBilagaProps } from './OverenskommelseBilaga.types'
import useGetAllOverenskommelseBilaga from './useGetAllOverenskommelseBilaga'

const OverenskommelseBilaga = ({
  content,
  formikFieldName,
  files,
  errorMessage,
  setFieldValue,
  nagotGickFel,
}: IOverenskommelseBilagaProps): JSX.Element => {
  const ansokanId = matchStringByRegex(window.location.pathname, UUIDRegex)

  const { data, error: errorGetBilagor } = useGetAllOverenskommelseBilaga()
  const { data: ansokan, error: errorGetAnsokan } = useGetAnsokan()

  const [uploadBilaga, { isLoading: isLoadingUpload }] =
    useUploadOverenskommelseBilagaMutation()
  const [deleteBilaga] = useDeleteOverenskommelseBilagaMutation()

  useEffect(() => {
    if (data) {
      const bilagor: IUniqueFile[] = data.map((bilaga) => ({
        id: bilaga.id,
        name: bilaga.name,
      }))
      setFieldValue(formikFieldName, bilagor)?.catch((err) => console.log(err))
    }
  }, [data, setFieldValue, formikFieldName])

  const uploadHandler = async (
    file: IUniqueFile,
    fileStream: File
  ): Promise<Response> => {
    const formData = new FormData()
    formData.append('fileId', file.id)
    formData.append('file', fileStream)
    formData.append('klientId', ansokan.klientId)
    return uploadBilaga({ formData, ansokanId }).unwrap()
  }

  const deleteHandler = (file: IUniqueFile) =>
    deleteBilaga({ ansokanId, bilagaId: file.id }).unwrap()

  if (errorGetBilagor || errorGetAnsokan) {
    return (
      <>
        <Gutter offset_xs={8} />
        <Alert severity="error">{nagotGickFel.heading}</Alert>
      </>
    )
  }

  return (
    <FileUpload
      isLoadingUpload={isLoadingUpload}
      content={{
        ...content,
        heading: null,
      }}
      inputName={formikFieldName}
      files={files}
      setFieldValue={setFieldValue}
      errorMessage={errorMessage}
      filePickedHandler={uploadHandler}
      fileDeletedHandler={deleteHandler}
      multiple
    />
  )
}

export default OverenskommelseBilaga
