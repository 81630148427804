import { baseApi } from '@local/services/API/baseApi'
import { getAnsokanId } from '@local/Utils/Helpers'
import { IPatchApiInput } from '@local/services/types/patchTypes'
import { mapAnsokanValuesToPatchModel } from '@local/Views/SkapaAnsokan/SkapaAnsokanNavigation/AnsokanFormValuesMapper'
import {
  IUppdragsAnsokanApiModel,
  IUppdragsAnsokanPayloadModel,
  IDeleteAnsokningarRequest,
  IDeleteAnsokanError,
} from '@local/Views/Detaljvy/Detaljvy.types'
import { IAnsokan } from '@local/services/API/ansokan/types'

export const skapaAnsokanApi = baseApi.injectEndpoints({
  overrideExisting: false,

  endpoints: (builder) => ({
    getAnsokan: builder.query<IAnsokan, string>({
      query: (ansokanId) => `ansokan/${ansokanId}`,
      providesTags: (_result, _error, id) => [{ type: 'ansokan', id }],
      keepUnusedDataFor: 0,
    }),
    getKanRedigeraAnsokan: builder.query<boolean, void>({
      query: () => `ansokan/${getAnsokanId()}/kanredigera`,
      providesTags: [{ type: 'ansokan' }],
      keepUnusedDataFor: 0,
    }),
    patchAnsokan: builder.mutation<Response, IPatchApiInput>({
      query: ({ values, prevValues }) => ({
        url: `ansokan/${getAnsokanId()}`,
        method: 'PATCH',
        body: mapAnsokanValuesToPatchModel(values, prevValues),
      }),
      invalidatesTags: () => [
        'uppdrag',
        { type: 'ansokan', id: 'GROUPED_LIST' },
        { type: 'ansokan', id: 'LIST' },
        { type: 'ansokan', id: getAnsokanId() },
      ],
    }),
    deleteAnsokanDraft: builder.mutation<Response, string>({
      query: (ansokanId) => ({
        url: `ansokan/${ansokanId}/draft`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [
        { type: 'ansokan', id: 'GROUPED_LIST' },
        { type: 'uppdrag', id: 'LIST' },
      ],
    }),
    deleteAnsokningar: builder.mutation<
      IDeleteAnsokanError[],
      IDeleteAnsokningarRequest
    >({
      query: (ansokanQuery) => ({
        url: `ansokan/`,
        method: 'DELETE',
        body: ansokanQuery,
      }),
      invalidatesTags: () => [
        { type: 'ansokan', id: 'LIST' },
        { type: 'ansokan', id: 'GROUPED_LIST' },
      ],
    }),
    getAnsokanList: builder.query<
      IUppdragsAnsokanApiModel[],
      IUppdragsAnsokanPayloadModel
    >({
      query: ({ uppdragId }) => `v2/uppdrag/${uppdragId}/ansokan`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'ansokan' as const, id })),
              { type: 'ansokan', id: 'LIST' },
            ]
          : [{ type: 'ansokan', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetAnsokanQuery,
  useGetKanRedigeraAnsokanQuery,
  usePatchAnsokanMutation,
  useDeleteAnsokanDraftMutation,
  useDeleteAnsokningarMutation,
  useGetAnsokanListQuery,
} = skapaAnsokanApi
