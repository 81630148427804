import React from 'react'
import { ISteps } from '@local/Components/Stepper/Stepper.types'
import { useSelector } from 'react-redux'
import {
  ISkapaAnsokningarContent,
  SkapaAnsokningarStep,
} from '@local/Views/SkapaAnsokningar/types/types'
import Sammanfattning from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning'
import { isNil } from 'ramda'
import useGetSteps from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/useGetSteps'
import StepperProgress from '@local/Components/StepperProgress'
import LaggTillMedarbetare from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare'
import { Typography, styled } from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import Gutter from '@local/Components/Gutter'
import Uppdragsinfo from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Uppdragsinfo'
import Divider from '@local/Components/Divider'
import GranskaSkickain from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/GranskaSkickaIn/'
import { selectSkapaAnsokningarStepper } from '@local/Views/SkapaAnsokningar/skapaAnsokningarStepperSlice'
import useUpdateStepParam from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/useUpdateStepParam'
import useSetDefaultStep from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/useSetDefaultStep'

const StyledHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',

  '& > div:first-of-type': {
    width: '100%',
  },

  [theme.breakpoints.up('md')]: {
    flexWrap: 'nowrap',
  },
}))

const useStepper = (): {
  stepper: JSX.Element
  steps: ISteps[]
  currentStepBody: JSX.Element
  currentStepHeader: JSX.Element
  nextStepPreview: JSX.Element
  currentStepTitle: JSX.Element
} => {
  const { steps: stepsContent } = usePickEpiContent<ISkapaAnsokningarContent>()

  useSetDefaultStep()
  useUpdateStepParam()

  const { steps } = useGetSteps()
  const { activeStep } = useSelector(selectSkapaAnsokningarStepper)

  const isLastStep = Number(activeStep) === steps.length - 1
  const isFirstStep = Number(activeStep) === 0

  const stepper = isNil(activeStep) ? null : (
    <StepperProgress steps={steps} activeStep={activeStep} />
  )

  const stepperContent: { [step: number]: JSX.Element } = {
    [SkapaAnsokningarStep.LaggTillMedarbetare]: <LaggTillMedarbetare />,
    [SkapaAnsokningarStep.Sammanfattning]: <Sammanfattning />,
    [SkapaAnsokningarStep.GranskaSkickaIn]: <GranskaSkickain />,
  }

  const stepperTitles: { [step: number]: string } = {
    [SkapaAnsokningarStep.LaggTillMedarbetare]:
      stepsContent.laggTillMedarbetare,
    [SkapaAnsokningarStep.Sammanfattning]: stepsContent.sammanfattning,
    [SkapaAnsokningarStep.GranskaSkickaIn]: stepsContent.granskaSkickaIn,
  }

  const nextStepPreview = (
    <Typography variant="body1">
      {stepsContent.heading}
      {isLastStep ? stepsContent.finalStep : stepperTitles[activeStep + 1]}
    </Typography>
  )
  const currentStepTitle = (
    <Typography variant="h3">{stepperTitles[activeStep]}</Typography>
  )

  const currentStepBody = <>{stepperContent[activeStep]}</>

  const currentStepHeader =
    activeStep === SkapaAnsokningarStep.GranskaSkickaIn ? (
      currentStepTitle
    ) : (
      <>
        <StyledHeader>
          <div>
            {currentStepTitle}

            <Gutter offset_xs={8} />

            {nextStepPreview}
          </div>

          {!isFirstStep && <Uppdragsinfo />}
        </StyledHeader>
        {!isFirstStep && <Divider offset_xs={32} />}
      </>
    )

  return {
    stepper,
    steps,
    currentStepHeader,
    currentStepTitle,
    currentStepBody,
    nextStepPreview,
  }
}

export default useStepper
