import DataGrid from '@local/Components/DataGrid'
import { styled } from '@mui/material'

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  fontSize: 16,
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'normal',
  },
  '& .MuiDataGrid-cell': {
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    borderTop: `0.5px solid ${theme.palette.grey[100]}`,
    borderBottom: `0.5px solid ${theme.palette.grey[100]}`,
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    width: '100%',
    justifyContent: 'left',
    '& .MuiCheckbox-root': {
      width: 30,
    },
  },
  '& .MuiDataGrid-detailPanel': {
    zIndex: 'unset',
  },
  '--DataGrid-overlayHeight': '260px', // Height of 5 rows (5*52px)
}))
