import React, { useState } from 'react'
import Spinner from '@local/Components/Spinner'
import Gutter from '@local/Components/Gutter'
import { Typography, Button } from '@mui/material'
import { IUppdragDetaljvyContent } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import useGetGroupedAnsokningar from '@local/Views/UppdragDetaljvy/useGetGroupedAnsokningar'
import AnsokanCard from '@local/Views/UppdragDetaljvy/AnsokanCard/AnsokanCard'
import useGetUppdragIsRisk from '@local/Views/UppdragDetaljvy/useGetUppdragIsRisk'
import { useIsBelowMediumScreens, usePickEpiContent } from '@local/Utils/Hooks'
import RedigeraInskickadAnsokanModal from '@local/Views/UppdragDetaljvy/Inskickade/RedigeraInskickadAnsokanModal'
import Alert from '@local/Components/Alert'

const Inskickade = (): JSX.Element => {
  const {
    inskickade,
    nagotGickFel,
    arbetsgivaransokanLinks: { kompletteraRiskUrl },
  } = usePickEpiContent<IUppdragDetaljvyContent>()

  const {
    data: groupedAnsokningar,
    isLoading,
    isError,
  } = useGetGroupedAnsokningar()
  const isRisk = useGetUppdragIsRisk()
  const isBelowMediumScreens = useIsBelowMediumScreens()

  const [currentAnsokanId, setCurrentAnsokanId] = useState<string>('')
  const [modalOpen, setModalOpen] = useState(false)

  if (isLoading) {
    return <Spinner centered />
  }

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  return (
    <div aria-label="Inskickade ansökningar">
      <Typography variant="h5">
        {isRisk ? inskickade.headingRisk : inskickade.heading}
      </Typography>

      <Gutter offset_xs={16} />

      {groupedAnsokningar?.inskickade.length === 0 && (
        <Typography variant="body1">
          {isRisk
            ? inskickade.riskAnsokningarSaknas
            : inskickade.ansokningarSaknas}
        </Typography>
      )}

      {groupedAnsokningar?.inskickade.map((ansokan) => (
        <AnsokanCard
          ansokan={ansokan}
          key={ansokan.id}
          checked
          disabledCheckbox
          primaryButton={
            isRisk ? (
              <Button
                href={`${kompletteraRiskUrl}${ansokan.id}/`}
                fullWidth={isBelowMediumScreens}
              >
                {inskickade.kompletteraRiskButtonLabel}
              </Button>
            ) : (
              ansokan.isRedigerbar && (
                <Button
                  onClick={() => {
                    setCurrentAnsokanId(ansokan.id)
                    setModalOpen(true)
                  }}
                  fullWidth={isBelowMediumScreens}
                >
                  {inskickade.redigeraButtonLabel}
                </Button>
              )
            )
          }
          secondaryButton={
            isRisk &&
            ansokan.isRedigerbar && (
              <Button
                variant="outlined"
                onClick={() => {
                  setCurrentAnsokanId(ansokan.id)
                  setModalOpen(true)
                }}
                fullWidth={isBelowMediumScreens}
              >
                {inskickade.redigeraButtonLabel}
              </Button>
            )
          }
        />
      ))}

      <RedigeraInskickadAnsokanModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        ansokanId={currentAnsokanId}
      />
    </div>
  )
}

export default Inskickade
