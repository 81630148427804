import React from 'react'
import { useFormikContext } from 'formik'
import Gutter from '@local/Components/Gutter'
import CommonUppsagningsgrad from '@local/Components/Uppsagningsgrad'
import Uppsagningsdatum from '@local/Components/Uppsagningsdatum'
import SistaAnstallningsdatum from '@local/Components/SistaAnstallningsdatum'
import { Typography, Grid } from '@mui/material'
import { IKompletteraRiskansokanContent } from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokan.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IKompletteraRiskansokanFormValues } from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokanForm/KompletteraRiskansokanForm.types'
import useGetAnsokan from '@local/Views/SkapaAnsokan/useGetAnsokan'
import { toTrrDateOrDefault } from '@local/Utils/Helpers/formatDate'
import Alert from '@local/Components/Alert'
import { AnsokanType } from '@local/Common.types'

const KompletteringAvUppgifter = (): JSX.Element => {
  const { data: ansokan, isError } = useGetAnsokan()

  const { uppsagningsgrad, riskDatum, kompletteringAvUppgifter, nagotGickFel } =
    usePickEpiContent<IKompletteraRiskansokanContent>()

  const formikContext = useFormikContext<IKompletteraRiskansokanFormValues>()
  const { errors, touched, values } = formikContext

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  return (
    <Grid container>
      <Grid item xs md={5}>
        <Typography variant="h6">{kompletteringAvUppgifter.heading}</Typography>

        <Gutter offset_xs={32} />

        <Uppsagningsdatum
          value={values.uppsagningsdatum}
          anstallningsdatum={toTrrDateOrDefault(
            ansokan?.anstallningsuppgifter.anstallningsdatum
          )}
          sistaAnstallningsdatum={values.sistaAnstallningsdatum}
          formikName="uppsagningsdatum"
          formikContext={formikContext}
          errorMsg={errors.uppsagningsdatum as string}
          touched={touched.uppsagningsdatum as boolean}
          datumContent={riskDatum}
        />

        <Gutter offset_xs={32} />

        <SistaAnstallningsdatum
          value={values.sistaAnstallningsdatum}
          anstallningsdatum={toTrrDateOrDefault(
            ansokan?.anstallningsuppgifter.anstallningsdatum
          )}
          uppsagningsdatum={values.uppsagningsdatum}
          lonSenastAndrad={toTrrDateOrDefault(
            ansokan?.loneuppgifter.lonerevisionsdatum
          )}
          formikName="sistaAnstallningsdatum"
          formikContext={formikContext}
          errorMsg={errors.sistaAnstallningsdatum as string}
          touched={touched.sistaAnstallningsdatum as boolean}
          datumContent={riskDatum}
          isTioTbAnsokan={
            ansokan?.type === AnsokanType.omstallningTidsbegransadAnstallning
          }
        />

        <Gutter offset_xs={32} />

        <CommonUppsagningsgrad
          value={values.uppsagningsgrad}
          formikContext={formikContext}
          formikName="uppsagningsgrad"
          uppsagningsgrad={uppsagningsgrad}
        />

        <Gutter offset_xs={32} />
      </Grid>
    </Grid>
  )
}

export default KompletteringAvUppgifter
