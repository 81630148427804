import React, { useEffect } from 'react'
import { ISkapaAnsokanContent } from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import HTMLMapper from '@local/Components/HTMLMapper'
import { useFormikContext } from 'formik'
import { ISkapaAnsokanFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import Gutter from '@local/Components/Gutter'
import { AnsokanKeys, RadioChoice } from '@local/Common.types'
import Tooltip from '@local/Components/Tooltip/Tooltip'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { isNil } from 'ramda'
import FormErrorMessage from '@local/Components/FormErrorMessage/FormErrorMessage'
import SelectInput from '@local/Components/SelectInput/SelectInput'

import { getSjukersattningsgradSelectOption } from './Sjukersattningsgrad.helpers'

const Sjukersattningsgrad = (): JSX.Element => {
  const formikContext = useFormikContext<ISkapaAnsokanFormValues>()
  const {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = formikContext

  const {
    anstallning: { sjukersattningsgrad, tooltipSjukersattningsgrad },
  } = usePickEpiContent<ISkapaAnsokanContent>()

  const isError =
    touched?.hasSjukersattningsgrad && !isNil(errors?.hasSjukersattningsgrad)

  useEffect(() => {
    if (values.hasSjukersattningsgrad === RadioChoice.Nej) {
      setFieldValue(AnsokanKeys.Sjukersattningsgrad, null)?.catch((err) =>
        console.log(err)
      )
      setFieldTouched(AnsokanKeys.Sjukersattningsgrad, false)?.catch((err) =>
        console.log(err)
      )
    }
  }, [setFieldValue, setFieldTouched, values.hasSjukersattningsgrad])

  return (
    <FormControl error={isError} variant="standard" fullWidth>
      <Tooltip
        variant="subtitle1"
        heading={sjukersattningsgrad.heading}
        tooltipContent={
          <HTMLMapper body={tooltipSjukersattningsgrad.mainBody} />
        }
      />

      <Typography variant="body2">{sjukersattningsgrad.label}</Typography>

      <Gutter offset_xs={16} />

      <RadioGroup
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={values.hasSjukersattningsgrad}
      >
        <FormControlLabel
          value={RadioChoice.Nej}
          control={<Radio color="primary" />}
          label={sjukersattningsgrad.nej}
          name={AnsokanKeys.HasSjukersattningsgrad}
          id="hasSjukersattningsgrad.nej"
        />

        <FormControlLabel
          value={RadioChoice.Ja}
          control={<Radio color="primary" />}
          label={sjukersattningsgrad.ja}
          name={AnsokanKeys.HasSjukersattningsgrad}
          id="hasSjukersattningsgrad.ja"
        />
      </RadioGroup>

      {isError && (
        <FormErrorMessage>{errors.hasSjukersattningsgrad}</FormErrorMessage>
      )}

      {values.hasSjukersattningsgrad === RadioChoice.Ja && (
        <>
          <Gutter offset_xs={16} />

          <SelectInput
            selectOptions={[
              getSjukersattningsgradSelectOption(25),
              getSjukersattningsgradSelectOption(50),
              getSjukersattningsgradSelectOption(75),
              getSjukersattningsgradSelectOption(100),
            ]}
            label={sjukersattningsgrad.placeholder}
            name={AnsokanKeys.Sjukersattningsgrad}
            value={values.sjukersattningsgrad}
            formikContext={formikContext}
            isError={
              touched?.sjukersattningsgrad &&
              !isNil(errors?.sjukersattningsgrad)
            }
            errorMsg={errors?.sjukersattningsgrad}
          />
        </>
      )}
    </FormControl>
  )
}

export default Sjukersattningsgrad
