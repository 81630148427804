import React from 'react'
import Gutter from '@local/Components/Gutter'
import HTMLMapper from '@local/Components/HTMLMapper'
import { IUppdragDetaljvyContent } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import useGetUppdrag from '@local/Views/UppdragDetaljvy/useGetUppdrag'
import Spinner from '@local/Components/Spinner'
import Alert from '@local/Components/Alert'

const InskickadAnsokanUnderRedigering = (): JSX.Element => {
  const { redigeratInskickadAnsokanHighlightbox, nagotGickFel } =
    usePickEpiContent<IUppdragDetaljvyContent>()

  const {
    data: uppdrag,
    isLoading: isLoadingUppdrag,
    error: isErrorUppdrag,
  } = useGetUppdrag()

  if (isLoadingUppdrag) {
    return <Spinner centered />
  }

  if (isErrorUppdrag) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  if (!uppdrag.hasInskickadAnsokanUnderRedigering) {
    return <Gutter offset_xs={64} offset_md={80} />
  }

  return (
    <>
      <Gutter offset_xs={32} />

      <HTMLMapper body={redigeratInskickadAnsokanHighlightbox.mainBody} />

      <Gutter offset_xs={24} offset_md={40} />
    </>
  )
}

export default InskickadAnsokanUnderRedigering
