import React, { useCallback } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@mui/material'
import { IUppdragContent } from '@local/Views/Uppdrag/Uppdrag.types'
import { useIsFeatureFlagEnabled, usePickEpiContent } from '@local/Utils/hooks'
import { IRedigeraInskickadAnsokanModal } from '@local/Views/Uppdrag/AnsokanList/RedigeraInskickadAnsokanModal/types'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import { useHistory } from 'react-router-dom'

const RedigeraInskickadAnsokanModal = ({
  ansokanId,
  modalOpen,
  setModalOpen,
}: IRedigeraInskickadAnsokanModal): JSX.Element => {
  const shouldTrack = useIsFeatureFlagEnabled('gtm.enabled.kundansokan')
  const trackButtonClick = useTrackCustomClick()

  const history = useHistory()

  const { redigeraInskickadAnsokanModal, arbetsgivaransokanLinks } =
    usePickEpiContent<IUppdragContent>()

  const handleOnConfirm = useCallback(() => {
    if (shouldTrack) {
      trackButtonClick('Modal: Redigera inskickad ansökan', {
        label: 'Redigera',
      })
    }

    history.push(`${arbetsgivaransokanLinks.skapaAnsokanUrl}${ansokanId}/`)
  }, [
    history,
    trackButtonClick,
    shouldTrack,
    arbetsgivaransokanLinks.skapaAnsokanUrl,
    ansokanId,
  ])

  const handleOnCancel = useCallback(() => {
    if (shouldTrack) {
      trackButtonClick('Modal: Redigera inskickad ansökan', {
        label: 'Avbryt',
      })
    }

    setModalOpen(false)
  }, [setModalOpen, trackButtonClick, shouldTrack])

  return (
    <Dialog open={modalOpen}>
      <DialogTitle>{redigeraInskickadAnsokanModal.heading}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {redigeraInskickadAnsokanModal.description}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={handleOnCancel}>
          {redigeraInskickadAnsokanModal.cancelText}
        </Button>
        <Button variant="text" onClick={handleOnConfirm}>
          {redigeraInskickadAnsokanModal.confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RedigeraInskickadAnsokanModal
