import { isEmpty } from 'ramda'
import { FormikErrors, FormikTouched } from 'formik'
import { IUppdragFormValues } from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.types'
import { IFackligKontakt } from '@local/Views/UppdragFormWrapper/UppdragFormWrapper.types'
import {
  IFackforbundApiModel,
  IFackligKontaktperson,
  IFackligKontaktpersonWithFackforbund,
} from '@local/Views/DinaUppdrag/DinaUppdrag.types'

export const getFackligKontaktListItemErrors = (
  index: number,
  errors: FormikErrors<IUppdragFormValues>
): FormikErrors<IFackligKontakt> =>
  (errors.fackligaKontakter?.length &&
    (errors.fackligaKontakter[index] as FormikErrors<IFackligKontakt>)) ||
  {}

export const getFackligKontaktListItemTouched = (
  index: number,
  touched: FormikTouched<IUppdragFormValues>
): FormikTouched<IFackligKontakt> =>
  (touched.fackligaKontakter?.length && touched.fackligaKontakter[index]) || {}

export const touchedKeyExistsInErrors = (
  index: number,
  touched: FormikTouched<IUppdragFormValues>,
  errors: FormikErrors<IUppdragFormValues>
): boolean => {
  const touchedKeys = Object.keys(touched.fackligaKontakter[index])
  const errorKeys = Object.keys(errors.fackligaKontakter[index])
  return touchedKeys.some((x) => errorKeys.includes(x))
}

export const fackligKontaktListItemHasErrors = (
  index: number,
  touched: FormikTouched<IUppdragFormValues>,
  errors: FormikErrors<IUppdragFormValues>
): boolean =>
  !isEmpty(getFackligKontaktListItemErrors(index, errors)) &&
  !isEmpty(getFackligKontaktListItemTouched(index, touched)) &&
  touchedKeyExistsInErrors(index, touched, errors)

export const getTouchedFackligKontaktList = (
  index: number,
  touched: FormikTouched<IUppdragFormValues>,
  errors: FormikErrors<IUppdragFormValues>
) => {
  const fackligaKontakterTouched = []
  if (touched.fackligaKontakter) {
    fackligaKontakterTouched.push(...touched.fackligaKontakter)
  }

  fackligaKontakterTouched[index] = errors.fackligaKontakter[index]

  return fackligaKontakterTouched
}

export const getFackligaKontakterFlatList = (
  fackligaKontakter: IFackforbundApiModel[]
) =>
  fackligaKontakter.reduce(
    (acc, current) => [...acc, ...current.kontaktPersoner],
    [] as IFackligKontaktperson[]
  )

export const getFackligaKontakterWithFackforbundFlatList = (
  fackligaKontakter: IFackforbundApiModel[]
) =>
  fackligaKontakter.reduce(
    (acc, current) => [
      ...acc,
      ...current.kontaktPersoner.map((kontakt) => ({
        ...kontakt,
        fackforbund: {
          label: current.namn,
          value: current.id ? current.id : '',
        },
      })),
    ],
    [] as IFackligKontaktpersonWithFackforbund[]
  )

export const getFackligaKontakterNames = (flatList: IFackligKontaktperson[]) =>
  flatList.map(
    (kontaktperson) => `${kontaktperson.fornamn} ${kontaktperson.efternamn}`
  )
