import React, { useCallback, useEffect, useState, useRef } from 'react'
import {
  Checkbox,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Button,
} from '@mui/material'
import Spinner from '@local/Components/Spinner'
import { usePickEpiContent } from '@local/Utils/Hooks'
import Gutter from '@local/Components/Gutter'
import HTMLMapper from '@local/Components/HTMLMapper'
import { useIsFeatureEnabled } from '@trr/app-shell-data'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import Alert from '@local/Components/Alert'
import {
  IDetaljvyContent,
  ISkickaInAnsokanApiModel,
} from '@local/Views/Detaljvy/Detaljvy.types'
import { decode } from 'html-entities'
import { removeHtmlTags } from '@local/Utils/Helpers'
import { usePostSkickaInAnsokanMutation } from '@local/services/API/uppdrag/uppdragApi'
import Highlightbox from '@local/Components/Highlightbox'

import { IForsakraUppgifter } from './ForsakraUppgifter.types'

const ForsakraUppgifter = ({
  markeradeAnsokningar,
  shouldResetModalContent,
  closeModal,
}: IForsakraUppgifter): JSX.Element => {
  const { skickaInForsakranText, skickaInModal } =
    usePickEpiContent<IDetaljvyContent>()

  const shouldTrack = useIsFeatureEnabled('gtm.enabled.kundansokan')
  const trackButtonClick = useTrackCustomClick()

  const [errorMessage, setErrorMessage] = useState(skickaInModal.felmeddelande)
  const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState(false)
  const [checked, setChecked] = useState(false)

  const [postSkickaInAnsokanMutation, { isLoading: isLoadingSkickaInAnsokan }] =
    usePostSkickaInAnsokanMutation()

  const handleSend = async () => {
    const ansokningar: ISkickaInAnsokanApiModel[] = markeradeAnsokningar.map(
      (ansokan) => ({
        ansokanId: ansokan.id,
        villkorstext: decode(
          removeHtmlTags(
            `${skickaInForsakranText.mainBody} ${skickaInModal.kollektivavtalDescription}`
          )
        ),
      })
    )
    await postSkickaInAnsokanMutation(ansokningar).unwrap()
  }

  const errorMessageRef = useRef<HTMLDivElement>(null)

  const toggleChecked = () => {
    setChecked(!checked)
  }

  const scrollIntoView = () => {
    errorMessageRef.current?.scrollIntoView({
      behavior: 'smooth',
    })
  }
  useEffect(() => {
    if (shouldShowErrorMessage) {
      scrollIntoView()
    }
  }, [shouldShowErrorMessage])

  useEffect(() => {
    if (shouldResetModalContent) {
      setShouldShowErrorMessage(false)
      setChecked(false)
    }
  }, [shouldResetModalContent])

  useEffect(() => {
    if (checked) {
      setShouldShowErrorMessage(false)
    }
  }, [checked])

  const handleOnConfirm = () =>
    new Promise<void>((resolve) => {
      setShouldShowErrorMessage(false)

      if (shouldTrack) {
        trackButtonClick('Modal: Försäkra uppgifter', {
          label: 'Skicka in',
        })
      }
      handleSend()
        .then(() => {
          closeModal()
          resolve()
        })
        .catch(() => {
          setErrorMessage(skickaInModal.felmeddelandeRequest)
          setShouldShowErrorMessage(true)
          resolve()
        })
    })

  const handleOnClose = () => {
    if (shouldTrack) {
      trackButtonClick('Modal: Försäkra uppgifter', {
        label: 'Avbryt',
      })
    }
    closeModal()
  }

  const handleOnConfirmError = useCallback(() => {
    setErrorMessage(skickaInModal.felmeddelande)
    setShouldShowErrorMessage(true)
    scrollIntoView()
  }, [setShouldShowErrorMessage, setErrorMessage, skickaInModal.felmeddelande])

  return (
    <>
      <DialogTitle>{skickaInModal.heading}</DialogTitle>
      <DialogContent>
        <DialogContentText
          variant="body1"
          sx={(theme) => ({
            color: theme.palette.text.primary,
          })}
        >
          {skickaInModal.preamble}
        </DialogContentText>

        <Gutter offset_xs={24} />

        {isLoadingSkickaInAnsokan && <Spinner centered />}
        {!isLoadingSkickaInAnsokan && (
          <>
            <Highlightbox padding_md={24}>
              <Typography variant="h6">
                {skickaInModal.juridiskTextTitle}
              </Typography>

              <Gutter offset_xs={12} />

              <HTMLMapper body={skickaInForsakranText.mainBody} />
            </Highlightbox>
            <Gutter offset_xs={24} />
            <DialogContentText variant="caption">
              {skickaInModal.kollektivavtalDescription}
            </DialogContentText>
            <Gutter offset_xs={24} />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={checked}
                  aria-checked={checked}
                  inputProps={{ 'aria-checked': checked }}
                  onChange={toggleChecked}
                />
              }
              label={skickaInModal.checkboxLabel}
            />
          </>
        )}
        {shouldShowErrorMessage && (
          <>
            <Gutter offset_xs={24} />
            <Alert severity="error" ref={errorMessageRef}>
              {errorMessage}
            </Alert>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={handleOnClose}
          disabled={isLoadingSkickaInAnsokan}
        >
          {skickaInModal.cancelText}
        </Button>

        <Button
          variant="text"
          onClick={checked ? handleOnConfirm : handleOnConfirmError}
          disabled={isLoadingSkickaInAnsokan}
        >
          {skickaInModal.confirmText}
        </Button>
      </DialogActions>

      <Gutter offset_xs={16} />
    </>
  )
}

export default ForsakraUppgifter
