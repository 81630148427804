import React, { useState } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  MenuItemProps,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { ISelectInput } from '@local/Components/SelectInput/types'
import FormErrorMessage from '@local/Components/FormErrorMessage/FormErrorMessage'
import { ISelectOption } from '@local/Common.types'
import { styled } from '@mui/material/styles'

const StyledMenuItem = styled(({ children, ...props }: MenuItemProps) => (
  <MenuItem {...props}>{children}</MenuItem>
))(() => ({
  whiteSpace: 'normal',
}))

const SelectInput = ({
  selectOptions,
  label,
  name,
  formikContext,
  errorMsg,
  isError,
  value,
  disabled = false,
}: ISelectInput): JSX.Element => {
  const {
    values: formikValues,
    errors,
    setFieldValue,
    setFieldTouched,
  } = formikContext

  const defaultValues =
    (value as ISelectOption)?.value ??
    ((formikValues?.[name] as ISelectOption)?.value as string)

  const [values, setValues] = useState<string>(defaultValues as string)

  const handleChange = (e: SelectChangeEvent<string>) => {
    setValues(e.target.value)
    setFieldValue(
      name,
      selectOptions.find((option) => option.value === e.target.value)
    ).catch(err => console.log(err))
    setFieldTouched(name, true, false).catch(err => console.log(err))
  }

  return (
    <FormControl fullWidth error={isError} disabled={disabled}>
      <InputLabel id={`${name}-select-label`}>{label}</InputLabel>
      <Select
        labelId={`${name}-select-label`}
        id={`${name}-select`}
        value={values ?? ''}
        name={name}
        label={label}
        onChange={handleChange}
        SelectDisplayProps={{
          style: {
            whiteSpace: 'normal',
          },
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        }}
      >
        {selectOptions?.map((option) => (
          <StyledMenuItem key={option.value} value={option.value}>
            {option.label}
          </StyledMenuItem>
        ))}
      </Select>

      {isError && (
        <FormErrorMessage>
          {errorMsg ?? String(errors?.[name])}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export default SelectInput
