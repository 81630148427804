import { useMemo } from 'react'
import { ISteps } from '@local/Components/Stepper/Stepper.types'
import {
  ISkapaAnsokningarContent,
  SkapaAnsokningarStep,
  SkapaAnsokningarStepFormikId,
} from '@local/Views/SkapaAnsokningar/types/types'
import { usePickEpiContent } from '@local/Utils/Hooks'

const useGetSteps = () => {
  const { steps: stepsContent } = usePickEpiContent<ISkapaAnsokningarContent>()

  const steps: ISteps[] = useMemo(
    () => [
      {
        step: SkapaAnsokningarStep.LaggTillMedarbetare,
        title: stepsContent.laggTillMedarbetare,
        formikId: SkapaAnsokningarStepFormikId.LaggTillMedarbetare,
      },
      {
        step: SkapaAnsokningarStep.Sammanfattning,
        title: stepsContent.sammanfattning,
        formikId: SkapaAnsokningarStepFormikId.Sammanfattning,
      },
      {
        step: SkapaAnsokningarStep.GranskaSkickaIn,
        title: stepsContent.granskaSkickaIn,
        formikId: SkapaAnsokningarStepFormikId.GranskaSkickaIn,
      },
    ],
    [stepsContent]
  )

  return { steps }
}

export default useGetSteps
