import React from 'react'
import { Typography, List, ListItem, ListItemText } from '@mui/material'
import useGetUppdrag from '@local/Views/UppdragDetaljvy/useGetUppdrag'
import { IUppdragDetaljvyContent } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import Alert from '@local/Components/Alert'

const Administratorer = (): JSX.Element => {
  const { data: uppdrag, isError } = useGetUppdrag()
  const { administratorer, nagotGickFel } =
    usePickEpiContent<IUppdragDetaljvyContent>()

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  if (!uppdrag?.administratorer?.length) {
    return null
  }

  return (
    <article>
      <Typography variant="h5">{administratorer.heading}</Typography>

      <List>
        {uppdrag.administratorer.map((admin) => (
          <ListItem key={admin.id} sx={{ border: 1, my: 2, padding: 1 }}>
            <ListItemText>{admin.namn}</ListItemText>
          </ListItem>
        ))}
      </List>
    </article>
  )
}

export default Administratorer
