import { IKeyValue } from '@local/Common.types'
import { IUniqueFile } from '@local/Components/FileUpload/FileUpload.types'
import {
  IAnsokan,
  ISkapaAnsokanContent,
} from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'
import {
  IAnstallningApiModel,
  IAnstallningFormValues,
} from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Anstallning/Anstallning.types'
import {
  ILonApiModel,
  ILonFormValues,
} from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Lon/Lon.types'
import {
  IMedarbetareApiModel,
  IMedarbetareFormValues,
} from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Medarbetare/Medarbetare.types'

export interface ISkapaAnsokanFormProps {
  ansokan: IAnsokan
  bilagor: IUniqueFile[]
  content: ISkapaAnsokanContent
}

export interface ISkapaAnsokanFormValues
  extends IKeyValue,
    IMedarbetareFormValues,
    IAnstallningFormValues,
    ILonFormValues {}

export interface ISkapaAnsokanApiModel
  extends IMedarbetareApiModel,
    IAnstallningApiModel,
    ILonApiModel {}

export enum SkapaAnsokanStep {
  Medarbetare = 0,
  Anstallning = 1,
  Lon = 2,
}

export enum SkapaAnsokanStepFormikId {
  Medarbetare = 'medarbetare',
  Anstallning = 'anstallning',
  Lon = 'lon',
}

export interface IRequestedUpdates {
  updatesToPatch: Partial<ISkapaAnsokanApiModel>
  updatesToStore: Partial<ISkapaAnsokanFormValues>
}
