import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'ramda'
import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IUppdragFormWrapperContent } from '@local/Views/UppdragFormWrapper/UppdragFormWrapper.types'
import { IUppdragFormValues } from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.types'
import {
  useGetSparaUppdragMutation,
  useIsRedigeraUppdrag,
} from '@local/Utils/Hooks/UppdragForm'
import useGetUppdrag from '@local/Views/UppdragDetaljvy/useGetUppdrag'
import { useAppDispatch } from '@local/Store/configureStore'
import { setShouldHighlightCard } from '@local/Components/RemovableCard/removableCardSlice'
import StickyFooter from '@local/Components/StickyMuiFooter/StickyFooter'
import { Button } from '@mui/material'

const UppdragFormSubmit = (): JSX.Element => {
  const { values, errors, validateForm, setTouched, resetForm } =
    useFormikContext<IUppdragFormValues>()

  const {
    sidfot,
    arbetsgivaransokanLinks: { dinaUppdragUrl, uppdragUrl },
  } = usePickEpiContent<IUppdragFormWrapperContent>()

  const dispatch = useAppDispatch()
  const history = useHistory()
  const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState(false)
  const sparaUppdrag = useGetSparaUppdragMutation()
  const isRedigera = useIsRedigeraUppdrag()
  const { data: uppdrag } = useGetUppdrag()
  const redirectUrl = isRedigera
    ? `${uppdragUrl}${uppdrag.id}/`
    : dinaUppdragUrl

  useEffect(() => {
    if (isEmpty(errors)) {
      setShouldShowErrorMessage(false)
    }
  }, [errors])

  const handleSparaUppdrag = useCallback(() => {
    void (async () => {
      const formErrors = await validateForm()
      dispatch(setShouldHighlightCard(false))

      if (isEmpty(formErrors)) {
        sparaUppdrag()
          .unwrap()
          .then(() => {
            resetForm()
            setTimeout(() => {
              history.push(redirectUrl)
            }, 50)
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        setTouched(
          setNestedObjectValues<FormikTouched<IUppdragFormValues>>(
            {
              ...formErrors,
              fackligaKontakter:
                values.fackligaKontakter?.length > 0
                  ? formErrors.fackligaKontakter
                  : [],
            },
            true
          )
        )?.catch(err => console.log(err))
        setShouldShowErrorMessage(true)
      }
    })()
  }, [
    validateForm,
    setTouched,
    sparaUppdrag,
    dispatch,
    resetForm,
    history,
    redirectUrl,
    values.fackligaKontakter?.length,
  ])

  return (
    <StickyFooter
      leftElements={
        <Button variant="outlined" href={redirectUrl}>
          {sidfot.avbryt}
        </Button>
      }
      rightElements={
        <Button onClick={handleSparaUppdrag}>{sidfot.spara}</Button>
      }
      isError={shouldShowErrorMessage}
      errorMsg={sidfot.felmeddelande}
    />
  )
}

export default UppdragFormSubmit
