import React from 'react'
import { isNil } from 'ramda'
import { Grid, Typography } from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IAnstallningProps } from '@local/Views/UppdragDetaljvy/AnsokanCard/Anstallning/Anstallning.types'
import { IUppdragDetaljvyContent } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import { formatCurrencySEK } from '@local/Utils/Helpers'
import { trrFormat } from '@local/Utils/Helpers/formatDate'
import { AnsokanType } from '@local/Common.types'

const Lon = ({ ansokan }: IAnstallningProps): JSX.Element => {
  const { anstallningsinformation } =
    usePickEpiContent<IUppdragDetaljvyContent>()

  const isTioTbAnsokan =
    ansokan?.type === AnsokanType.omstallningTidsbegransadAnstallning

  return (
    <Grid item md={4}>
      <Grid item py={2}>
        <Typography variant="subtitle1">
          {anstallningsinformation.manadslon}
        </Typography>
        <Typography variant="subtitle2">
          {!isNil(ansokan.loneuppgifter.manadslon) &&
            formatCurrencySEK(ansokan.loneuppgifter.manadslon)}
        </Typography>
      </Grid>

      {!isTioTbAnsokan && (
        <Grid item py={2}>
          <Typography variant="subtitle1">
            {anstallningsinformation.lonerevisionsdatum}
          </Typography>
          <Typography variant="subtitle2">
            {!isNil(ansokan.loneuppgifter.lonerevisionsdatum) &&
              trrFormat(ansokan.loneuppgifter.lonerevisionsdatum)}
          </Typography>
        </Grid>
      )}
      <Grid item py={2}>
        <Typography variant="subtitle1">
          {anstallningsinformation.provisionBelopp}
        </Typography>
        <Typography variant="subtitle2">
          {!isNil(ansokan.loneuppgifter.provisionBelopp) &&
            formatCurrencySEK(ansokan.loneuppgifter.provisionBelopp)}
        </Typography>
      </Grid>
      <Grid item py={2}>
        <Typography variant="subtitle1">
          {anstallningsinformation.jourBelopp}
        </Typography>
        <Typography variant="subtitle2">
          {!isNil(ansokan.loneuppgifter.jourBelopp) &&
            formatCurrencySEK(ansokan.loneuppgifter.jourBelopp)}
        </Typography>
      </Grid>
      {!isTioTbAnsokan && (
        <>
          {!isNil(ansokan.loneuppgifter.kostLogiBelopp) && (
            <Grid item py={2}>
              <Typography variant="subtitle1">
                {anstallningsinformation.kostLogiBelopp}
              </Typography>
              <Typography variant="subtitle2">
                {formatCurrencySEK(ansokan.loneuppgifter.kostLogiBelopp)}
              </Typography>
            </Grid>
          )}
          <Grid item py={2}>
            <Typography variant="subtitle1">
              {anstallningsinformation.avgangsvederlag}
            </Typography>
            <Typography variant="subtitle2">
              {!isNil(ansokan.loneuppgifter.avgangsvederlag) &&
                formatCurrencySEK(ansokan.loneuppgifter.avgangsvederlag)}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default Lon
