import { useGetUppdragQuery } from '@local/services/API/uppdrag/uppdragApi'
import { matchStringByRegex, UUIDRegex } from '@local/Utils/helpers'
import { useAuthentication } from '@trr/app-shell-data'
import { isEmpty, isNil } from 'ramda'

const useGetUppdrag = () => {
  const { sub } = useAuthentication()
  const currentUppdragsId = matchStringByRegex(location.pathname, UUIDRegex)
  return useGetUppdragQuery(
    { uppdragId: currentUppdragsId, userSub: sub },
    {
      skip: isNil(sub) || isEmpty(sub) || !currentUppdragsId,
    }
  )
}

export default useGetUppdrag
