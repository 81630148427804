import React from 'react'
import { Button, Grid, Typography } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Gutter from '@local/Components/Gutter'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IDetaljvyContent } from '@local/Views/Detaljvy/Detaljvy.types'
import {
  useCanAccessSkapaAnsokningar,
  useGetUppdrag,
} from '@local/Views/Detaljvy/hooks'

const IngaRaderOverlay = (): JSX.Element => {
  const {
    ingaRaderOverlay,
    skapaAnsokningarLank,
    arbetsgivaransokanLinks: { laggTillMedarbetareUrl },
  } = usePickEpiContent<IDetaljvyContent>()
  const canAccessSkapaAnsokningar = useCanAccessSkapaAnsokningar()
  const { data: uppdrag } = useGetUppdrag()

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100%' }}
    >
      <Typography variant="h6">{ingaRaderOverlay.heading}</Typography>
      <Gutter offset_xs={16} />
      <Typography variant="body2">{ingaRaderOverlay.body}</Typography>
      <Gutter offset_xs={24} />
      <Button
        variant="text"
        startIcon={<AddCircleIcon />}
        href={
          canAccessSkapaAnsokningar
            ? `${skapaAnsokningarLank.url}${uppdrag?.id}/`
            : `${laggTillMedarbetareUrl}${uppdrag?.id}/`
        }
      >
        {ingaRaderOverlay.buttonLabel}
      </Button>
    </Grid>
  )
}

export default IngaRaderOverlay
