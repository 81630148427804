import React from 'react'
import { Grid, Typography } from '@mui/material'
import Divider from '@local/Components/Divider'
import Gutter from '@local/Components/Gutter'
import Medarbetarinformation from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Medarbetare/Medarbetarinformation'
import Tjanstemannaavtal from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Medarbetare/Tjanstemannaavtal'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ISkapaAnsokanContent } from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'
import useGetAnsokan from '@local/Views/SkapaAnsokan/useGetAnsokan'
import OverenskommelseBilaga from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Medarbetare/OverenskommelseBilaga'
import Undantagsgrupp from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Medarbetare/Undantagsgrupp/Undantagsgrupp'
import Alert from '@local/Components/Alert'

const Medarbetare = (): JSX.Element => {
  const { tabbar, nagotGickFel } = usePickEpiContent<ISkapaAnsokanContent>()

  const { data: ansokan, isError } = useGetAnsokan()

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  return (
    <Grid container>
      <Grid item xs md={8}>
        <Typography variant="h3">{tabbar.medarbetare}</Typography>

        <Gutter offset_xs={32} offset_md={48} />

        <Medarbetarinformation />

        {ansokan.kraverOverenskommelseBilaga && (
          <>
            <Gutter offset_xs={32} />
            <OverenskommelseBilaga />
          </>
        )}

        <Divider offset_xs={64} offset_md={96} />

        <Tjanstemannaavtal />

        <Gutter offset_xs={32} />

        <Undantagsgrupp />

        <Gutter offset_xs={48} />
      </Grid>
    </Grid>
  )
}

export default Medarbetare
