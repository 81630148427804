import React from 'react'
import HTMLMapper from '@local/Components/HTMLMapper'
import { usePickEpiContent } from '@local/Utils/hooks'
import { IUppdragFormWrapperContent } from '@local/Views/UppdragFormWrapper/UppdragFormWrapper.types'
import { useIsBegransadRedigering } from '@local/Views/UppdragFormWrapper/UppdragForm/hooks'
import { IUppdragFormValues } from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.types'
import { useFormikContext } from 'formik'
import Gutter from '@local/Components/Gutter'
import Tooltip from '@local/Components/Tooltip/Tooltip'
import { Grid2 as Grid, Typography } from '@mui/material'

const FackligKontaktIntro = (): JSX.Element => {
  const isBegransadRedigering = useIsBegransadRedigering()
  const { values } = useFormikContext<IUppdragFormValues>()
  const { fackligKontakt, tooltipFackligKontakt } =
    usePickEpiContent<IUppdragFormWrapperContent>()

  const shouldRenderPreamble = isBegransadRedigering
    ? !values.fackligRepSaknas
    : true

  return (
    <Grid container>
      <Grid size={{ md: 8 }}>
        <Tooltip
          variant="subtitle1"
          heading={fackligKontakt.heading}
          tooltipContent={<HTMLMapper body={tooltipFackligKontakt.mainBody} />}
        />

        {shouldRenderPreamble && (
          <>
            <Typography variant="subtitle2">
              {fackligKontakt.preamble}
            </Typography>
            <Gutter offset_xs={32} />
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default FackligKontaktIntro
