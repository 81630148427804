import React from 'react'
import {
  useIsMediumScreensOrAbove,
  usePickEpiContent,
} from '@local/Utils/Hooks'
import { IUppdragDetaljvyContent } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import Gutter from '@local/Components/Gutter'
import { Typography, Checkbox, Grid } from '@mui/material'

import type { IHeaderProps } from './Header.types'

const Header = ({ checkedState, handleCheck }: IHeaderProps): JSX.Element => {
  const isMediumScreensOrAbove = useIsMediumScreensOrAbove()
  const { header } = usePickEpiContent<IUppdragDetaljvyContent>()

  return (
    <Grid
      container
      pl={2}
      pr={5}
      sx={{ borderTop: 1, borderBottom: 1 }}
      alignItems="center"
      justifyContent="space-between"
      style={{
        position: 'sticky',
        top: -1,
        backgroundColor: 'white',
        zIndex: 10,
      }}
    >
      <Gutter offset_xs={8} />
      <Grid item xs={'auto'} md={2}>
        <Grid container wrap="nowrap" alignItems="center" onClick={handleCheck}>
          <Grid item>
            <Checkbox
              inputProps={{
                name: 'Markera alla ansökningar',
                'aria-label': 'Markera alla ansökningar',
              }}
              color="primary"
              checked={
                checkedState.every((checked) => checked) &&
                checkedState?.length > 0
              }
              disabled={checkedState?.length <= 0}
              onChange={handleCheck}
            />
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography noWrap>{header.name}</Typography>
          </Grid>
        </Grid>
      </Grid>

      {isMediumScreensOrAbove && (
        <>
          <Grid item xs={2}>
            <Typography noWrap variant="body1">
              {header.personnummer}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography noWrap variant="body1">
              {header.beslutRadgivning}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography noWrap variant="body1">
              {header.beslutAge}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography noWrap variant="body1">
              {header.inskickadAv}
            </Typography>
          </Grid>
        </>
      )}

      <Gutter offset_xs={8} />
    </Grid>
  )
}

export default Header
