import React from 'react'
import { usePickEpiContent } from '@local/Utils/hooks'
import { ISkapaAnsokanContent } from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'
import Divider from '@local/Components/Divider'
import Gutter from '@local/Components/Gutter'
import { Grid2 as Grid, Typography } from '@mui/material'

import Datum from './Datum'
import Omfattning from './Omfattning'

const Anstallning = (): JSX.Element => {
  const { tabbar } = usePickEpiContent<ISkapaAnsokanContent>()

  return (
    <Grid container>
      <Grid size={{ md: 8 }}>
        <Typography variant="h3">{tabbar.anstallning}</Typography>

        <Gutter offset_xs={32} offset_md={48} />

        <Datum />

        <Divider offset_md={96} />

        <Omfattning />

        <Gutter offset_xs={48} />
      </Grid>
    </Grid>
  )
}

export default Anstallning
