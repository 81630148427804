import { SignalRRoom } from '@local/App.types'
import { matchStringByRegex } from '@local/Utils/Helpers'
import { useSignalRConnectToGroup } from '@local/Utils/Hooks'
import { UUIDRegex } from '@local/Utils/regexes'
import { IGetMedarbetareNamnResponseModel } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/types/types'
import { ISkapaAnsokningarFormValues } from '@local/Views/SkapaAnsokningar/types/types'
import { useGetFilAnsokanQuery } from '@local/services/API/filAnsokanApi'
import { useFormikContext } from 'formik'
import { useCallback, useEffect, useRef, useState } from 'react'

const useGetMedarbetareNamn = () => {
  const timer = useRef<ReturnType<typeof setTimeout>>()

  const [requestedUpdates, setRequestedUpdates] = useState<
    IGetMedarbetareNamnResponseModel[]
  >([])

  const { values, setValues } = useFormikContext<ISkapaAnsokningarFormValues>()

  const uppdragsId = matchStringByRegex(location.pathname, UUIDRegex)

  const { data } = useGetFilAnsokanQuery(uppdragsId)

  useSignalRConnectToGroup({
    room: SignalRRoom.FilansokanUpdated,
    group: data?.fileId,
    onEventReceived: useCallback(
      (response: IGetMedarbetareNamnResponseModel) => {
        setRequestedUpdates((prevUpdates) => [...prevUpdates, response])
      },
      []
    ),
  })

  const updateFilansokanValues = useCallback(() => {
    const requestedUpdatesRef = requestedUpdates
    setRequestedUpdates([])

    const valuesWithRequestedUpdates: ISkapaAnsokningarFormValues = {
      ...values,
      filansokningar: values.filansokningar?.map((filansokan) => {
        const requestedUpdate = requestedUpdatesRef.find(
          (update) => update.Id === filansokan.id
        )

        if (requestedUpdate) {
          return {
            ...filansokan,
            namn: requestedUpdate.Namn,
          }
        } else {
          return filansokan
        }
      }),
    }

    setValues(valuesWithRequestedUpdates).catch((err) => console.log(err))
  }, [requestedUpdates, setValues, values])

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(() => {
      if (requestedUpdates?.length > 0) {
        updateFilansokanValues()
      }
    }, 1000)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedUpdates])
}

export default useGetMedarbetareNamn
