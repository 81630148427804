import { booleanToRadioChoice } from '@local/Utils/Helpers/Forms/Forms.helpers'
import { IAnstallningFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Anstallning/Anstallning.types'
import { getSjukersattningsgradSelectOption } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Anstallning/Omfattning/Sjukersattningsgrad/Sjukersattningsgrad.helpers'
import { mapAnsokanToRiskAnstallningFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Anstallning/Anstallningrisk.schema'
import { toTrrDateOrDefault } from '@local/Utils/Helpers/formatDate'
import { AnsokanKeys } from '@local/Common.types'
import { IAnsokan } from '@local/services/API/ansokan/types'

export const mapAnsokanToAnstallningFormValues = (
  ansokan: IAnsokan
): IAnstallningFormValues => ({
  [AnsokanKeys.Anstallningsdatum]: toTrrDateOrDefault(
    ansokan.anstallningsuppgifter?.anstallningsdatum
  ),
  [AnsokanKeys.Anstallningsgrad]:
    ansokan.anstallningsuppgifter.anstallningsgrad,
  [AnsokanKeys.ArbetstimmarPerVecka]:
    ansokan.anstallningsuppgifter.arbetstimmarPerVecka,
  [AnsokanKeys.HasSjukersattningsgrad]: booleanToRadioChoice(
    ansokan.hasSjukersattningsgrad
  ),
  [AnsokanKeys.Sjukersattningsgrad]: getSjukersattningsgradSelectOption(
    ansokan.loneuppgifter.sjukersattningsgrad
  ),

  ...mapAnsokanToRiskAnstallningFormValues(ansokan),
})
