import { today } from '@local/Utils/Helpers'
import { isAfter, isBefore, isValid } from 'date-fns'

export const getDefaultCalendarDate = (minDate: Date, maxDate: Date) => {
  if (isValid(maxDate) && isValid(minDate)) {
    if (isAfter(minDate, today)) {
      return minDate
    }
    return isAfter(maxDate, today) ? today : maxDate
  } else if (isValid(maxDate)) {
    return isAfter(maxDate, today) ? today : maxDate
  } else if (isValid(minDate)) {
    return isBefore(minDate, today) ? today : minDate
  }

  return today
}
