import { AnsokanKeys } from '@local/Common.types'
import { usePickEpiContent } from '@local/Utils/hooks'
import {
  ISkapaAnsokningarContent,
  ISkapaAnsokningarFormValues,
} from '@local/Views/SkapaAnsokningar/types/types'
import { useDirtyCheckPrompt } from '@trr/react-use-dirty-check-prompt'
import { useFormikContext } from 'formik'
import { equals, isNil, omit } from 'ramda'
import React, { useEffect, useState } from 'react'

const VarningEjSparadData = (): JSX.Element => {
  const { varningLamnaSidan } = usePickEpiContent<ISkapaAnsokningarContent>()

  const { initialValues, values } =
    useFormikContext<ISkapaAnsokningarFormValues>()

  const [formIsDirty, setFormIsDirty] = useState(false)

  const { routerPrompt } = useDirtyCheckPrompt(
    formIsDirty,
    varningLamnaSidan.description
  )

  const initialValuesWithEditableFields = initialValues?.filansokningar?.map(
    (filansokan) => ({
      ...omit([AnsokanKeys.Namn, AnsokanKeys.Personnummer], filansokan),
    })
  )

  const valuesWithEditableFields = values?.filansokningar?.map(
    (filansokan) => ({
      ...omit([AnsokanKeys.Namn, AnsokanKeys.Personnummer], filansokan),
    })
  )

  useEffect(() => {
    if (
      !isNil(initialValuesWithEditableFields) &&
      !isNil(valuesWithEditableFields)
    ) {
      if (equals(initialValuesWithEditableFields, valuesWithEditableFields)) {
        setFormIsDirty(false)
      } else {
        setFormIsDirty(true)
      }
    }
  }, [initialValuesWithEditableFields, valuesWithEditableFields])

  return <>{routerPrompt}</>
}

export default VarningEjSparadData
