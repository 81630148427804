import React from 'react'
import { Typography, Grid } from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IUppdragDetaljvyContent } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import Divider from '@local/Components/Divider'
import { trrFormat } from '@local/Utils/Helpers/formatDate'
import Gutter from '@local/Components/Gutter'

import { ISambandsanstallningarProps } from './Sambandsanstallningar.types'

const Sambandsanstallningar = ({
  ansokan,
}: ISambandsanstallningarProps): JSX.Element => {
  const { sambandsanstallningar } =
    usePickEpiContent<IUppdragDetaljvyContent>()

  if (!ansokan.sambandsanstallningar?.length) {
    return null
  }

  return (
    <>
      <Divider offset_xs={48} />
      <Typography variant="h6" textTransform={'uppercase'}>
        {sambandsanstallningar.heading}
      </Typography>
      <Gutter offset_xs={24} />

      {ansokan.sambandsanstallningar.map((samband, index) => (
        <Grid container key={index}>
          <Grid item my={2} md={4} xs={12} alignItems={'center'}>
            <Typography variant="h6">{samband.foretagsnamn}</Typography>
          </Grid>
          <Grid item my={2} md={4} xs={12}>
            <Typography variant="subtitle1">
              {sambandsanstallningar.anstallningsdatum}
            </Typography>
            <Typography variant="subtitle2">
              {trrFormat(samband.anstallningsdatum)}
            </Typography>
          </Grid>
          <Grid item my={2} md={4} xs={12}>
            <Typography variant="subtitle1">
              {sambandsanstallningar.sistaAnstallningsdatum}
            </Typography>
            <Typography variant="subtitle2">
              {trrFormat(samband.sistaAnstallningsdatum)}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </>
  )
}
export default Sambandsanstallningar
