import React, { useCallback } from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IKompletteraRiskansokanContent } from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokan.types'
import Gutter from '@local/Components/Gutter'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import { useSkickaKompletteringMutation } from '@local/services/API/kompletteraRisk/kompletteraRiskApi'
import { getMappedKompletteraRiskansokanFormValues } from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokanForm/KompletteraRiskansokanFormValuesMapper'
import { IKompletteraRiskansokanFormValues } from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokanForm/KompletteraRiskansokanForm.types'
import { useFormikContext } from 'formik'
import { useHistory } from 'react-router-dom'
import useGetAnsokan from '@local/Views/SkapaAnsokan/useGetAnsokan'
import Alert from '@local/Components/Alert'
import { ISkickaKompletteringPayloadModel } from '@local/services/API/kompletteraRisk/types'

import { ISkickaInModal } from './SkickaInModal.types'

const SkickaInModal = ({
  modalOpen,
  closeModal,
}: ISkickaInModal): JSX.Element => {
  const history = useHistory()

  const { values, resetForm } =
    useFormikContext<IKompletteraRiskansokanFormValues>()

  const {
    slutforaModal,
    nagotGickFel,
    arbetsgivaransokanLinks: { uppdragUrl },
  } = usePickEpiContent<IKompletteraRiskansokanContent>()

  const [skickaKomplettering, { isLoading, isError: isErrorSkickaIn }] =
    useSkickaKompletteringMutation()

  const { data: ansokan, isError } = useGetAnsokan()

  const handleSkickaInKomplettering = useCallback(() => {
    void (async () => {
      const payload: ISkickaKompletteringPayloadModel = {
        ansokanId: ansokan.id,
        nuvarandeUppdragId: ansokan.uppdragId,
        body: getMappedKompletteraRiskansokanFormValues(values),
      }
      await skickaKomplettering(payload)
        .unwrap()
        .then(() => {
          resetForm()
          setTimeout(() => {
            history.push(`${uppdragUrl}${ansokan?.uppdragId}/`)
          }, 50)
        })
        .catch((err) => console.log(err))
    })()
  }, [
    ansokan.id,
    ansokan.uppdragId,
    history,
    resetForm,
    skickaKomplettering,
    uppdragUrl,
    values,
  ])

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  return (
    <Dialog onClose={closeModal} open={modalOpen}>
      <DialogTitle>{slutforaModal.heading}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">{slutforaModal.preamble}</Typography>
        </DialogContentText>

        <Gutter offset_xs={24} />

        {isErrorSkickaIn && (
          <Alert severity="error">{nagotGickFel.heading}</Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button disabled={isLoading} variant="text" onClick={closeModal}>
          {slutforaModal.cancelText}
        </Button>
        <Button
          disabled={isLoading}
          variant="text"
          onClick={handleSkickaInKomplettering}
        >
          {slutforaModal.confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SkickaInModal
