import { selectStepper } from '@local/Views/SkapaAnsokan/SkapaAnsokanNavigation/skapaAnsokanNavigationSlice'
import { useTrackVirtualView } from '@trr/gtm-tracking'
import { isNil } from 'ramda'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

const SkapaAnsokanTracker = () => {
  const stepTrigger = useTrackVirtualView('Medarbetaransökan')
  const { activeStep } = useSelector(selectStepper)

  useEffect(() => {
    if (!isNil(activeStep)) {
      stepTrigger(`Step: ${activeStep}`)
    }
  }, [stepTrigger, activeStep])

  return <></>
}

export default SkapaAnsokanTracker
