import React from 'react'
import { List, ListItem, ListItemText, Typography } from '@mui/material'
import Gutter from '@local/Components/Gutter'
import useGetUppdrag from '@local/Views/UppdragDetaljvy/useGetUppdrag'
import { IUppdragDetaljvyContent } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import Alert from '@local/Components/Alert'

const FackligaKontaktpersoner = (): JSX.Element => {
  const { data: uppdrag, isError } = useGetUppdrag()
  const { fackligaKontaktpersoner, nagotGickFel } =
    usePickEpiContent<IUppdragDetaljvyContent>()

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  if (!uppdrag?.fackligaKontakter?.length) {
    return null
  }

  return (
    <article>
      <Typography variant="h5">{fackligaKontaktpersoner.heading}</Typography>

      <Gutter offset_xs={8} />

      <List>
        {uppdrag.fackligaKontakter.map((fackforbund) => (
          <ListItem
            key={fackforbund.namn}
            sx={{ display: 'block' }}
            disablePadding
            divider={false}
          >
            <Typography variant="subtitle1" gutterBottom>
              {fackforbund.namn}
            </Typography>
            <List>
              {fackforbund.kontaktPersoner.map((kontakt, index) => (
                <ListItem key={index} sx={{ border: 1, mb: 2, padding: 1 }}>
                  <ListItemText>
                    {kontakt.fornamn} {kontakt.efternamn}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </ListItem>
        ))}
      </List>
    </article>
  )
}

export default FackligaKontaktpersoner
