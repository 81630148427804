import {
  FackligKontaktOrsakerTillUppsagning,
  FackligKontaktSaknasOrsakerTillUppsagning,
  IPatchBodyApiModel,
  PatchOps,
} from '@local/Common.types'
import { getFackligaKontakterWithFackforbundFlatList } from '@local/Utils/Helpers/FackligKontakt.helpers'
import {
  getDropdownSelectOptionLabel,
  getDropdownSelectOptionValue,
} from '@local/Utils/Helpers/Forms/Forms.helpers'
import {
  getFormattedAdmins,
  getFormattedForetag,
} from '@local/Views/UppdragFormWrapper/UppdragForm/Helpers'
import { IUppdragFormValues } from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.types'
import { IFackligKontakt } from '@local/Views/UppdragFormWrapper/UppdragFormWrapper.types'
import {
  ICreateUppdragPayloadModel,
  IFackforbundApiModel,
  IFackligKontaktApiModel,
  IUppdragApiModel,
  IUppdragFormApiModel,
} from '@local/services/API/uppdrag/types'
import { omit } from 'ramda'

/**
 * Prepare form values before sending to API
 */
export const mapUppdragFormValuesToApi = (
  values: IUppdragFormValues
): IUppdragFormApiModel => ({
  ...omit(['arbetsplats', 'admins'], values),

  arbetsplatsId: getDropdownSelectOptionValue(values.arbetsplats) as string,

  fackligaKontakter: fackligaKontakerIsAllowedForOrsakskod(values)
    ? values.fackligaKontakter.map((kontakt) =>
        mapFackligKontaktFormValuesToApi(kontakt)
      )
    : [],

  fackligRepSaknas: Object.values<string>(
    FackligKontaktSaknasOrsakerTillUppsagning
  ).includes(values.orsakTillUppsagning)
    ? values.fackligRepSaknas
    : false,

  adminIds: values.admins
    ? values.admins
        ?.map((admin) => getDropdownSelectOptionValue(admin) as string)
        .sort()
        .reverse()
    : [],
})
export const mapCreateUppdragFormValuesToApi = (
  uppdrag: IUppdragFormValues,
  organisationsnummer: string
): ICreateUppdragPayloadModel => {
  const mappedUppdrag = mapUppdragFormValuesToApi(uppdrag)

  return { organisationsnummer, ...mappedUppdrag }
}

/**
 * Prepare API values before populating form
 */
export const mapUppdragApiValuesToForm = (
  uppdrag: IUppdragApiModel
): IUppdragFormValues => ({
  uppdragsnamn: uppdrag.namn,
  arbetsplats: {
    label: getFormattedForetag(uppdrag.arbetsstalle),
    value: uppdrag.arbetsstalle.id,
  },
  orsakTillUppsagning: uppdrag.orsakTillUppsagning,
  fackligaKontakter: mapFackligaKontakterApiValuesToForm(
    uppdrag.fackligaKontakter
  ).sort((a, b) => a.index - b.index),
  fackligRepSaknas: uppdrag.fackligRepSaknas,
  admins: getFormattedAdmins(uppdrag.administratorer),
})

/**
 * Prepare API values for fackliga kontakter before populating form
 */
export const mapFackligaKontakterApiValuesToForm = (
  fackligaKontakter: IFackforbundApiModel[]
): IFackligKontakt[] => {
  const flatList =
    getFackligaKontakterWithFackforbundFlatList(fackligaKontakter)

  return flatList.map((kontakt) => ({
    key: crypto.randomUUID(),
    id: kontakt.id,
    index: kontakt.index,
    fornamn: kontakt.fornamn,
    efternamn: kontakt.efternamn,
    epostadress: kontakt.epostadress,
    telefon: kontakt.telefon,
    fackforbund: kontakt.fackforbund,
  }))
}

/**
 * Prepare form values for facklig kontakt before sending to API
 */
export const mapFackligKontaktFormValuesToApi = (
  kontakt: IFackligKontakt
): IFackligKontaktApiModel => ({
  ...omit(['key', 'fackforbund'], kontakt),

  fackforbund: getDropdownSelectOptionLabel(kontakt.fackforbund),
  fackforbundId: getDropdownSelectOptionValue(kontakt.fackforbund) as string,
})

/**
 * Returns bool to indicate whether fackliga kontakter is allowed for current orsakskod
 */
export const fackligaKontakerIsAllowedForOrsakskod = (
  values: IUppdragFormApiModel | IUppdragFormValues
) =>
  Object.values<string>(FackligKontaktOrsakerTillUppsagning).includes(
    values.orsakTillUppsagning
  )

/**
 * Map form values and return PATCH operations for each key in form
 */
export const mapUppdragValuesToPatchModel = (
  values: IUppdragFormApiModel,
  uppdrag: IUppdragApiModel
): IPatchBodyApiModel[] =>
  Object.keys(values)
    .map((key) => [
      {
        op: PatchOps.Test,
        path: `/${key.toLocaleLowerCase()}`,
        value: mapUppdragFormValuesToApi(mapUppdragApiValuesToForm(uppdrag))[
          key as keyof IUppdragFormApiModel
        ],
      },
      {
        op: PatchOps.Replace,
        path: `/${key.toLocaleLowerCase()}`,
        value: values[key as keyof IUppdragFormApiModel],
      },
    ])
    .reduce((acc, current) => [...acc, ...current])
