import React from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import Gutter from '@local/Components/Gutter'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import Alert from '@local/Components/Alert'
import CloseIcon from '@mui/icons-material/Close'
import { IDetaljvyContent } from '@local/Views/Detaljvy/Detaljvy.types'
import useGetUppdrag from '@local/Views/UppdragDetaljvy/useGetUppdrag'

import { IDetaljerModal } from './DetaljerModal.types'

const TitleWithCaption = ({
  title = '',
  caption,
}: {
  title?: string
  caption: string[]
}) => (
  <>
    {title && (
      <Typography
        variant="caption"
        sx={{ lineHeight: 1.5 }}
        color={'text.secondary'}
      >
        {title}
      </Typography>
    )}
    {caption.map((entry, index) => (
      <Typography key={index} variant="body1" sx={{ lineHeight: 1.75 }}>
        {entry}
      </Typography>
    ))}
  </>
)

const DetaljerModal = ({
  modalOpen,
  closeModal,
}: IDetaljerModal): JSX.Element => {
  const { data: uppdrag, isError } = useGetUppdrag()

  const {
    nagotGickFel,
    arbetsgivaransokanLinks,
    orsakTillUppsagning,
    uppdragsdetaljerModal,
  } = usePickEpiContent<IDetaljvyContent>()

  if (!uppdrag) return

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  return (
    <Dialog onClose={closeModal} open={modalOpen} maxWidth={'sm'} fullWidth>
      <DialogTitle sx={{ marginRight: 4 }}>{uppdrag.namn}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container columnSpacing={3} rowSpacing={3}>
          <Grid item sm={6}>
            <Typography variant="body1" fontWeight={'bold'}>
              {uppdragsdetaljerModal.omUppdragetHeader}
            </Typography>
            <Gutter offset_sm={16} />
            <TitleWithCaption
              title={uppdragsdetaljerModal.uppsagningsorsak}
              caption={[orsakTillUppsagning[uppdrag.orsakTillUppsagning]]}
            />
            <TitleWithCaption
              title={uppdragsdetaljerModal.uppdragsnummer}
              caption={[uppdrag.nummer]}
            />
            <TitleWithCaption
              title={uppdragsdetaljerModal.arbetsplats}
              caption={[uppdrag.arbetsstalle.namn]}
            />
            <Gutter offset_sm={16} />

            <Typography variant="body1" fontWeight={'bold'}>
              {uppdragsdetaljerModal.administratorerHeader}
            </Typography>
            <Gutter offset_sm={16} />
            <TitleWithCaption
              caption={uppdrag.administratorer.map((admin) => admin.namn)}
            />
          </Grid>
          <Grid item sm={6}>
            <Typography variant="body1" fontWeight={'bold'}>
              {uppdragsdetaljerModal.fackligaKontakterHeader}
            </Typography>
            <Gutter offset_sm={16} />
            {uppdrag.fackligaKontakter.length > 0 ? (
              uppdrag.fackligaKontakter.map((facklig) => (
                <span key={facklig.id}>
                  <TitleWithCaption
                    title={facklig.namn}
                    caption={facklig.kontaktPersoner.map(
                      (kontakt) => kontakt.fornamn + ' ' + kontakt.efternamn
                    )}
                  />
                </span>
              ))
            ) : (
              <TitleWithCaption
                caption={[uppdragsdetaljerModal.fackligaKontakterSaknas]}
              />
            )}
          </Grid>
        </Grid>

        <Gutter offset_xs={24} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          href={`${arbetsgivaransokanLinks.redigeraUppdragUrl}${uppdrag?.id}/`}
        >
          {uppdragsdetaljerModal.redigeraButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DetaljerModal
