import React from 'react'
import HTMLMapper from '@local/Components/HTMLMapper'
import Gutter from '@local/Components/Gutter'
import Tooltip from '@local/Components/Tooltip/Tooltip'
import { Grid, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { useGetAdminsByOrganisationsnummerQuery } from '@local/services/API/foretagApi'
import { IUppdragFormWrapperContent } from '@local/Views/UppdragFormWrapper/UppdragFormWrapper.types'
import { IUppdragFormValues } from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.types'
import { getFormattedAdmins } from '@local/Views/UppdragFormWrapper/UppdragForm/Helpers'
import { useAuthentication, useUser } from '@trr/app-shell-data'
import Spinner from '@local/Components/Spinner/Spinner'
import SelectChipInput from '@local/Components/SelectChipInput/SelectChipInput'
import { ISelectOption } from '@local/Common.types'
import Alert from '@local/Components/Alert'

const LaggTillAdmin = (): JSX.Element => {
  const { laggTillAdmin, tooltipLaggTillAdmin, nagotGickFel } =
    usePickEpiContent<IUppdragFormWrapperContent>()

  const formikContext = useFormikContext<IUppdragFormValues>()
  const { values, setFieldValue } = formikContext
  const { sub: userSub } = useAuthentication()

  const { kundProperties } = useUser()

  const {
    data: administratorer,
    isError,
    isLoading,
  } = useGetAdminsByOrganisationsnummerQuery(
    kundProperties?.foretag?.organizationNumber
  )

  const selectOptions = getFormattedAdmins(administratorer)?.filter(
    (admin) => admin.value !== userSub
  )

  const handleDelete = (adminId: string) => {
    setFieldValue(
      'admins',
      values.admins?.filter(
        (admin) => (admin as ISelectOption).value !== adminId
      )
    )?.catch(err => console.log(err))
  }

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  return (
    <Grid container>
      <Grid item xs md={8}>
        <Tooltip
          variant="subtitle1"
          heading={laggTillAdmin.heading}
          tooltipContent={<HTMLMapper body={tooltipLaggTillAdmin.mainBody} />}
        />

        <Typography variant="subtitle2">{laggTillAdmin.description}</Typography>

        <Gutter offset_xs={16} />

        {isLoading && <Spinner />}

        {!isLoading && (
          <SelectChipInput
            selectOptions={selectOptions}
            value={values.admins?.filter(
              (admin) => (admin as ISelectOption).value !== userSub
            )}
            label={laggTillAdmin.label}
            name="admins"
            formikContext={formikContext}
            handleDelete={handleDelete}
          />
        )}

        <Gutter offset_xs={8} />
        {selectOptions?.length <= 0 && (
          <>
            <Gutter offset_xs={8} />

            <Alert severity="info">{laggTillAdmin.noAdministratorsFound}</Alert>
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default LaggTillAdmin
