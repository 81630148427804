import React, { useState } from 'react'
import Spinner from '@local/Components/Spinner'
import HTMLMapper from '@local/Components/HTMLMapper'
import Tooltip from '@local/Components/Tooltip/Tooltip'
import { Typography, Button } from '@mui/material'
import { IUppdragDetaljvyContent } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import useGetGroupedAnsokningar from '@local/Views/UppdragDetaljvy/useGetGroupedAnsokningar'
import AnsokanCard from '@local/Views/UppdragDetaljvy/AnsokanCard/AnsokanCard'
import TaBortAnsokanModal from '@local/Views/UppdragDetaljvy/AnsokanCard/TaBortAnsokanModal/TaBortAnsokanModal'
import { useDeleteAnsokanMutation } from '@local/services/API/skapaAnsokanApi'
import { useIsBelowMediumScreens, usePickEpiContent } from '@local/Utils/Hooks'
import Alert from '@local/Components/Alert'

const EjFullstandiga = (): JSX.Element => {
  const {
    ejFullstandiga,
    tooltipEjFullstandiga,
    nagotGickFel,
    taBortModal,
    arbetsgivaransokanLinks: { skapaAnsokanUrl },
  } = usePickEpiContent<IUppdragDetaljvyContent>()
  const isBelowMediumScreens = useIsBelowMediumScreens()

  const [taBortModalOpen, setTaBortModalOpen] = useState(false)
  const [currentAnsokanId, setCurrentAnsokanId] = useState<string>('')

  const [deleteAnsokan, { isError: deleteHasErrors }] =
    useDeleteAnsokanMutation()

  const {
    data: groupedAnsokningar,
    isLoading,
    isError,
  } = useGetGroupedAnsokningar()

  const handleTaBort = async (ansokanId: string) => {
    await deleteAnsokan(ansokanId)
      .unwrap()
      .then(() => !deleteHasErrors && setTaBortModalOpen(false))
      .then(() => {
        window.scroll({ behavior: 'smooth', top: 0 })
      })
  }

  if (isLoading) {
    return <Spinner centered />
  }

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  return (
    <div aria-label="Ej fullständiga ansökningar">
      <Tooltip
        variant="h5"
        heading={ejFullstandiga.heading}
        tooltipContent={<HTMLMapper body={tooltipEjFullstandiga.mainBody} />}
      />
      {groupedAnsokningar?.utkast.length === 0 && (
        <Typography variant="body1">
          {ejFullstandiga.ansokningarSaknas}
        </Typography>
      )}

      {groupedAnsokningar?.utkast.map((ansokan) => (
        <AnsokanCard
          ansokan={ansokan}
          disabledCheckbox
          key={ansokan.id}
          primaryButton={
            <Button
              href={`${skapaAnsokanUrl}${ansokan.id}/`}
              fullWidth={isBelowMediumScreens}
            >
              {ejFullstandiga.redigeraButtonLabel}
            </Button>
          }
          secondaryButton={
            <Button
              variant="outlined"
              fullWidth={isBelowMediumScreens}
              onClick={() => {
                setCurrentAnsokanId(ansokan.id)
                setTaBortModalOpen(true)
              }}
            >
              {taBortModal.confirmText}
            </Button>
          }
        />
      ))}
      <TaBortAnsokanModal
        ansokanId={currentAnsokanId}
        taBortModalOpen={taBortModalOpen}
        setTaBortModalOpen={setTaBortModalOpen}
        handleTaBort={handleTaBort}
        isError={deleteHasErrors}
      />
    </div>
  )
}

export default EjFullstandiga
