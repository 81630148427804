import React from 'react'
import { Button, Grid, Tab, Tabs } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { AnsokanState, OrsakerTillUppsagning } from '@local/Common.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IDetaljvyContent } from '@local/Views/Detaljvy/Detaljvy.types'
import {
  useCanAccessSkapaAnsokningar,
  useGetUppdrag,
} from '@local/Views/Detaljvy/hooks'
import {
  selectAnsokanList,
  setActiveTab,
} from '@local/Views/Detaljvy/AnsokanList/ansokanListSlice'
import { useAppDispatch } from '@local/Store/configureStore'
import { useSelector } from 'react-redux'

const AnsokanTabs = ({
  ansokningarPerTab,
}: {
  ansokningarPerTab: Record<string, number>
}): JSX.Element => {
  const dispatch = useAppDispatch()
  const { activeTab } = useSelector(selectAnsokanList)

  const canAccessSkapaAnsokningar = useCanAccessSkapaAnsokningar()
  const { data: uppdrag } = useGetUppdrag()

  const {
    ansokanState,
    ansokanList,
    skapaAnsokningarLank,
    arbetsgivaransokanLinks: { laggTillMedarbetareUrl },
  } = usePickEpiContent<IDetaljvyContent>()

  let tabs: AnsokanState[] = [
    AnsokanState.Utkast,
    AnsokanState.Inskickad,
    AnsokanState.Bedomd,
  ]

  if (uppdrag?.orsakTillUppsagning === OrsakerTillUppsagning.Risk) {
    tabs = [AnsokanState.Utkast, AnsokanState.KompletteraRisk]
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      height={72}
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.palette.neutral?.divider}`,
      })}
    >
      <Grid item>
        <Tabs
          value={activeTab}
          onChange={(e, filterName: AnsokanState) => {
            dispatch(setActiveTab(filterName))
          }}
          style={{ marginBottom: -16 }}
        >
          {tabs.map((tabName) => (
            <Tab
              label={`${ansokanState[tabName]} (${ansokningarPerTab?.[tabName] || 0})`}
              value={tabName}
              key={tabName}
            />
          ))}
        </Tabs>
      </Grid>
      <Grid item>
        <Grid container columnSpacing={2}>
          <Button
            variant={'contained'}
            startIcon={<AddIcon />}
            sx={{ ml: 2 }}
            href={
              canAccessSkapaAnsokningar
                ? `${skapaAnsokningarLank.url}${uppdrag?.id}/`
                : `${laggTillMedarbetareUrl}${uppdrag?.id}/`
            }
          >
            {ansokanList.filterButtonLaggTillMedarbetareLabel}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AnsokanTabs
