import React from 'react'
import { useFormikContext } from 'formik'
import { ISkapaAnsokanFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import { usePickEpiContent } from '@local/Utils/hooks'
import { ISkapaAnsokanContent } from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'
import HTMLMapper from '@local/Components/HTMLMapper'
import Gutter from '@local/Components/Gutter'
import { AnsokanKeys, RadioChoice } from '@local/Common.types'
import Tooltip from '@local/Components/Tooltip/Tooltip'
import { isNil } from 'ramda'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import FormErrorMessage from '@local/Components/FormErrorMessage/FormErrorMessage'
import Alert from '@local/Components/Alert'

const Undantagsgrupp = (): JSX.Element => {
  const { handleChange, handleBlur, errors, touched, values } =
    useFormikContext<ISkapaAnsokanFormValues>()

  const {
    medarbetare: { undantagsgrupp, tooltipUndantagsgrupp },
  } = usePickEpiContent<ISkapaAnsokanContent>()

  const isError =
    touched?.isVdEllerUndantagsgrupp && !isNil(errors?.isVdEllerUndantagsgrupp)

  return (
    <FormControl error={isError} variant="standard" fullWidth>
      <Tooltip
        variant="h5"
        heading={undantagsgrupp.heading}
        tooltipContent={<HTMLMapper body={tooltipUndantagsgrupp.mainBody} />}
      />

      <Typography variant="subtitle1">{undantagsgrupp.label}</Typography>

      <Gutter offset_xs={16} />

      <RadioGroup
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={values.isVdEllerUndantagsgrupp}
      >
        <FormControlLabel
          value={RadioChoice.Nej}
          control={<Radio color="primary" />}
          label={undantagsgrupp.nej}
          name={AnsokanKeys.IsVdEllerUndantagsgrupp}
          id="isVdEllerUndantagsgrupp.nej"
        />

        <FormControlLabel
          value={RadioChoice.Ja}
          control={<Radio color="primary" />}
          label={undantagsgrupp.ja}
          name={AnsokanKeys.IsVdEllerUndantagsgrupp}
          id="isVdEllerUndantagsgrupp.ja"
        />
      </RadioGroup>

      {isError && (
        <FormErrorMessage>{errors.isVdEllerUndantagsgrupp}</FormErrorMessage>
      )}

      {values.isVdEllerUndantagsgrupp === RadioChoice.Ja && (
        <>
          <Gutter offset_xs={16} />
          <Alert severity="warning">{undantagsgrupp.warning}</Alert>
        </>
      )}
    </FormControl>
  )
}

export default Undantagsgrupp
