import { useGetDinaUppdragQuery } from '@local/services/API/uppdragApi'
import { useAuthentication, useUser } from '@trr/app-shell-data'
import { isEmpty, isNil } from 'ramda'

const useGetDinaUppdrag = () => {
  const { kundProperties } = useUser()
  const { sub } = useAuthentication()

  return useGetDinaUppdragQuery(
    {
      userSub: sub,
      organisationsnummer: kundProperties?.foretag?.organizationNumber,
    },
    { skip: isNil(kundProperties?.foretag) || isEmpty(kundProperties?.foretag) }
  )
}

export default useGetDinaUppdrag
