import React from 'react'
import { useFormikContext } from 'formik'
import { usePickEpiContent } from '@local/Utils/hooks'
import HTMLMapper from '@local/Components/HTMLMapper'
import { OrsakerTillUppsagning } from '@local/Common.types'
import { IUppdragFormWrapperContent } from '@local/Views/UppdragFormWrapper/UppdragFormWrapper.types'
import { IUppdragFormValues } from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.types'
import { useIsBegransadRedigering } from '@local/Views/UppdragFormWrapper/UppdragForm/hooks'
import Tooltip from '@local/Components/Tooltip/Tooltip'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { isNil } from 'ramda'
import FormErrorMessage from '@local/Components/FormErrorMessage/FormErrorMessage'

const OrsakTillUppsagning = (): JSX.Element => {
  const {
    orsakTillUppsagningHeader,
    orsakTillUppsagning,
    tooltipOrsakTillUppsagning,
  } = usePickEpiContent<IUppdragFormWrapperContent>()

  const { handleChange, handleBlur, errors, touched, values } =
    useFormikContext<IUppdragFormValues>()

  const isBegransadRedigering = useIsBegransadRedigering()

  const isError =
    touched.orsakTillUppsagning && !isNil(errors.orsakTillUppsagning)

  return (
    <FormControl error={isError} variant="standard" fullWidth>
      <Tooltip
        variant="subtitle1"
        heading={orsakTillUppsagningHeader.heading}
        tooltipContent={
          <HTMLMapper body={tooltipOrsakTillUppsagning.mainBody} />
        }
      />

      <RadioGroup
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={values.orsakTillUppsagning}
      >
        {Object.values(OrsakerTillUppsagning).map((orsak) => (
          <FormControlLabel
            disabled={isBegransadRedigering}
            key={orsak}
            value={orsak}
            control={<Radio color="primary" />}
            label={orsakTillUppsagning[orsak]}
            name="orsakTillUppsagning"
            id={`orsakTillUppsagning.${orsak}`}
          />
        ))}
      </RadioGroup>

      {isError && (
        <FormErrorMessage>{errors.orsakTillUppsagning}</FormErrorMessage>
      )}
    </FormControl>
  )
}

export default OrsakTillUppsagning
