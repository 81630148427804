import React from 'react'
import { formatIdentityNumber } from '@local/Utils/Helpers'
import { usePickEpiContent } from '@local/Utils/Hooks'
import StatusChip from '@local/Views/Detaljvy/AnsokanList/StatusChip'
import {
  IDetaljvyContent,
  IUppdragsAnsokanApiModel,
} from '@local/Views/Detaljvy/Detaljvy.types'
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro'
import { Button } from '@mui/material'
import { ArbetsgivaransokanStatus } from '@local/Common.types'

const useGetColumnHeaders = (
  redigeraButtonCallback: (ansokan: IUppdragsAnsokanApiModel) => void
) => {
  const {
    ansokanList,
    arbetsgivaransokanLinks: { kompletteraRiskUrl },
  } = usePickEpiContent<IDetaljvyContent>()

  const columnHeaders: GridColDef[] = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 30,
      headerClassName: '',
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      width: 20,
    },
    {
      field: 'ansokanState',
      headerName: '',
    },
    {
      field: 'namn',
      headerName: ansokanList.columnHeaderNamn,
      flex: 1,
    },
    {
      field: 'personnummer',
      headerName: ansokanList.columnHeaderPersonnummer,
      flex: 1,
      valueFormatter: (value) => formatIdentityNumber(value),
    },
    {
      field: 'arbetsgivaransokanStatus',
      headerName: ansokanList.columnHeaderStatus,
      flex: 1,
      renderCell: (params: GridRenderCellParams<IUppdragsAnsokanApiModel>) => (
        <StatusChip status={params.row.arbetsgivaransokanStatus} />
      ),
    },
    {
      field: 'link',
      type: 'actions',
      headerName: '',
      sortable: false,
      width: 115,
      renderCell: (params: GridRenderCellParams<IUppdragsAnsokanApiModel>) => {
        if (
          params.row.arbetsgivaransokanStatus ===
          ArbetsgivaransokanStatus.KompletteringKravs
        ) {
          return (
            <Button
              variant="text"
              size="small"
              disableRipple
              color="primary"
              href={`${kompletteraRiskUrl}${params.row.id}/`}
            >
              {ansokanList.kompletteraButtonLabel}
            </Button>
          )
        } else if (params.row.isRedigerbar) {
          return (
            <Button
              variant="text"
              size="small"
              disableRipple
              color="primary"
              key="edit"
              onClick={() => redigeraButtonCallback(params.row)}
            >
              {ansokanList.redigeraButtonLabel}
            </Button>
          )
        }
      },
    },
  ]

  return { columnHeaders }
}

export default useGetColumnHeaders
