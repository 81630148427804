import React, { useState } from 'react'
import { Checkbox, Typography, Grid } from '@mui/material'
import { getAnsokanColumns } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.helpers'
import Accordion from '@local/Components/Accordion'
import Divider from '@local/Components/Divider'
import Gutter from '@local/Components/Gutter'
import { useIsMediumScreensOrAbove } from '@local/Utils/Hooks'

import { IAnsokanCardProps } from './AnsokanCard.types'
import Anstallning from './Anstallning'
import Sambandsanstallningar from './Sambandsanstallningar'

const AnsokanCard = ({
  ansokan,
  checked = false,
  primaryButton,
  secondaryButton,
  disabledCheckbox = false,
  handleCheckedOnChange,
}: IAnsokanCardProps): JSX.Element => {
  const isMediumScreensOrAbove = useIsMediumScreensOrAbove()

  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <Accordion
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
      aria-label={`Ansökan för ${ansokan.personuppgifter.fornamn} ${ansokan.personuppgifter.efternamn}`}
      ariaControls="ansokan-card"
      ariaControlsId={`ansokan-card-${ansokan.id}`}
      disableGutters
      sx={{
        mb: 2,
        '&:last-child': { mb: 0 },
        '& .MuiAccordionSummary-root': {
          px: 2,
        },
        '& .MuiAccordionSummary-content': {
          my: 2,
        },
      }}
      heading={
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <Grid item md={2}>
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              p={0}
              onClick={(e) => {
                if (isMediumScreensOrAbove && !disabledCheckbox) {
                  e.stopPropagation()
                  handleCheckedOnChange()
                }
              }}
            >
              <Grid item>
                <Checkbox
                  color="primary"
                  inputProps={{
                    name: `Markera ansökan för ${ansokan.personuppgifter.fornamn} ${ansokan.personuppgifter.efternamn}`,
                    'aria-label': `Markera ansökan för ${ansokan.personuppgifter.fornamn} ${ansokan.personuppgifter.efternamn}`,
                  }}
                  checked={handleCheckedOnChange ? checked : undefined}
                  defaultChecked={handleCheckedOnChange ? undefined : checked}
                  disabled={disabledCheckbox}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                  onChange={handleCheckedOnChange}
                />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography noWrap>
                  {`${ansokan.personuppgifter.fornamn} ${ansokan.personuppgifter.efternamn}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {isMediumScreensOrAbove &&
            getAnsokanColumns(ansokan).map((column) => (
              <Grid
                item
                md={2}
                key={column.key}
                sx={{
                  userSelect: 'text',
                  WebkitUserSelect: 'text',
                  cursor: 'text',
                }}
              >
                {column}
              </Grid>
            ))}
        </Grid>
      }
    >
      <Divider offset_xs={4} />
      <Anstallning ansokan={ansokan} />
      <Sambandsanstallningar ansokan={ansokan} />
      {primaryButton || secondaryButton ? (
        <>
          <Gutter offset_xs={24} />
          <Divider offset_xs={48} />

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            {secondaryButton && (
              <Grid item xs={12} md="auto">
                {secondaryButton}
              </Grid>
            )}
            {primaryButton && (
              <Grid item xs={12} md="auto">
                {primaryButton}
              </Grid>
            )}
          </Grid>
        </>
      ) : null}
    </Accordion>
  )
}

export default AnsokanCard
