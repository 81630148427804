import React from 'react'
import { FieldArrayRenderProps, useFormikContext } from 'formik'
import { IUppdragFormValues } from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.types'
import FackligKontaktListItem from '@local/Views/UppdragFormWrapper/UppdragForm/FackligKontakt/FackligKontaktList/FackligKontaktListItem'

const FackligKontaktList = ({
  arrayHelpers,
}: {
  arrayHelpers: FieldArrayRenderProps
}): JSX.Element => {
  const { values } = useFormikContext<IUppdragFormValues>()

  return (
    <>
      {values.fackligaKontakter?.length > 0
        ? values.fackligaKontakter.map((kontakt, index) => (
            <FackligKontaktListItem
              key={kontakt.key}
              kontakt={kontakt}
              index={index}
              arrayHelpers={arrayHelpers}
            />
          ))
        : null}
    </>
  )
}

export default FackligKontaktList
