import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'ramda'
import { Button, Grid } from '@mui/material'
import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik'
import {
  ISkapaAnsokanFormValues,
  SkapaAnsokanStep,
} from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import StickyFooter from '@local/Components/StickyMuiFooter/StickyFooter'
import {
  gotoNext,
  gotoPrevious,
  selectStepper,
} from '@local/Views/SkapaAnsokan/SkapaAnsokanNavigation/skapaAnsokanNavigationSlice'
import { useAppDispatch } from '@local/Store/configureStore'
import useGetAnsokan from '@local/Views/SkapaAnsokan/useGetAnsokan'
import { setFormIsDirty } from '@local/Views/SkapaAnsokan/skapaAnsokanSlice'
import { ISkapaAnsokanContent } from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'
import { baseApi } from '@local/services/API/baseApi'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { useIsFeatureEnabled } from '@trr/app-shell-data'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import Alert from '@local/Components/Alert'

const SkapaAnsokanFormSubmit = (): JSX.Element => {
  const {
    nagotGickFel,
    arbetsgivaransokanLinks: { uppdragUrl },
    sidfot,
  } = usePickEpiContent<ISkapaAnsokanContent>()

  const shouldTrack = useIsFeatureEnabled('gtm.enabled.kundansokan')
  const trackButtonClick = useTrackCustomClick()

  const dispatch = useAppDispatch()
  const formikContext = useFormikContext<ISkapaAnsokanFormValues>()
  const history = useHistory()

  const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState(false)

  const { activeStep, steps } = useSelector(selectStepper)
  const isLastStep = (activeStep as number) === steps.length - 1

  const { data: ansokan, isError } = useGetAnsokan()

  const redirectUrl = `${uppdragUrl}${ansokan?.uppdragId}/`

  useEffect(() => {
    if (isEmpty(formikContext.errors)) {
      setShouldShowErrorMessage(false)
    }
  }, [formikContext.errors])

  const goBackHandler = useCallback(() => {
    dispatch(gotoPrevious())
    window.scroll({ top: 0 })
  }, [dispatch])

  const goNextHandler = useCallback(() => {
    dispatch(gotoNext())
    window.scroll({ top: 0 })
  }, [dispatch])

  const handleFinish = useCallback(() => {
    const navigateToUppdrag = () => {
      // Manually invalidate ansökan since its now complete and needs to be refetched
      baseApi.util.invalidateTags([
        { type: 'ansokan', id: 'GROUPED_LIST' },
        { type: 'ansokan', id: ansokan.id },
      ])
      dispatch(setFormIsDirty(false))

      setTimeout(() => {
        history.push(redirectUrl)
      }, 50)
    }
    void (async () => {
      const formErrors = await formikContext.validateForm()

      if (isEmpty(formErrors)) {
        if (shouldTrack) {
          trackButtonClick('Medarbetaransökan', {
            label: 'Slutför',
          })
        }
        navigateToUppdrag()
      } else {
        formikContext.setTouched(
          setNestedObjectValues<FormikTouched<ISkapaAnsokanFormValues>>(
            formErrors,
            true
          )
        )?.catch(err => console.log(err))
        setShouldShowErrorMessage(true)
      }
    })()
  }, [
    ansokan?.id,
    dispatch,
    formikContext,
    history,
    redirectUrl,
    shouldTrack,
    trackButtonClick,
  ])

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  return (
    <StickyFooter
      leftElements={
        <Button variant="outlined" href={redirectUrl}>
          {sidfot.avbryt}
        </Button>
      }
      rightElements={
        <div>
          <Grid container columnSpacing={{ xs: 1, sm: 2 }}>
            <Grid item xs={6} sm="auto">
              <Button
                disabled={activeStep === SkapaAnsokanStep.Medarbetare}
                variant="outlined"
                onClick={goBackHandler}
              >
                {sidfot.foregaende}
              </Button>
            </Grid>

            <Grid item xs={6} sm="auto">
              {isLastStep && (
                <Button
                  variant="contained"
                  onClick={handleFinish}
                  data-trackable="false"
                >
                  {sidfot.slutfor}
                </Button>
              )}
              {!isLastStep && (
                <Button variant="contained" onClick={goNextHandler}>
                  {sidfot.nastaSteg}
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      }
      isError={shouldShowErrorMessage}
      errorMsg={sidfot.felmeddelande}
    />
  )
}

export default SkapaAnsokanFormSubmit
