import React from 'react'
import { useFormikContext } from 'formik'
import CommonUppsagningsgrad from '@local/Components/Uppsagningsgrad'
import { ISkapaAnsokanContent } from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'
import { ISkapaAnsokanFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import useGetAnsokan from '@local/Views/SkapaAnsokan/useGetAnsokan'
import { usePickEpiContent } from '@local/Utils/hooks'
import { isNil } from 'ramda'
import Alert from '@local/Components/Alert'
import { AnsokanKeys, AnsokanType } from '@local/Common.types'

const Uppsagningsgrad = (): JSX.Element => {
  const formikContext = useFormikContext<ISkapaAnsokanFormValues>()
  const { values, errors, touched } = formikContext

  const {
    anstallning: { uppsagningsgrad },
    nagotGickFel,
  } = usePickEpiContent<ISkapaAnsokanContent>()

  const { data: ansokan, isError } = useGetAnsokan()

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  if (
    ansokan.isRisk ||
    ansokan.type === AnsokanType.omstallningTidsbegransadAnstallning
  ) {
    return null
  }

  return (
    <CommonUppsagningsgrad
      value={values.uppsagningsgrad}
      formikContext={formikContext}
      formikName={AnsokanKeys.Uppsagningsgrad}
      uppsagningsgrad={uppsagningsgrad}
      error={touched?.uppsagningsgrad && !isNil(errors?.uppsagningsgrad)}
      errorMsg={errors?.uppsagningsgrad}
    />
  )
}

export default Uppsagningsgrad
