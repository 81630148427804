import { OrsakerTillUppsagning } from '@local/Common.types'
import {
  useIsMediumScreensOrAbove,
  usePickEpiContent,
} from '@local/Utils/hooks'
import { useGetUppdrag } from '@local/Utils/hooks/useGetUppdrag'
import { IUppdragContent } from '@local/Views/Uppdrag/Uppdrag.types'
import { isNil } from 'ramda'

const useCanAccessSkapaAnsokningar = (): boolean => {
  const isMediumScreensOrAbove = useIsMediumScreensOrAbove()

  const { data: uppdrag } = useGetUppdrag()

  const { skapaAnsokningarLank } = usePickEpiContent<IUppdragContent>()

  const isArbetsbrist =
    uppdrag?.orsakTillUppsagning === OrsakerTillUppsagning.Arbetsbrist

  const isOverenskommelseWithFacklig =
    uppdrag?.orsakTillUppsagning === OrsakerTillUppsagning.Overenskommelse &&
    uppdrag?.fackligaKontakter?.length > 0

  if (
    isMediumScreensOrAbove &&
    !isNil(skapaAnsokningarLank) &&
    (isArbetsbrist || isOverenskommelseWithFacklig)
  ) {
    return true
  }

  return false
}

export default useCanAccessSkapaAnsokningar
