import React from 'react'
import { Grid, Typography, styled } from '@mui/material'
import Gutter from '@local/Components/Gutter'
import WarningIcon from '@mui/icons-material/Warning'
import {
  useIsBelowMediumScreens,
  useIsMediumScreensOrAbove,
} from '@local/Utils/Hooks'
import { IHeaderContent } from '@local/Components/RemovableCard/Header/types'

const StyledWarningIcon = styled(({ ...props }) => {
  const isMediumScreensOrAbove = useIsMediumScreensOrAbove()

  return (
    <WarningIcon
      color="error"
      fontSize={isMediumScreensOrAbove ? 'large' : 'medium'}
      {...props}
    />
  )
})(({ theme }) => ({
  '&.MuiSvgIcon-root': {
    marginRight: theme.spacing(),

    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
  },
}))

const Header = ({
  title,
  subtitle,
  titlePlaceholder,
  cardHasErrors,
}: IHeaderContent): JSX.Element => {
  const isBelowMediumScreens = useIsBelowMediumScreens()

  return (
    <Grid container>
      <Grid item xs>
        <Typography variant={isBelowMediumScreens ? 'h6' : 'h4'}>
          {title ?? titlePlaceholder}
        </Typography>

        {subtitle && (
          <>
            <Gutter offset_xs={8} />
            <Typography variant={isBelowMediumScreens ? 'body2' : 'body1'}>
              {subtitle}
            </Typography>
          </>
        )}
      </Grid>
      <Grid item xs="auto" sx={{ display: 'flex', alignItems: 'center' }}>
        {cardHasErrors && <StyledWarningIcon />}
      </Grid>
    </Grid>
  )
}

export default Header
