import React from 'react'
import Gutter from '@local/Components/Gutter'
import { Grid, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import {
  getFackligKontaktListItemErrors,
  getFackligKontaktListItemTouched,
} from '@local/Utils/Helpers/FackligKontakt.helpers'
import { useGetFackforbundQuery } from '@local/services/API/uppdrag/uppdragApi'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IUppdragFormWrapperContent } from '@local/Views/UppdragFormWrapper/UppdragFormWrapper.types'
import { IUppdragFormValues } from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.types'
import TextInput from '@local/Components/TextInput/TextInput'
import { isNil } from 'ramda'
import SelectInput from '@local/Components/SelectInput/SelectInput'
import Spinner from '@local/Components/Spinner/Spinner'
import { ISelectOption } from '@local/Common.types'
import Alert from '@local/Components/Alert'

const FackligKontaktUppgifter = ({ index }: { index: number }): JSX.Element => {
  const { fackligKontaktListItem, nagotGickFel } =
    usePickEpiContent<IUppdragFormWrapperContent>()

  const formikContext = useFormikContext<IUppdragFormValues>()
  const { touched, errors, values } = formikContext

  const fackligKontaktListItemErrors = getFackligKontaktListItemErrors(
    index,
    errors
  )
  const fackligKontaktListItemTouched = getFackligKontaktListItemTouched(
    index,
    touched
  )

  const {
    data: fackforbund,
    isError: isErrorFackforbund,
    isLoading: isLoadingFackforbund,
    isSuccess: isSuccessFackforbund,
  } = useGetFackforbundQuery()

  if (isErrorFackforbund) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  const selectOptions: ISelectOption[] = fackforbund?.map((x) => ({
    label: x.namn,
    value: x.kod,
  }))

  return (
    <Grid container>
      <Grid item xs md={9}>
        <Typography variant="subtitle1">
          {fackligKontaktListItem.fornamn.heading}
        </Typography>

        <Gutter offset_xs={8} />

        <TextInput
          inputMode="text"
          formikContext={formikContext}
          label={fackligKontaktListItem.fornamn.heading}
          name={`fackligaKontakter.${index}.fornamn`}
          value={values.fackligaKontakter[index].fornamn}
          placeholder={fackligKontaktListItem.fornamn.heading}
          maxLength={40}
          error={
            fackligKontaktListItemTouched.fornamn &&
            !isNil(fackligKontaktListItemErrors.fornamn)
          }
          errorMsg={fackligKontaktListItemErrors.fornamn}
        />

        <Gutter offset_xs={32} />

        <Typography variant="subtitle1">
          {fackligKontaktListItem.efternamn.heading}
        </Typography>

        <Gutter offset_xs={8} />

        <TextInput
          inputMode="text"
          formikContext={formikContext}
          label={fackligKontaktListItem.efternamn.heading}
          name={`fackligaKontakter.${index}.efternamn`}
          value={values.fackligaKontakter[index].efternamn}
          placeholder={fackligKontaktListItem.efternamn.heading}
          maxLength={40}
          error={
            fackligKontaktListItemTouched.efternamn &&
            !isNil(fackligKontaktListItemErrors.efternamn)
          }
          errorMsg={fackligKontaktListItemErrors.efternamn}
        />

        <Gutter offset_xs={32} />

        <Typography variant="subtitle1">
          {fackligKontaktListItem.epostadress.heading}
        </Typography>

        <Gutter offset_xs={8} />

        <TextInput
          inputMode="email"
          formikContext={formikContext}
          label={fackligKontaktListItem.epostadress.heading}
          name={`fackligaKontakter.${index}.epostadress`}
          value={values.fackligaKontakter[index].epostadress}
          placeholder={fackligKontaktListItem.epostadress.heading}
          error={
            fackligKontaktListItemTouched.epostadress &&
            !isNil(fackligKontaktListItemErrors.epostadress)
          }
          errorMsg={fackligKontaktListItemErrors.epostadress}
        />

        <Gutter offset_xs={32} />

        <Typography variant="subtitle1">
          {fackligKontaktListItem.telefonnummer.heading}
        </Typography>

        <Gutter offset_xs={8} />

        <TextInput
          inputMode="tel"
          formikContext={formikContext}
          label={fackligKontaktListItem.telefonnummer.heading}
          name={`fackligaKontakter.${index}.telefon`}
          value={values.fackligaKontakter[index].telefon}
          placeholder={fackligKontaktListItem.telefonnummer.heading}
          error={
            fackligKontaktListItemTouched.telefon &&
            !isNil(fackligKontaktListItemErrors.telefon)
          }
          errorMsg={fackligKontaktListItemErrors.telefon}
          maxLength={30}
        />

        <Gutter offset_xs={32} />

        <Typography variant="subtitle1">
          {fackligKontaktListItem.fackforbund.heading}
        </Typography>

        <Gutter offset_xs={8} />

        {isLoadingFackforbund && <Spinner />}
        {isSuccessFackforbund && (
          <SelectInput
            selectOptions={selectOptions}
            label={fackligKontaktListItem.fackforbund.label}
            name={`fackligaKontakter.${index}.fackforbund`}
            value={values.fackligaKontakter[index].fackforbund}
            formikContext={formikContext}
            isError={
              fackligKontaktListItemTouched.fackforbund &&
              !isNil(fackligKontaktListItemErrors.fackforbund)
            }
            errorMsg={fackligKontaktListItemErrors.fackforbund}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default FackligKontaktUppgifter
