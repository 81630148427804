import { useIsRedigeraUppdrag } from '@local/Utils/Hooks/UppdragForm'
import useGetUppdrag from '@local/Views/UppdragDetaljvy/useGetUppdrag'

const useIsBegransadRedigering = (): boolean => {
  const { data: uppdrag } = useGetUppdrag()
  const isRedigera = useIsRedigeraUppdrag()

  return isRedigera && uppdrag.antalMedarbetare > 0
}

export default useIsBegransadRedigering
