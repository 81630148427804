import React, { useEffect } from 'react'
import { FieldArrayRenderProps, useFormikContext } from 'formik'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IUppdragFormWrapperContent } from '@local/Views/UppdragFormWrapper/UppdragFormWrapper.types'
import { IUppdragFormValues } from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.types'
import { useAppDispatch } from '@local/Store/configureStore'
import { setShouldHighlightCard } from '@local/Components/RemovableCard/removableCardSlice'
import { OrsakerTillUppsagning } from '@local/Common.types'
import Gutter from '@local/Components/Gutter'
import {
  useFackligKontaktHasErrors,
  useShouldShowFackligKontaktSaknas,
} from '@local/Utils/Hooks/UppdragForm'
import { Button, Grid } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { generateEmptyFackligKontakt } from '@local/Views/UppdragFormWrapper/UppdragForm/Helpers'

const FackligKontaktAdd = ({
  arrayHelpers,
}: {
  arrayHelpers: FieldArrayRenderProps
}): JSX.Element => {
  const dispatch = useAppDispatch()
  const shouldShowFackligKontaktSaknas = useShouldShowFackligKontaktSaknas()
  const { fackligKontakt } = usePickEpiContent<IUppdragFormWrapperContent>()
  const fackligKontaktHasErrors = useFackligKontaktHasErrors()

  const { values, setFieldValue } = useFormikContext<IUppdragFormValues>()

  const addFackligKontakt = () => {
    dispatch(setShouldHighlightCard(true))
    arrayHelpers.push(generateEmptyFackligKontakt())
    setFieldValue('fackligRepSaknas', false)?.catch(err => console.log(err))
  }

  //Reset to original state when component is destroyed/unmounted
  useEffect(
    () => () => {
      dispatch(setShouldHighlightCard(false))
    },
    [dispatch]
  )

  const shouldShowFackligKontaktAdd = () => {
    switch (values.orsakTillUppsagning) {
      case OrsakerTillUppsagning.Arbetsbrist:
        return true
      case OrsakerTillUppsagning.Overenskommelse:
        return !values.fackligRepSaknas
      case OrsakerTillUppsagning.Sjukdom:
        return !values.fackligRepSaknas
      default:
        return false
    }
  }

  if (!shouldShowFackligKontaktAdd()) {
    return null
  }

  return (
    <Grid container>
      <Grid item md={8}>
        <Button
          variant="outlined"
          startIcon={<AddCircleIcon />}
          onClick={addFackligKontakt}
        >
          {fackligKontakt.buttonText}
        </Button>
        {shouldShowFackligKontaktSaknas && !fackligKontaktHasErrors && (
          <Gutter offset_xs={fackligKontaktHasErrors ? 16 : 32} />
        )}
      </Grid>
    </Grid>
  )
}

export default FackligKontaktAdd
