import React from 'react'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ISkapaAnsokanContent } from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'
import Gutter from '@local/Components/Gutter'
import Loneuppgifter from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Lon/Loneuppgifter'
import TillaggsBelopp from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Lon/TillaggsBelopp'
import Avgangsvederlag from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Lon/Avgangsvederlag'
import { Grid, Typography } from '@mui/material'
import HTMLMapper from '@local/Components/HTMLMapper'

const Lon = (): JSX.Element => {
  const { tabbar, lon } = usePickEpiContent<ISkapaAnsokanContent>()

  return (
    <Grid container>
      <Grid item md={8}>
        <Typography variant="h3">{tabbar.lon}</Typography>

        <Gutter offset_xs={16} />

        <HTMLMapper body={lon.ingress.mainBody} />

        <Gutter offset_xs={32} offset_md={40} />

        <Loneuppgifter />

        <Gutter offset_xs={32} />

        <TillaggsBelopp />

        <Gutter offset_xs={32} />

        <Avgangsvederlag />

        <Gutter offset_xs={48} />
      </Grid>
    </Grid>
  )
}

export default Lon
