import React from 'react'
import { Grid2 as Grid, Skeleton, Typography } from '@mui/material'
import { useCheckFileTimestamp } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/hooks'
import { isNil } from 'ramda'
import { usePickEpiContent } from '@local/Utils/hooks'
import { ISkapaAnsokningarContent } from '@local/Views/SkapaAnsokningar/types/types'

const SkeletonLoading = (): JSX.Element => {
  const {
    sammanfattning: { validering },
  } = usePickEpiContent<ISkapaAnsokningarContent>()
  const { arbitraryTimeHasPassedSinceUpload } = useCheckFileTimestamp()

  if (isNil(arbitraryTimeHasPassedSinceUpload)) {
    return <Grid container width={'100%'} alignItems={'center'} />
  }

  if (arbitraryTimeHasPassedSinceUpload) {
    return (
      <Grid container width={'100%'} alignItems={'center'}>
        <Grid>
          <Typography variant="body2">{validering.namnSparError}</Typography>
        </Grid>
      </Grid>
    )
  }
  return <Skeleton variant="text" width={`100%`} />
}

export default SkeletonLoading
