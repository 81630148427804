import { IUniqueFile } from '@local/Components/FileUpload/FileUpload.types'
import { RootState } from '@local/Store/configureStore'
import { ISkapaAnsokanFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ISkapaAnsokanState {
  values: ISkapaAnsokanFormValues
  formIsDirty: boolean
}

const initialState: ISkapaAnsokanState = {
  values: null,
  formIsDirty: false,
}

export const skapaAnsokanSlice = createSlice({
  name: 'skapaAnsokan',
  initialState,
  reducers: {
    setValues: (state, action: PayloadAction<ISkapaAnsokanFormValues>) => {
      state.values = action.payload
    },
    setOverenskommelseBilagor: (
      state,
      action: PayloadAction<IUniqueFile[]>
    ) => {
      if (state.values) {
        state.values.overenskommelseBilagor = action.payload
      }
    },
    setFormIsDirty: (state, action: PayloadAction<boolean>) => {
      state.formIsDirty = action.payload
    },
    resetState: () => initialState,
  },
})

export const {
  setValues,
  setOverenskommelseBilagor,
  setFormIsDirty,
  resetState,
} = skapaAnsokanSlice.actions

export const selectSkapaAnsokan = (state: RootState) => state.skapaAnsokan

export default skapaAnsokanSlice.reducer
