import React from 'react'
import { Avatar, Grid2 as Grid, Typography, useTheme } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import {
  ISkapaAnsokningarContent,
  ISkapaAnsokningarFormValues,
} from '@local/Views/SkapaAnsokningar/types/types'
import { usePickEpiContent } from '@local/Utils/hooks'
import { useFormikContext } from 'formik'
import { replaceEpiVariables } from '@local/Utils/helpers'

const NoErrors = (): JSX.Element => {
  const theme = useTheme()

  const {
    sammanfattning: { validering },
  } = usePickEpiContent<ISkapaAnsokningarContent>()

  const { values } = useFormikContext<ISkapaAnsokningarFormValues>()

  return (
    <Grid container alignItems="center" gap={1}>
      <Grid>
        <Avatar
          sx={{
            width: 42,
            height: 42,
            bgcolor: theme.palette.surface?.green,
          }}
        >
          <CheckIcon color={'success'} sx={{ fontSize: 24 }} />
        </Avatar>
      </Grid>
      <Grid>
        <Typography variant="body1">
          {replaceEpiVariables(validering.filansokningarNoErrors, {
            validAnsokningarCount: String(values.filansokningar.length),
          })}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default NoErrors
