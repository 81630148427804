import React from 'react'
import { Chip, ChipProps } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { styled } from '@mui/material/styles'
import { IDetaljvyContent } from '@local/Views/Detaljvy/Detaljvy.types'
import { ArbetsgivaransokanStatus } from '@local/Common.types'

const StyledStatusChip = styled('div')(({ theme }) => ({
  userSelect: 'none',
  pointerEvents: 'none',
  cursor: 'pointer',

  '.MuiChip-outlined': {
    border: 'none',
  },
  '&:nth-child(2)': {
    marginLeft: theme.spacing(1),
  },

  display: 'flex',
  '.MuiChip-colorError .MuiSvgIcon-root': {
    color: theme.palette.error.main,
  },
  '.MuiChip-colorSuccess .MuiSvgIcon-root': {
    color: theme.palette.success.main,
  },
  '.MuiChip-colorInfo .MuiSvgIcon-root': {
    color: theme.palette.info.main,
  },
  '.MuiChip-colorWarning .MuiSvgIcon-root': {
    color: theme.palette.warning.main,
  },
  '&.MuiChip-colorPrimary .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
  '.MuiChip-colorSecondary .MuiSvgIcon-root': {
    color: theme.palette.secondary.main,
  },
}))

const getStatusColor = (
  Status: ArbetsgivaransokanStatus
): ChipProps['color'] => {
  switch (Status) {
    case ArbetsgivaransokanStatus.Preskriberad:
    case ArbetsgivaransokanStatus.EjInskickad:
    case ArbetsgivaransokanStatus.Redigerad:
      return 'warning'
    case ArbetsgivaransokanStatus.EjKomplett:
    case ArbetsgivaransokanStatus.KompletteringKravs:
      return 'error'
    case ArbetsgivaransokanStatus.Bedomd:
      return 'success'
    case ArbetsgivaransokanStatus.Okand:
    case ArbetsgivaransokanStatus.Inskickad:
    default:
      return 'info'
  }
}

const StatusChip = ({
  status,
  size = 'small',
}: {
  status: ArbetsgivaransokanStatus
  size?: 'small' | 'medium'
}): JSX.Element => {
  const { arbetsgivaransokanStatus } = usePickEpiContent<IDetaljvyContent>()

  if (!status) {
    return null
  }
  const chipColor = getStatusColor(status)

  return (
    <StyledStatusChip>
      <Chip
        size={size}
        color={chipColor}
        avatar={<CircleIcon />}
        label={arbetsgivaransokanStatus[status]}
      />
    </StyledStatusChip>
  )
}

export default StatusChip
