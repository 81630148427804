import React from 'react'
import { Box, Button, Grid2 as Grid, Typography } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Gutter from '@local/Components/Gutter'
import { usePickEpiContent } from '@local/Utils/hooks'
import { IUppdragContent } from '@local/Views/Uppdrag/Uppdrag.types'
import { useGetOverlayContent } from '@local/Views/Uppdrag/AnsokanList/IngaRaderOverlay/hooks'
import { useCanAccessSkapaAnsokningar } from '@local/Utils/hooks/useCanAccessSkapaAnsokningar'
import { useGetUppdrag } from '@local/Utils/hooks/useGetUppdrag'

const IngaRaderOverlay = (): JSX.Element => {
  const {
    ingaRaderOverlay,
    skapaAnsokningarLank,
    arbetsgivaransokanLinks: { laggTillMedarbetareUrl },
  } = usePickEpiContent<IUppdragContent>()

  const canAccessSkapaAnsokningar = useCanAccessSkapaAnsokningar()

  const { heading, body } = useGetOverlayContent()
  const { data: uppdrag } = useGetUppdrag()

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        sx={{ flexFlow: 'wrap' }}
        padding={4}
      >
        <Typography variant="h6">{heading}</Typography>

        <Gutter offset_xs={16} />

        <Typography variant="body2">{body}</Typography>

        <Gutter offset_xs={24} />

        <Button
          variant="text"
          startIcon={<AddCircleIcon />}
          href={
            canAccessSkapaAnsokningar
              ? `${skapaAnsokningarLank.url}${uppdrag?.id}/`
              : `${laggTillMedarbetareUrl}${uppdrag?.id}/`
          }
        >
          {ingaRaderOverlay.buttonLabel}
        </Button>
      </Grid>
    </Box>
  )
}

export default IngaRaderOverlay
