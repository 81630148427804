import { ISkapaAnsokanFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import useFormikWatcherCallback from '@local/Views/SkapaAnsokan/hooks/usePatchAnsokan/hooks/useFormikWatcherCallback'
import { usePatchAnsokanMutation } from '@local/services/API/skapaAnsokanApi'
import { isNil } from 'ramda'
import { useCallback, useEffect } from 'react'

const usePatchAnsokan = (
  setStoredValuesAfterPatch: (values: Partial<ISkapaAnsokanFormValues>) => void,
  storedValues: ISkapaAnsokanFormValues
) => {
  const { requestedUpdates, setRequestedUpdates, formikWatcherCallback } =
    useFormikWatcherCallback()

  const [patchAnsokan, { isError, isLoading: isLoadingPatch }] =
    usePatchAnsokanMutation()

  const patchAnsokanCallback = useCallback(async () => {
    const requestedUpdatesRef = requestedUpdates
    setRequestedUpdates({ updatesToPatch: null, updatesToStore: null })

    await patchAnsokan({
      values: requestedUpdatesRef.updatesToPatch,
      prevValues: storedValues,
    })
      .unwrap()
      .then(() => {
        setStoredValuesAfterPatch(requestedUpdatesRef.updatesToStore)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [
    patchAnsokan,
    storedValues,
    requestedUpdates,
    setStoredValuesAfterPatch,
    setRequestedUpdates,
  ])

  useEffect(() => {
    if (
      !isLoadingPatch &&
      !isNil(requestedUpdates.updatesToPatch) &&
      !isNil(requestedUpdates.updatesToStore)
    ) {
      void patchAnsokanCallback()
    }
  }, [isLoadingPatch, requestedUpdates, patchAnsokanCallback])

  return { isError, patchAnsokan: formikWatcherCallback }
}

export default usePatchAnsokan
