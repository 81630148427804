import React from 'react'
import { isNil } from 'ramda'
import { Grid, Typography } from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IAnstallningProps } from '@local/Views/UppdragDetaljvy/AnsokanCard/Anstallning/Anstallning.types'
import { IUppdragDetaljvyContent } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import { trrFormat } from '@local/Utils/Helpers/formatDate'
import useGetUppdragIsRisk from '@local/Views/UppdragDetaljvy/useGetUppdragIsRisk'
import { AnsokanType } from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'

const AnstallningDatum = ({ ansokan }: IAnstallningProps): JSX.Element => {
  const { anstallningsinformation } =
    usePickEpiContent<IUppdragDetaljvyContent>()

  const isRisk = useGetUppdragIsRisk()
  const isTioTbAnsokan =
    ansokan.type === AnsokanType.omstallningTidsbegransadAnstallning

  return (
    <>
      <Grid item xs py={2}>
        <Typography variant="subtitle1">
          {anstallningsinformation.anstallningsdatum}
        </Typography>
        <Typography variant="subtitle2">
          {!isNil(ansokan.anstallningsuppgifter.anstallningsdatum) &&
            trrFormat(ansokan.anstallningsuppgifter.anstallningsdatum)}
        </Typography>
      </Grid>
      {!isTioTbAnsokan && (
        <Grid item xs py={2}>
          <Typography variant="subtitle1">
            {anstallningsinformation.uppsagningsdatum}
          </Typography>
          <Typography variant="subtitle2">
            {isRisk
              ? anstallningsinformation.ejFaststalltLabel
              : !isNil(ansokan.anstallningsuppgifter.uppsagningsdatum) &&
                trrFormat(ansokan.anstallningsuppgifter.uppsagningsdatum)}
          </Typography>
        </Grid>
      )}
      <Grid item py={2}>
        <Typography variant="subtitle1">
          {anstallningsinformation.sistaAnstallningsdatum}
        </Typography>
        <Typography variant="subtitle2">
          {isRisk
            ? anstallningsinformation.ejFaststalltLabel
            : !isNil(ansokan.anstallningsuppgifter.sistaAnstallningsdatum) &&
              trrFormat(ansokan.anstallningsuppgifter.sistaAnstallningsdatum)}
        </Typography>
      </Grid>
    </>
  )
}

export default AnstallningDatum
