import React from 'react'
import { Button, Grid, Typography, useTheme } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { useFormikContext } from 'formik'
import { ISkapaAnsokningarFormValues } from '@local/Views/SkapaAnsokningar/types/types'
import { useSelector } from 'react-redux'
import {
  setCurrentErrorIndex,
  selectErrorStepper,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/ErrorStepper/errorStepperSlice'
import { useAppDispatch } from '@local/Store/configureStore'
import { IErrorStepper } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/ErrorStepper/types/types'
import {
  getNextErrorIndex,
  getPreviousErrorIndex,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/helpers/common'
import { isEmpty, isNil } from 'ramda'
import NoErrors from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/NoErrors'

const ErrorStepper = ({ apiRef }: IErrorStepper): JSX.Element => {
  const dispatch = useAppDispatch()
  const theme = useTheme()

  const { currentErrorIndex, errors } = useSelector(selectErrorStepper)

  const { values, errors: formikErrors } =
    useFormikContext<ISkapaAnsokningarFormValues>()

  const stepIntoError = (nextErrorIndex: number) => {
    const nextError = errors[nextErrorIndex]
    const colIndex = apiRef.current.getColumnIndex(nextError.id)
    const rowId = values.filansokningar[errors[nextErrorIndex]?.rowIndex]?.id

    dispatch(setCurrentErrorIndex(nextErrorIndex))

    if (rowId) {
      apiRef.current.scrollToIndexes({ rowIndex: nextError.rowIndex, colIndex })
      apiRef.current.setCellFocus(rowId, nextError.id)
    }
  }

  const handleGoToNextError = () => {
    const nextErrorIndex = getNextErrorIndex(currentErrorIndex, errors)
    stepIntoError(nextErrorIndex)
  }

  const handleGoToPreviousError = () => {
    const previousErrorIndex = getPreviousErrorIndex(currentErrorIndex, errors)

    stepIntoError(previousErrorIndex)
  }

  const currentError =
    values.filansokningar?.[errors?.[currentErrorIndex]?.rowIndex]

  if (isEmpty(errors)) {
    return <NoErrors />
  }

  return (
    <Grid container alignItems="center" gap={1}>
      <Grid item>
        <Button
          variant="outlined"
          sx={{
            minWidth: 0,
            padding: theme.spacing(1),
          }}
          onClick={handleGoToPreviousError}
          aria-label="Stega till föregående fel"
        >
          <ChevronLeftIcon />
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          sx={{ minWidth: 0, padding: theme.spacing(1) }}
          onClick={handleGoToNextError}
          aria-label="Stega till nästa fel"
        >
          <ChevronRightIcon />
        </Button>
      </Grid>
      <Grid
        item
        sx={{
          marginLeft: theme.spacing(1),
          display: 'flex',
        }}
      >
        <div>
          <Grid container gap={1}>
            <Grid item>
              <Typography variant="body1" color={theme.palette.error.main}>
                {`${currentErrorIndex + 1}/${errors.length}`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" color={theme.palette.error.main}>
                {String(errors[currentErrorIndex]?.error)}
              </Typography>
            </Grid>
            {typeof formikErrors.filansokningar !== 'string' &&
              !isNil(currentError?.namn) &&
              !isEmpty(currentError?.namn) && (
                <>
                  <Grid item>
                    <Typography variant="body1">-</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">{currentError.namn}</Typography>
                  </Grid>
                </>
              )}
          </Grid>
        </div>
      </Grid>
    </Grid>
  )
}

export default ErrorStepper
