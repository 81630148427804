import {
  useCreateUppdragMutation,
  usePatchUppdragMutation,
} from '@local/services/API/uppdragApi'
import { useIsRedigeraUppdrag } from '@local/Utils/Hooks/UppdragForm'
import useGetUppdrag from '@local/Views/UppdragDetaljvy/useGetUppdrag'
import { IUppdragFormValues } from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.types'
import {
  mapUppdragFormValuesToApi,
  mapCreateUppdragFormValuesToApi,
  mapUppdragValuesToPatchModel,
} from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragFormValuesMapper'
import { useAuthentication, useUser } from '@trr/app-shell-data'
import { useFormikContext } from 'formik'
import { ISelectOption } from '@local/Common.types'

const useGetSparaUppdragMutation = () => {
  const { values } = useFormikContext<IUppdragFormValues>()
  const user = useUser()
  const { sub: userSub } = useAuthentication()

  const [createUppdrag] = useCreateUppdragMutation({
    fixedCacheKey: 'shared-create-uppdrag',
  })
  const [patchUppdrag] = usePatchUppdragMutation({
    fixedCacheKey: 'shared-patch-uppdrag',
  })
  const { data: uppdrag } = useGetUppdrag()

  const isRedigera = useIsRedigeraUppdrag()
  const valuesWithUserAsAdmin = values.admins?.find(
    (admin) => (admin as ISelectOption).value === userSub
  )
    ? values
    : {
        ...values,
        admins: [
          ...(values.admins || []),
          { label: `${user.firstName} ${user.lastName}`, value: userSub },
        ],
      }

  return isRedigera
    ? () =>
        patchUppdrag({
          uppdrag: uppdrag,
          values: mapUppdragValuesToPatchModel(
            mapUppdragFormValuesToApi(valuesWithUserAsAdmin),
            uppdrag
          ),
        })
    : () =>
        createUppdrag(
          mapCreateUppdragFormValuesToApi(
            valuesWithUserAsAdmin,
            user?.kundProperties?.foretag?.organizationNumber
          )
        )
}

export default useGetSparaUppdragMutation
