import { IFilAnsokanRowPutModel } from '@local/services/API/filansokan/types'
import { trrDateUTC } from '@local/Utils/Helpers/formatDate'
import { getDropdownSelectOptionValue } from '@local/Utils/Helpers/Forms/Forms.helpers'
import { IFilAnsokanFormValues } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/types/types'

// /**
//  * Maps formik values to API model for sending PUT in filAnsokan
//  */

export const getMappedFilAnsokanFormValues = (
  values: IFilAnsokanFormValues
): IFilAnsokanRowPutModel => ({
  anstallningsdatum: values.anstallningsdatum
    ? trrDateUTC(values.anstallningsdatum)
    : null,
  anstallningsgrad: values.anstallningsgrad,
  arbetstimmarPerVecka: values.arbetstimmarPerVecka,
  avgangsvederlag: values.avgangsvederlag,
  epostadress: values.epostadress,
  hasTjanstemannaavtal: values.hasTjanstemannaavtal
    ? getDropdownSelectOptionValue(values.hasTjanstemannaavtal) === 'true'
    : null,
  isVdEllerUndantagsgrupp: values.isVdEllerUndantagsgrupp
    ? getDropdownSelectOptionValue(values.isVdEllerUndantagsgrupp) === 'true'
    : null,
  jourBelopp: values.skiftJourOBTillaggBelopp,
  lonerevisionsdatum: values.lonSenastAndrad
    ? trrDateUTC(values.lonSenastAndrad)
    : null,
  manadslon: values.manadslon,
  provisionBelopp: values.provisionTantiemBelopp,
  sistaAnstallningsdatum: values.sistaAnstallningsdatum
    ? trrDateUTC(values.sistaAnstallningsdatum)
    : null,
  sjukersattningsgrad: values.sjukersattningsgrad
    ? (getDropdownSelectOptionValue(values.sjukersattningsgrad) as number)
    : null,
  uppsagningsdatum: values.uppsagningsdatum
    ? trrDateUTC(values.uppsagningsdatum)
    : null,
  uppsagningsgrad: values.uppsagningsgrad,
})
