import React from 'react'
import { Typography, Grid } from '@mui/material'
import Gutter from '@local/Components/Gutter'
import { IUppdragDetaljvyContent } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import { usePickEpiContent } from '@local/Utils/Hooks'

import { IAnstallningProps } from './Anstallning.types'
import Medarbetare from './Medarbetare'
import AnstallningDatum from './AnstallningDatum'
import Omfattning from './Omfattning'
import Lon from './Lon'

const Anstallning = ({ ansokan }: IAnstallningProps): JSX.Element => {
  const { anstallningsinformation } =
    usePickEpiContent<IUppdragDetaljvyContent>()

  return (
    <>
      <Gutter offset_xs={16} />
      <Typography variant="h6" textTransform={'uppercase'}>
        {anstallningsinformation.heading}
      </Typography>
      <Gutter offset_xs={8} />

      <Grid container>
        <Medarbetare ansokan={ansokan} />

        <Grid item md={4} xs={12}>
          <AnstallningDatum ansokan={ansokan} />
          <Omfattning ansokan={ansokan} />
        </Grid>

        <Lon ansokan={ansokan} />
      </Grid>
    </>
  )
}

export default Anstallning
