import { usePickEpiContent } from '@local/Utils/hooks'
import { ILaggTillMedarbetareContent } from '@local/Views/LaggTillMedarbetare/LaggTillMedarbetare.types'
import React from 'react'

import LaggTillMedarbetareForm from './LaggTillMedarbetareForm'

const LaggTillMedarbetare = (): JSX.Element => {
  const { ansokanValidering } = usePickEpiContent<ILaggTillMedarbetareContent>()
  return (
    <section>
      <LaggTillMedarbetareForm ansokanValidering={ansokanValidering} />
    </section>
  )
}

export default LaggTillMedarbetare
