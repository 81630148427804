import React from 'react'
import { Grid2 as Grid, Alert as MuiAlert } from '@mui/material'
import { IAlert } from '@local/Components/Alert/types'

const Alert: React.FunctionComponent<React.PropsWithChildren<IAlert>> = ({
  centered,
  children,
  ...props
}): JSX.Element => (
  <Grid container justifyContent={centered ? 'center' : null}>
    <Grid maxWidth={752} flex="0 0 100%">
      <MuiAlert {...props}>{children}</MuiAlert>
    </Grid>
  </Grid>
)

export default Alert
