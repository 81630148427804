import React from 'react'
import { Button, Divider, Grid2 as Grid, Tab, Tabs } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { AnsokanState, OrsakerTillUppsagning } from '@local/Common.types'
import {
  useIsMediumScreensOrAbove,
  usePickEpiContent,
} from '@local/Utils/hooks'
import { IUppdragContent } from '@local/Views/Uppdrag/Uppdrag.types'
import {
  selectAnsokanList,
  setActiveTab,
} from '@local/Views/Uppdrag/AnsokanList/ansokanListSlice'
import { useAppDispatch } from '@local/Store/configureStore'
import { useSelector } from 'react-redux'
import { useCanAccessSkapaAnsokningar } from '@local/Utils/hooks/useCanAccessSkapaAnsokningar'
import { useGetUppdrag } from '@local/Utils/hooks/useGetUppdrag'
import Gutter from '@local/Components/Gutter'

const AnsokanTabs = ({
  ansokningarPerTab,
}: {
  ansokningarPerTab: Record<string, number>
}): JSX.Element => {
  const dispatch = useAppDispatch()
  const { activeTab } = useSelector(selectAnsokanList)

  const isMediumScreenOrAbove = useIsMediumScreensOrAbove()

  const canAccessSkapaAnsokningar = useCanAccessSkapaAnsokningar()
  const { data: uppdrag } = useGetUppdrag()

  const {
    ansokanState,
    ansokanList,
    skapaAnsokningarLank,
    arbetsgivaransokanLinks: { laggTillMedarbetareUrl },
  } = usePickEpiContent<IUppdragContent>()

  let tabs: AnsokanState[] = [
    AnsokanState.Utkast,
    AnsokanState.Inskickad,
    AnsokanState.Bedomd,
  ]

  if (uppdrag?.orsakTillUppsagning === OrsakerTillUppsagning.Risk) {
    tabs = [AnsokanState.Utkast, AnsokanState.KompletteraRisk]
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        rowGap={isMediumScreenOrAbove ? 2 : 4}
      >
        <Grid size={{ xs: 12, md: 'grow' }}>
          <Tabs
            sx={{ minHeight: 0 }}
            value={activeTab}
            onChange={(e, filterName: AnsokanState) => {
              dispatch(setActiveTab(filterName))
            }}
          >
            {tabs.map((tabName) => (
              <Tab
                label={`${ansokanState[tabName]} (${ansokningarPerTab?.[tabName] || 0})`}
                value={tabName}
                key={tabName}
              />
            ))}
          </Tabs>
          {!isMediumScreenOrAbove && <Divider />}
        </Grid>
        <Grid size={{ xs: 12, md: 'auto' }}>
          <Button
            variant={'contained'}
            startIcon={<AddIcon />}
            href={
              canAccessSkapaAnsokningar
                ? `${skapaAnsokningarLank.url}${uppdrag?.id}/`
                : `${laggTillMedarbetareUrl}${uppdrag?.id}/`
            }
          >
            {ansokanList.filterButtonLaggTillMedarbetareLabel}
          </Button>
          <Gutter offset_xs={8} offset_md={12} />
        </Grid>
      </Grid>
      {isMediumScreenOrAbove && <Divider />}
    </>
  )
}

export default AnsokanTabs
