import React, { useEffect } from 'react'
import Spinner from '@local/Components/Spinner'
import { Grid2 as Grid } from '@mui/material'
import { IUppdragFormWrapperContent } from '@local/Views/UppdragFormWrapper/UppdragFormWrapper.types'
import UppdragForm from '@local/Views/UppdragFormWrapper/UppdragForm'
import { usePickEpiContent } from '@local/Utils/hooks'
import { usePatchUppdragMutation } from '@local/services/API/uppdrag/uppdragApi'
import Gutter from '@local/Components/Gutter'
import Alert from '@local/Components/Alert'
import { useGetUppdrag } from '@local/Utils/hooks/useGetUppdrag'

const RedigeraUppdrag = (): JSX.Element => {
  const content = usePickEpiContent<IUppdragFormWrapperContent>()

  const {
    data: uppdrag,
    isLoading,
    isError: isErrorGetUppdrag,
  } = useGetUppdrag()

  const [, { isError: isErrorPatchUppdrag, reset }] = usePatchUppdragMutation({
    fixedCacheKey: 'shared-patch-uppdrag',
  })

  useEffect(
    () => () => {
      reset()
    },
    [reset]
  )

  if (isLoading) {
    return <Spinner centered />
  }

  if (isErrorGetUppdrag) {
    return (
      <Grid container>
        <Grid size={{ md: 8 }}>
          <Alert severity="error">{content.nagotGickFel.heading}</Alert>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {isErrorPatchUppdrag && (
        <Grid container>
          <Grid size={{ md: 8 }}>
            <Alert severity="error">{content.nagotGickFel.heading}</Alert>
            <Gutter offset_xs={16} />
          </Grid>
        </Grid>
      )}

      <UppdragForm content={content} uppdrag={uppdrag} />
    </>
  )
}

export default RedigeraUppdrag
