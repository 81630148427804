import React from 'react'
import Spinner from '@local/Components/Spinner'
import SkapaAnsokanForm from '@local/Views/SkapaAnsokan/SkapaAnsokanForm'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ISkapaAnsokanContent } from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'
import useGetAnsokan from '@local/Views/SkapaAnsokan/useGetAnsokan'
import useGetAllOverenskommelseBilaga from '@local/Components/OverenskommelseBilaga/useGetAllOverenskommelseBilaga'
import { useGetKanRedigeraAnsokanQuery } from '@local/services/API/ansokan/skapaAnsokanApi'
import HTMLMapper from '@local/Components/HTMLMapper'
import Alert from '@local/Components/Alert'

const SkapaAnsokan = (): JSX.Element => {
  const content = usePickEpiContent<ISkapaAnsokanContent>()
  const {
    data: ansokan,
    isLoading: isLoadingAnsokan,
    isError: isErrorAnsokan,
  } = useGetAnsokan()

  const {
    data: kanRedigera,
    isLoading: isLoadingKanRedigera,
    isError: isErrorKanRedigera,
  } = useGetKanRedigeraAnsokanQuery()

  const {
    data: bilagor,
    isLoading: isLoadingBilagor,
    isError: isErrorBilagor,
  } = useGetAllOverenskommelseBilaga({
    skip: !ansokan?.kraverOverenskommelseBilaga,
  })

  if (isLoadingAnsokan || isLoadingBilagor || isLoadingKanRedigera) {
    return <Spinner centered />
  }

  if (isErrorAnsokan || isErrorBilagor || isErrorKanRedigera) {
    return <Alert severity="error">{content.nagotGickFel.heading}</Alert>
  }

  if (!kanRedigera) {
    return <HTMLMapper body={content.ejRedigerbar.mainBody} />
  }

  return (
    <SkapaAnsokanForm content={content} ansokan={ansokan} bilagor={bilagor} />
  )
}

export default SkapaAnsokan
