import { FackligKontaktOrsakerTillUppsagning } from '@local/Common.types'
import CompressedList from '@local/Components/CompressedList'
import {
  getFackligaKontakterFlatList,
  getFackligaKontakterNames,
} from '@local/Utils/Helpers/FackligKontakt.helpers'
import { IFackligaKontakterItemProps } from '@local/Views/DinaUppdrag/DinaUppdrag.types'
import { Grid, Typography } from '@mui/material'
import React from 'react'

const FackligaKontakterItem = ({
  uppdrag,
  label,
  p = 0,
  py = 0,
  px = 0,
}: IFackligaKontakterItemProps): JSX.Element => {
  const flatList = getFackligaKontakterFlatList(uppdrag.fackligaKontakter)

  return (
    !uppdrag.fackligRepSaknas &&
    Object.values<string>(FackligKontaktOrsakerTillUppsagning).includes(
      uppdrag.orsakTillUppsagning
    ) && (
      <Grid item px={px} p={p} py={py}>
        <Typography variant="subtitle1">{label}</Typography>
        <Typography variant="body2">
          {
            <CompressedList
              list={getFackligaKontakterNames(flatList)}
              maxNumberOfItems={2}
            />
          }
        </Typography>
      </Grid>
    )
  )
}

export default FackligaKontakterItem
