import React from 'react'
import {
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { useIsFeatureEnabled } from '@trr/app-shell-data'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import { IDetaljvyContent } from '@local/Views/Detaljvy/Detaljvy.types'
import { replaceEpiVariables } from '@local/Utils/Helpers'
import { isNil } from 'ramda'

import { IVarningInvalidAnsokningar } from './VarningInvalidAnsokningar.types'

const VarningInvalidAnsokningar = ({
  markeradeAnsokningar,
  apiRef,
  setMarkeradeAnsokningar,
  closeTabortModal,
}: IVarningInvalidAnsokningar): JSX.Element => {
  const { taBortVarningModal } = usePickEpiContent<IDetaljvyContent>()

  const shouldTrack = useIsFeatureEnabled('gtm.enabled.kundansokan')
  const trackButtonClick = useTrackCustomClick()

  const invalidAnsokningar = markeradeAnsokningar.filter(
    (ansokan) => !isNil(ansokan.inskickadAv)
  )
  const validAnsokningar = markeradeAnsokningar.filter((ansokan) =>
    isNil(ansokan.inskickadAv)
  )
  const hasValidAnsokningar = validAnsokningar.length > 0

  const handleOnConfirm = () => {
    if (shouldTrack) {
      trackButtonClick('Modal: Ta bort ansökningar', {
        label: 'Gå vidare med ej inskickade ansökningar',
      })
    }

    setMarkeradeAnsokningar(validAnsokningar)
    apiRef.current.setRowSelectionModel(
      validAnsokningar.map((ansokan) => ansokan.id)
    )
  }

  return (
    <>
      <DialogTitle>{taBortVarningModal.heading}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {hasValidAnsokningar &&
            replaceEpiVariables(taBortVarningModal.description, {
              invalidAnsokningarCount: String(invalidAnsokningar.length),
            })}
          {!hasValidAnsokningar && taBortVarningModal.invalidDescription}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeTabortModal}>
          {taBortVarningModal.cancelLabel}
        </Button>
        {hasValidAnsokningar && (
          <Button variant="text" onClick={handleOnConfirm}>
            {taBortVarningModal.confirmLabel}
          </Button>
        )}
      </DialogActions>
    </>
  )
}

export default VarningInvalidAnsokningar
