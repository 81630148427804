import React, { useEffect } from 'react'
import { isNil } from 'ramda'
import { useFormikContext } from 'formik'
import Gutter from '@local/Components/Gutter'
import HTMLMapper from '@local/Components/HTMLMapper'
import Tooltip from '@local/Components/Tooltip/Tooltip'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { ISkapaAnsokanContent } from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'
import useGetAnsokan from '@local/Views/SkapaAnsokan/useGetAnsokan'
import { ISkapaAnsokanFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import { usePickEpiContent } from '@local/Utils/hooks'
import { AnsokanKeys, AnsokanType, RadioChoice } from '@local/Common.types'
import FormErrorMessage from '@local/Components/FormErrorMessage/FormErrorMessage'
import NumberInput from '@local/Components/NumberInput/NumberInput'
import Alert from '@local/Components/Alert'

const Avgangsvederlag = (): JSX.Element => {
  const formikContext = useFormikContext<ISkapaAnsokanFormValues>()
  const {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = formikContext

  const {
    lon: { avgangsvederlag, tooltipAvgangsvederlag },
    nagotGickFel,
  } = usePickEpiContent<ISkapaAnsokanContent>()

  const { data: ansokan, isError: isErrorGetAnsokan } = useGetAnsokan()

  useEffect(() => {
    if (values.hasAvgangsvederlag === RadioChoice.Nej) {
      setFieldValue(AnsokanKeys.Avgangsvederlag, null)?.catch((err) =>
        console.log(err)
      )
      setFieldTouched(AnsokanKeys.Avgangsvederlag, false)?.catch((err) =>
        console.log(err)
      )
    }
  }, [setFieldValue, setFieldTouched, values.hasAvgangsvederlag])

  if (isErrorGetAnsokan) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  if (
    ansokan.isRisk ||
    ansokan.type === AnsokanType.omstallningTidsbegransadAnstallning
  ) {
    return null
  }

  const isError =
    touched?.hasAvgangsvederlag && !isNil(errors?.hasAvgangsvederlag)

  return (
    <div aria-label={avgangsvederlag.heading}>
      <FormControl error={isError} variant="standard" fullWidth>
        <Tooltip
          variant="subtitle1"
          heading={avgangsvederlag.heading}
          tooltipContent={<HTMLMapper body={tooltipAvgangsvederlag.mainBody} />}
        />

        <RadioGroup
          onChange={handleChange}
          onBlur={handleBlur}
          defaultValue={values.hasAvgangsvederlag}
        >
          <FormControlLabel
            value={RadioChoice.Ja}
            control={<Radio color="primary" />}
            label={avgangsvederlag.ja}
            name={AnsokanKeys.HasAvgangsvederlag}
            id="hasAvgangsvederlag.ja"
          />
          <FormControlLabel
            value={RadioChoice.Nej}
            control={<Radio color="primary" />}
            label={avgangsvederlag.nej}
            name={AnsokanKeys.HasAvgangsvederlag}
            id="hasAvgangsvederlag.nej"
          />
        </RadioGroup>

        {isError && (
          <FormErrorMessage>{errors.hasAvgangsvederlag}</FormErrorMessage>
        )}

        {values.hasAvgangsvederlag === RadioChoice.Ja && (
          <>
            <Gutter offset_xs={32} />

            <Typography variant="body2">{avgangsvederlag.label}</Typography>

            <Gutter offset_xs={8} />

            <NumberInput
              formikContext={formikContext}
              label={avgangsvederlag.label}
              placeholder={avgangsvederlag.placeholder}
              name={AnsokanKeys.Avgangsvederlag}
              value={values.avgangsvederlag}
              error={
                touched?.avgangsvederlag && !isNil(errors?.avgangsvederlag)
              }
              errorMsg={errors?.avgangsvederlag}
            />
          </>
        )}
      </FormControl>
    </div>
  )
}

export default Avgangsvederlag
