import React from 'react'
import { Form, withFormik } from 'formik'
import {
  ISkapaAnsokningarFormProps,
  ISkapaAnsokningarFormValues,
} from '@local/Views/SkapaAnsokningar/types/types'
import { useIsFeatureFlagEnabled } from '@local/Utils/Hooks'
import SkapaAnsokningarTracker from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/SkapaAnsokningarTracker'
import { mapFilAnsokanToLaggTillMedarbetareFormValues } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/helpers'
import { mapFilAnsokanDraftToSammanfattningFormValues } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/helpers'
import FullScreenHeader from '@local/Components/FullScreenHeader'
import SkapaAnsokningarHeader from '@local/Views/SkapaAnsokningar/SkapaAnsokningarHeader'
import SkapaAnsokningarStepper from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/SkapaAnsokningarStepper'
import SkapaAnsokningarFormSubmit from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/SkapaAnsokningarFormSubmit'
import {
  useGetMedarbetareNamn,
  useSetFilansokningarErrors,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/hooks'

const SkapaAnsokningarForm = () => {
  const shouldTrack = useIsFeatureFlagEnabled('gtm.enabled.kundansokan')

  useSetFilansokningarErrors()
  useGetMedarbetareNamn()

  return (
    <>
      <FullScreenHeader headerComponents={<SkapaAnsokningarHeader />} />

      {shouldTrack && <SkapaAnsokningarTracker />}

      <Form aria-label="Skapa ansökningar">
        <SkapaAnsokningarStepper />

        <SkapaAnsokningarFormSubmit />
      </Form>
    </>
  )
}

const SkapaAnsokningarFormik = withFormik<
  ISkapaAnsokningarFormProps,
  ISkapaAnsokningarFormValues
>({
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: ({ filansokan, filansokanRows, excelShouldBePreset }) => ({
    ...mapFilAnsokanToLaggTillMedarbetareFormValues(
      filansokan,
      excelShouldBePreset
    ),
    ...mapFilAnsokanDraftToSammanfattningFormValues(filansokanRows),
  }),
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  validationSchema: ({ skapaAnsokningarSchema }: ISkapaAnsokningarFormProps) =>
    skapaAnsokningarSchema,
  displayName: 'SkapaAnsokningarForm',
})(SkapaAnsokningarForm)

export default SkapaAnsokningarFormik
