import React from 'react'
import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import Spinner from '@local/Components/Spinner'
import { usePickEpiContent } from '@local/Utils/Hooks'
import Gutter from '@local/Components/Gutter'
import HTMLMapper from '@local/Components/HTMLMapper'
import { useAppDispatch } from '@local/Store/configureStore'
import {
  ILaggTillMedarbetareContent,
  ILaggTillMedarbetareFormValues,
} from '@local/Views/LaggTillMedarbetare/LaggTillMedarbetare.types'
import useGetUppdrag from '@local/Views/UppdragDetaljvy/useGetUppdrag'
import Tooltip from '@local/Components/Tooltip/Tooltip'
import TextInput from '@local/Components/TextInput/TextInput'
import { Button } from '@mui/material'
import { handleChangeWithTrim } from '@local/Utils/Helpers/Forms/Forms.helpers'

import {
  selectPersonuppgifterState,
  setShouldGetPersonuppgifter,
} from '../Personuppgifter/personuppgifterSlice'
import Personuppgifter from '../Personuppgifter'

const Personnummer = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const formikContext = useFormikContext<ILaggTillMedarbetareFormValues>()

  const { personnummer: personnummerContent, tooltipPersonnummer } =
    usePickEpiContent<ILaggTillMedarbetareContent>()

  const { shouldGetPersonuppgifter } = useSelector(selectPersonuppgifterState)

  const { data: currentUppdrag, isLoading } = useGetUppdrag()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setShouldGetPersonuppgifter(false))
    handleChangeWithTrim(e, formikContext.handleChange)
  }

  if (isLoading) {
    return <Spinner centered />
  }

  return (
    <div>
      <Tooltip
        variant="subtitle1"
        heading={personnummerContent.personnummer}
        tooltipContent={<HTMLMapper body={tooltipPersonnummer.mainBody} />}
      />

      <TextInput
        withTrim
        value={formikContext.values.personnummer}
        label={personnummerContent.personnummer}
        placeholder={personnummerContent.personnummerPlaceholder}
        formikContext={formikContext}
        name="personnummer"
        inputMode="numeric"
        onChange={onChange}
      />

      <Gutter offset_xs={24} />

      <Button
        variant={shouldGetPersonuppgifter ? 'outlined' : 'contained'}
        onClick={() => formikContext.handleSubmit()}
      >
        {personnummerContent.hamtaUppgifter}
      </Button>

      <Personuppgifter
        personnummer={formikContext.values.personnummer}
        orsak={currentUppdrag.orsakTillUppsagning}
      />
    </div>
  )
}

export default Personnummer
