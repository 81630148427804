import React from 'react'
import { ISkapaAnsokanContent } from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import HTMLMapper from '@local/Components/HTMLMapper'
import { useFormikContext } from 'formik'
import { ISkapaAnsokanFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import Tooltip from '@local/Components/Tooltip/Tooltip'
import NumberInput from '@local/Components/NumberInput/NumberInput'
import { isNil } from 'ramda'
import { AnsokanKeys } from '@local/Common.types'

const Arbetstimmar = (): JSX.Element => {
  const formikContext = useFormikContext<ISkapaAnsokanFormValues>()
  const { errors, touched, values } = formikContext

  const {
    anstallning: { arbetstimmar, tooltipArbetstimmar },
  } = usePickEpiContent<ISkapaAnsokanContent>()

  return (
    <div>
      <Tooltip
        variant="subtitle1"
        heading={arbetstimmar.heading}
        tooltipContent={<HTMLMapper body={tooltipArbetstimmar.mainBody} />}
      />

      <NumberInput
        formikContext={formikContext}
        label={arbetstimmar.heading}
        placeholder={arbetstimmar.placeholder}
        name={AnsokanKeys.ArbetstimmarPerVecka}
        value={values.arbetstimmarPerVecka}
        error={
          touched?.arbetstimmarPerVecka && !isNil(errors?.arbetstimmarPerVecka)
        }
        errorMsg={errors?.arbetstimmarPerVecka}
      />
    </div>
  )
}

export default Arbetstimmar
