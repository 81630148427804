import React from 'react'
import { usePickEpiContent } from '@local/Utils/hooks'
import { IKompletteraRiskansokanContent } from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokan.types'
import KompletteraRiskansokanForm from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokanForm'
import useGetAnsokan from '@local/Views/SkapaAnsokan/useGetAnsokan'
import Spinner from '@local/Components/Spinner/Spinner'
import Alert from '@local/Components/Alert'

const KompletteraRiskansokan = (): JSX.Element => {
  const content = usePickEpiContent<IKompletteraRiskansokanContent>()
  const { data: ansokan, isLoading, isError } = useGetAnsokan()

  if (isLoading) {
    return <Spinner centered />
  }

  if (isError) {
    return <Alert severity="error">{content.nagotGickFel.heading}</Alert>
  }

  return <KompletteraRiskansokanForm content={content} ansokan={ansokan} />
}

export default KompletteraRiskansokan
