import React, { useCallback, useEffect, useState, useRef } from 'react'
import {
  Checkbox,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Button,
} from '@mui/material'
import Spinner from '@local/Components/Spinner'
import { usePickEpiContent } from '@local/Utils/Hooks'
import Gutter from '@local/Components/Gutter'
import { IUppdragDetaljvyContent } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import HTMLMapper from '@local/Components/HTMLMapper'
import { useIsFeatureEnabled } from '@trr/app-shell-data'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import Alert from '@local/Components/Alert'

import { ISkickaInModal } from './SkickaInModal.types'

const SkickaInModal = ({
  shouldResetModalContent,
  modalOpen,
  isLoadingSkickaInAnsokan,
  closeModal,
  handleSend,
}: ISkickaInModal): JSX.Element => {
  const { skickaInJuridiskText, skickaInModal } =
    usePickEpiContent<IUppdragDetaljvyContent>()

  const shouldTrack = useIsFeatureEnabled('gtm.enabled.kundansokan')
  const trackButtonClick = useTrackCustomClick()

  const [errorMessage, setErrorMessage] = useState(skickaInModal.felmeddelande)
  const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState(false)
  const [checked, setChecked] = useState(false)

  const errorMessageRef = useRef<HTMLDivElement>(null)

  const toggleChecked = () => {
    setChecked(!checked)
  }

  const scrollIntoView = () => {
    errorMessageRef.current?.scrollIntoView({
      behavior: 'smooth',
    })
  }
  useEffect(() => {
    if (shouldShowErrorMessage) {
      scrollIntoView()
    }
  }, [shouldShowErrorMessage])

  useEffect(() => {
    if (shouldResetModalContent) {
      setShouldShowErrorMessage(false)
      setChecked(false)
    }
  }, [shouldResetModalContent])

  useEffect(() => {
    if (checked) {
      setShouldShowErrorMessage(false)
    }
  }, [checked])

  const handleOnConfirm = () =>
    new Promise<void>((resolve) => {
      setShouldShowErrorMessage(false)

      if (shouldTrack) {
        trackButtonClick('Modal: Skicka in ansökan', {
          label: 'Skicka in',
        })
      }
      handleSend()
        .then(() => {
          closeModal()
          resolve()
        })
        .catch(() => {
          setErrorMessage(skickaInModal.felmeddelandeRequest)
          setShouldShowErrorMessage(true)
          resolve()
        })
    })

  const handleOnClose = () => {
    if (shouldTrack) {
      trackButtonClick('Modal: Skicka in ansökan', {
        label: 'Avbryt',
      })
    }
    closeModal()
  }

  const handleOnConfirmError = useCallback(() => {
    setErrorMessage(skickaInModal.felmeddelande)
    setShouldShowErrorMessage(true)
    scrollIntoView()
  }, [setShouldShowErrorMessage, setErrorMessage, skickaInModal.felmeddelande])

  return (
    <Dialog open={modalOpen}>
      <DialogTitle>{skickaInModal.heading}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">{skickaInModal.preamble}</Typography>
        </DialogContentText>

        <Gutter offset_xs={12} />

        {isLoadingSkickaInAnsokan && <Spinner centered />}
        {!isLoadingSkickaInAnsokan && (
          <>
            <Typography variant="subtitle1">
              {skickaInModal.juridiskTextTitle}
            </Typography>

            <Gutter offset_xs={12} />

            <HTMLMapper body={skickaInJuridiskText.mainBody} />

            <Gutter offset_xs={24} />

            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={checked}
                  aria-checked={checked}
                  inputProps={{ 'aria-checked': checked }}
                  onChange={toggleChecked}
                />
              }
              label={skickaInModal.checkboxLabel}
            />
          </>
        )}
        {shouldShowErrorMessage && (
          <>
            <Gutter offset_xs={24} />
            <Alert severity="error" ref={errorMessageRef}>
              {errorMessage}
            </Alert>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={handleOnClose}
          disabled={isLoadingSkickaInAnsokan}
        >
          {skickaInModal.cancelText}
        </Button>

        <Button
          variant="text"
          onClick={checked ? handleOnConfirm : handleOnConfirmError}
          disabled={isLoadingSkickaInAnsokan}
        >
          {skickaInModal.confirmText}
        </Button>
      </DialogActions>

      <Gutter offset_xs={16} />
    </Dialog>
  )
}

export default SkickaInModal
