import React from 'react'
import {
  Box,
  Button,
  Divider,
  Grid2 as Grid,
  Snackbar,
  Typography,
} from '@mui/material'
import { IUppdragContent } from '@local/Views/Uppdrag/Uppdrag.types'
import { useIsSmallScreensOrAbove, usePickEpiContent } from '@local/Utils/hooks'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import DeleteIcon from '@mui/icons-material/Delete'
import Gutter from '@local/Components/Gutter'
import { IMarkeradeAnsokningar } from '@local/Views/Uppdrag/AnsokanList/MarkeradeAnsokningar/types/types'

const MarkeradeAnsokningar = ({
  open,
  markeradeAnsokningar,
  setOpen,
  openSkickaInModal,
  openTaBortModal,
}: IMarkeradeAnsokningar): JSX.Element => {
  const isSmallScreensOrAbove = useIsSmallScreensOrAbove()

  const { markeraAnsokningar: markeraAnsokningarContent } =
    usePickEpiContent<IUppdragContent>()

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
    >
      <Box
        sx={{
          backgroundColor: 'neutral.main',
          color: 'common.white',
          borderRadius: '4px',
          padding: 2,
          minWidth: isSmallScreensOrAbove ? '500px' : 'unset',
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: '700' }}>
          {markeradeAnsokningar.length}{' '}
          {markeraAnsokningarContent.markeradeAnsokningar}
        </Typography>

        <Gutter offset_xs={16} />

        <Divider
          sx={{
            backgroundColor: 'common.white',
            width: `calc(100% + 32px)`,
            marginLeft: '-16px',
          }}
        />

        <Gutter offset_xs={16} />

        <Grid container spacing={3}>
          <Grid size={{ xs: 'auto' }}>
            <Button
              variant="text"
              startIcon={<ArrowForwardIcon />}
              sx={{ color: 'common.white', padding: 0 }}
              onClick={openSkickaInModal}
            >
              {markeraAnsokningarContent.skickaIn}
            </Button>
          </Grid>
          <Grid size={{ xs: 'auto' }}>
            <Button
              variant="text"
              startIcon={<DeleteIcon />}
              sx={{ color: 'common.white', padding: 0 }}
              onClick={openTaBortModal}
            >
              {markeraAnsokningarContent.taBort}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Snackbar>
  )
}

export default MarkeradeAnsokningar
