import { useEffect } from 'react'
import { useAppDispatch } from '@local/Store/configureStore'
import {
  ISkapaAnsokningarFormValues,
  SkapaAnsokningarStep,
} from '@local/Views/SkapaAnsokningar/types/types'
import { useLocation } from 'react-router-dom'
import useGetSteps from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/useGetSteps'
import { useFormikContext } from 'formik'
import { LaggTillMedarbetareFormTypes } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/LaggTillMedarbetare/types/types'
import { initStepper } from '@local/Views/SkapaAnsokningar/skapaAnsokningarStepperSlice'
import { UUIDRegex, matchStringByRegex } from '@local/Utils/regexes'
import { useGetFilAnsokanQuery } from '@local/services/API/filansokan/filAnsokanApi'
import { isNil } from 'ramda'

const useSetDefaultStep = () => {
  const { setFieldValue } = useFormikContext<ISkapaAnsokningarFormValues>()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { steps } = useGetSteps()

  const uppdragsId = matchStringByRegex(location.pathname, UUIDRegex)

  const { data: filansokan } = useGetFilAnsokanQuery(uppdragsId)

  const paramStep = Number(new URLSearchParams(location.search).get('step'))

  useEffect(() => {
    const isValidStep = Object.values(SkapaAnsokningarStep).includes(paramStep)
    let defaultStep = SkapaAnsokningarStep.LaggTillMedarbetare

    if (isValidStep && !isNil(filansokan?.fileId)) {
      defaultStep = paramStep

      setFieldValue(
        'laggTillMedarbetareChoice',
        LaggTillMedarbetareFormTypes.LaggTillMedarbetareExcel
      )?.catch((err) => console.log(err))
    }

    dispatch(initStepper({ steps, activeStep: defaultStep }))

    // Only setup once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useSetDefaultStep
