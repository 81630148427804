import { IKeyValue, FilAnsokanKeys } from '@local/Common.types'

export interface IUploadFilAnsokanModel {
  formData: FormData
  uppdragsId: string
}

export interface IUploadFilAnsokanModal {
  uppdragsId: string
  fileId: string
  villkorsText: string
}
export interface IPutFilAnsokanModel {
  uppdragsId: string
  fileId: string
  rowId: string
  rowData: IFilAnsokanRowPutModel
}

export interface IFilAnsokanRowPutModel {
  anstallningsdatum: Date
  anstallningsgrad: number
  arbetstimmarPerVecka: number
  avgangsvederlag: number
  epostadress: string
  hasTjanstemannaavtal: boolean
  isVdEllerUndantagsgrupp: boolean
  jourBelopp: number
  lonerevisionsdatum: Date
  manadslon: number
  provisionBelopp: number
  sistaAnstallningsdatum: Date
  sjukersattningsgrad: number
  uppsagningsdatum: Date
  uppsagningsgrad: number
}

export interface IDeleteFilAnsokanModel {
  fileId: string
  uppdragsId: string
}

export interface IFilAnsokanResponse {
  filnamn: string
  fileId: string
  uppdragsId: string
  uploadedAt: Date
  columnHeaders: IColumnHeaders[]
}

export interface IColumnHeaders {
  key: string
  filansokanKey: FilAnsokanKeys
  value: string
}

export interface FileError {
  reason: keyof typeof FilePickerError
  name: string
}

export enum FilePickerError {
  MIN_AMOUNT_OF_FILES_NOT_REACHED = 'minAmountOfFilesNotReached',
  MAX_AMOUNT_OF_FILES_EXCEEDED = 'maxAmountOfFilesExceeded',
  FILE_SIZE_TOO_LARGE = 'fileSizeTooLarge',
  FILE_SIZE_TOO_SMALL = 'fileSizeTooSmall',
  FILE_TYPE_NOT_ACCEPTED = 'fileTypeNotAccepted',
}
export interface IFilePickerErrorContent extends IKeyValue {
  minAmountOfFilesNotReached: string
  maxAmountOfFilesExceeded: string
  fileSizeTooLarge: string
  fileSizeTooSmall: string
  fileTypeNotAccepted: string
}
