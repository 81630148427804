import { IOrsakTillUppsagningContent } from '@local/Common.types'
import {
  IUppdragKompletteraRiskApiModel,
  IValjUppdragContent,
} from '@local/Views/KompletteraRiskansokan/KompletteraRiskansokan.types'

import {
  IKompletteraRiskansokanFormValues,
  INyttUppdragSelectOption,
} from './KompletteraRiskansokanForm.types'

export const getUppdragKraverOverenskommelseBilaga = (
  formValues: IKompletteraRiskansokanFormValues
) =>
  (formValues.nyttUppdrag as INyttUppdragSelectOption)
    ?.kraverOverenskommelseBilaga || false

export const getUppdragSelectOptions = (
  uppdrag: IUppdragKompletteraRiskApiModel[],
  uppdragContent: IValjUppdragContent,
  orsakTillUppsagning: IOrsakTillUppsagningContent
): INyttUppdragSelectOption[] =>
  uppdrag?.map((uppdrag) => ({
    label: `
            ${uppdrag.namn}${uppdragContent.dropdownSeparator}
           ${orsakTillUppsagning[uppdrag.orsakTillUppsagning]}${
      uppdragContent.dropdownSeparator
    } 
           ${uppdrag.nummer}
`,
    value: uppdrag.id,
    // Store value so it can be read in yup schema
    kraverOverenskommelseBilaga: uppdrag.kraverOverenskommelseBilaga,
  }))
