import React, { useState } from 'react'
import Spinner from '@local/Components/Spinner'
import HTMLMapper from '@local/Components/HTMLMapper'
import Tooltip from '@local/Components/Tooltip/Tooltip'
import { Typography, Button } from '@mui/material'
import { IUppdragDetaljvyContent } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import useGetGroupedAnsokningar from '@local/Views/UppdragDetaljvy/useGetGroupedAnsokningar'
import { IEjInskickadeProps } from '@local/Views/UppdragDetaljvy/EjInskickade/EjInskickade.types'
import AnsokanCard from '@local/Views/UppdragDetaljvy/AnsokanCard/AnsokanCard'
import TaBortAnsokanModal from '@local/Views/UppdragDetaljvy/AnsokanCard/TaBortAnsokanModal/TaBortAnsokanModal'
import { useDeleteAnsokanMutation } from '@local/services/API/skapaAnsokanApi'
import { useIsBelowMediumScreens, usePickEpiContent } from '@local/Utils/Hooks'
import { isNil } from 'ramda'
import Alert from '@local/Components/Alert'

const EjInskickade = ({
  checkedState,
  handleCheckedOnChange,
}: IEjInskickadeProps): JSX.Element => {
  const {
    ejInskickade,
    tooltipEjInskickade,
    taBortModal,
    nagotGickFel,
    arbetsgivaransokanLinks: { skapaAnsokanUrl },
  } = usePickEpiContent<IUppdragDetaljvyContent>()

  const [taBortModalOpen, setTaBortModalOpen] = useState(false)
  const [currentAnsokanId, setCurrentAnsokanId] = useState<string>('')

  const [deleteAnsokan, { isError: deleteHasErrors }] =
    useDeleteAnsokanMutation()

  const {
    data: groupedAnsokningar,
    isLoading,
    isError,
  } = useGetGroupedAnsokningar()

  const handleTaBort = async (ansokanId: string) => {
    await deleteAnsokan(ansokanId)
      .unwrap()
      .then(() => !deleteHasErrors && setTaBortModalOpen(false))
      .then(() => {
        window.scroll({ behavior: 'smooth', top: 0 })
      })
  }
  const isBelowMediumScreens = useIsBelowMediumScreens()

  if (isLoading) {
    return <Spinner centered />
  }

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  return (
    <div aria-label="Ej inskickade ansökningar">
      <Tooltip
        variant="h5"
        heading={ejInskickade.heading}
        tooltipContent={<HTMLMapper body={tooltipEjInskickade.mainBody} />}
      />

      {groupedAnsokningar?.fullstandiga.length === 0 && (
        <Typography variant="body1">
          {ejInskickade.ansokningarSaknas}
        </Typography>
      )}

      {groupedAnsokningar?.fullstandiga.map((ansokan, index) => (
        <AnsokanCard
          key={ansokan.id}
          ansokan={ansokan}
          checked={checkedState[index]}
          handleCheckedOnChange={() => handleCheckedOnChange(index)}
          primaryButton={
            <Button
              href={`${skapaAnsokanUrl}${ansokan.id}/`}
              fullWidth={isBelowMediumScreens}
            >
              {ejInskickade.redigeraButtonLabel}
            </Button>
          }
          secondaryButton={
            isNil(ansokan.inskickadAv) && (
              <Button
                variant="outlined"
                fullWidth={isBelowMediumScreens}
                onClick={() => {
                  setCurrentAnsokanId(ansokan.id)
                  setTaBortModalOpen(true)
                }}
              >
                {taBortModal.confirmText}
              </Button>
            )
          }
        />
      ))}
      <TaBortAnsokanModal
        ansokanId={currentAnsokanId}
        taBortModalOpen={taBortModalOpen}
        setTaBortModalOpen={setTaBortModalOpen}
        handleTaBort={handleTaBort}
        isError={deleteHasErrors}
      />
    </div>
  )
}

export default EjInskickade
