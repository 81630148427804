import React, { useEffect, useCallback } from 'react'
import { isEmpty } from 'ramda'
import { Form, withFormik, useFormikContext } from 'formik'
import {
  ILaggTillMedarbetareFormProps,
  ILaggTillMedarbetareFormValues,
} from '@local/Views/LaggTillMedarbetare/LaggTillMedarbetare.types'
import {
  initialLaggTillMedarbetareValues,
  laggTillMedarbetareValidationSchema,
} from '@local/Views/LaggTillMedarbetare/LaggTillMedarbetare.schema'
import { useAppDispatch } from '@local/Store/configureStore'
import {
  setShouldGetPersonuppgifter,
  resetPersonuppgifterState,
} from '@local/Views/LaggTillMedarbetare/Personuppgifter/personuppgifterSlice'
import { Grid } from '@mui/material'
import { ICommonAnsokanValideringContent } from '@local/Common.types'

import Personnummer from './Personnummer/Personnummer'

const LaggTillMedarbetareForm = () => {
  const dispatch = useAppDispatch()
  const { touched, validateForm, isSubmitting } =
    useFormikContext<ILaggTillMedarbetareFormValues>()

  //Reset store on render to prevent previous values from being displayed when navigating back and forth
  useEffect(() => {
    dispatch(resetPersonuppgifterState())
    return () => {
      dispatch(resetPersonuppgifterState())
    }
  }, [dispatch])

  const submitLaggTillMedarbetareForm = useCallback(() => {
    void (async () => {
      const formErrors = await validateForm()

      if (isEmpty(formErrors) && touched.personnummer) {
        dispatch(setShouldGetPersonuppgifter(true))
      }
    })()
  }, [dispatch, validateForm, touched.personnummer])

  useEffect(() => {
    // Only submit once
    if (isSubmitting) {
      submitLaggTillMedarbetareForm()
    }
  }, [submitLaggTillMedarbetareForm, isSubmitting])

  return (
    <Form>
      <Grid container>
        <Grid item md={6} xs={12}>
          <Personnummer />
        </Grid>
      </Grid>
    </Form>
  )
}

const LaggTillMedarbetareFormik = withFormik<
  ILaggTillMedarbetareFormProps,
  ILaggTillMedarbetareFormValues
>({
  validateOnBlur: true,
  enableReinitialize: true,
  mapPropsToValues: () => initialLaggTillMedarbetareValues,
  handleSubmit: (_, { setSubmitting }) => {
    setSubmitting(false)
  },
  validationSchema: ({
    ansokanValidering,
  }: {
    ansokanValidering: ICommonAnsokanValideringContent
  }) => laggTillMedarbetareValidationSchema(ansokanValidering),
  displayName: 'LaggTillMedarbetareForm',
})(LaggTillMedarbetareForm)

export default LaggTillMedarbetareFormik
