import React from 'react'
import {
  AnsokanCardProps,
  ICardContainer,
} from '@local/Components/AnsokanCard/Types'
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from '@mui/material'
import { isNil } from 'ramda'
import Divider from '@local/Components/Divider'
import { useIsBelowMediumScreens } from '@local/Utils/Hooks'

export const CardContainer: React.FunctionComponent<
  React.PropsWithChildren<ICardContainer>
> = ({ href, isMobile, children, customLabel }) =>
  isNil(href) || isMobile ? (
    <>{children}</>
  ) : (
    <CardActionArea href={href} data-gtm-label={customLabel}>
      {children}
    </CardActionArea>
  )

const AnsokanCard: React.FunctionComponent<
  React.PropsWithChildren<AnsokanCardProps>
> = ({
  title,
  subtitle,
  action,
  actionMobile,
  href,
  buttonLinkText,
  children,
  actionAlign = 'flex-start',
  ...props
}): JSX.Element => {
  const isBelowMediumScreens = useIsBelowMediumScreens()

  return (
    <Card {...props}>
      <CardContainer
        href={href}
        isMobile={isBelowMediumScreens}
        customLabel={`Uppdragskort`}
      >
        <CardContent sx={{ padding: 3 }}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <Typography variant="h4">{title}</Typography>
              {subtitle && typeof subtitle === 'string' ? (
                <Typography variant="body1">{subtitle}</Typography>
              ) : (
                subtitle ?? null
              )}
            </Grid>
            {action && !isBelowMediumScreens && (
              <Grid
                item
                sx={{
                  display: 'flex',
                  alignItems: actionAlign,
                  justifyContent: 'flex-end',
                }}
              >
                {action}
              </Grid>
            )}
          </Grid>

          <Divider offset_xs={48} />

          {children}

          {!isNil(href) && isBelowMediumScreens && (
            <>
              <Divider offset_xs={48} />

              <Button variant="outlined" href={href} fullWidth>
                {buttonLinkText}
              </Button>
            </>
          )}
          {isBelowMediumScreens && actionMobile && (
            <>
              <Divider offset_xs={48} />
              {actionMobile}
            </>
          )}
        </CardContent>
      </CardContainer>
    </Card>
  )
}

export default AnsokanCard
