import React from 'react'
import { Provider } from 'react-redux'
import { AppShellRouter, KeyRoute } from '@local/Router'
import { store } from '@local/Store/configureStore'
import DinaUppdrag from '@local/Views/DinaUppdrag'
import LaggTillMedarbetare from '@local/Views/LaggTillMedarbetare'
import UppdragDetaljvy from '@local/Views/UppdragDetaljvy'
import KompletteraRiskansokan from '@local/Views/KompletteraRiskansokan'
import SkapaAnsokan from '@local/Views/SkapaAnsokan'
import Detalvy from '@local/Views/Detaljvy'
import { RedigeraUppdrag, SkapaUppdrag } from '@local/Views/UppdragFormWrapper'
import { createTheme, ThemeProvider } from '@mui/material'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { GTMTracker } from '@trr/gtm-tracking'
import { sv } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers'
import SkapaAnsokningar from '@local/Views/SkapaAnsokningar'
import { svSE as dataGridSvSe } from '@mui/x-data-grid-pro/locales'
import { svSE as datePickerSvSE } from '@mui/x-date-pickers/locales'

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => {
  const theme = createTheme(
    themeOptions,
    defaultLocale,
    dataGridSvSe,
    datePickerSvSE
  )

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
        <GTMTracker mfName="kundansokan">
          <Provider store={store}>
            <AppShellDataProvider value={appShellData}>
              <AppShellRouter currentKey={appShellData.currentKey}>
                <KeyRoute urlKey="dina-uppdrag">
                  <DinaUppdrag />
                </KeyRoute>
                <KeyRoute urlKey="lagg-till-medarbetare">
                  <LaggTillMedarbetare />
                </KeyRoute>
                <KeyRoute urlKey="skapa-ansokan">
                  <SkapaAnsokan />
                </KeyRoute>
                <KeyRoute urlKey="skapa-uppdrag">
                  <SkapaUppdrag />
                </KeyRoute>
                <KeyRoute urlKey="redigera-uppdrag">
                  <RedigeraUppdrag />
                </KeyRoute>
                <KeyRoute urlKey="uppdrag">
                  <UppdragDetaljvy />
                </KeyRoute>
                <KeyRoute urlKey="detaljvy">
                  <Detalvy />
                </KeyRoute>
                <KeyRoute urlKey="komplettera-riskansokan">
                  <KompletteraRiskansokan />
                </KeyRoute>
                <KeyRoute urlKey="skapa-ansokningar">
                  <SkapaAnsokningar />
                </KeyRoute>
              </AppShellRouter>
            </AppShellDataProvider>
          </Provider>
        </GTMTracker>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
