import React from 'react'
import { styled } from '@mui/material/styles'
import { IconButton, Tooltip as MuiTooltip, Typography } from '@mui/material'
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'
import Gutter from '@local/Components/Gutter'
import { ITooltip } from '@local/Components/Tooltip/Types'

const smallHeadlines = ['body1', 'body2', 'subtitle1', 'subtitle2']

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip
    {...props}
    classes={{ popper: className }}
    enterTouchDelay={0}
    leaveDelay={100}
    leaveTouchDelay={999999999}
    PopperProps={{ disablePortal: true }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.neutral?.main,
    boxShadow: theme.shadows[4],
    padding: theme.spacing(2),
  },
}))

const TooltipContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  '& > *:first-of-type': {
    marginRight: theme.spacing(1),
  },
  '& button': {
    color: theme.palette.neutral?.main,
    paddingTop: 0,
    paddingBottom: 0,
  },
  '& h6:not([class*="subtitle1"]):not([class*="subtitle2"]) + button': {
    paddingTop: theme.spacing(0.25),
  },
  '& h5 + button': {
    paddingTop: theme.spacing(0.5),
  },
  '& h4 + button': {
    paddingTop: theme.spacing(1),
  },
  '& h3 + button': {
    paddingTop: theme.spacing(1.25),
  },
  '& h2 + button': {
    paddingTop: theme.spacing(2),
  },
  '& h1 + button': {
    paddingTop: theme.spacing(3),
  },
  '& h6[class*="subtitle2"]': {
    paddingTop: theme.spacing(0.25),
  },
}))

const Tooltip = ({
  heading,
  variant = 'h6',
  tooltipContent,
}: ITooltip): JSX.Element => (
  <>
    <TooltipContainer>
      <Typography variant={variant}>{heading}</Typography>

      <LightTooltip title={tooltipContent}>
        <IconButton>
          <InfoIcon />
        </IconButton>
      </LightTooltip>
    </TooltipContainer>
    <Gutter offset_xs={smallHeadlines.includes(variant) ? 8 : 16} />
  </>
)

export default Tooltip
