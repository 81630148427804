import React from 'react'
import Gutter from '@local/Components/Gutter'
import { FieldArray, useFormikContext } from 'formik'
import { IUppdragFormValues } from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.types'
import FackligKontaktIntro from '@local/Views/UppdragFormWrapper/UppdragForm/FackligKontakt/FackligKontaktIntro/FackligKontaktIntro'
import { fackligaKontakerIsAllowedForOrsakskod } from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragFormValuesMapper'
import FackligKontaktInfoBox from '@local/Views/UppdragFormWrapper/UppdragForm/FackligKontakt/FackligKontaktInfoBox'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import {
  useFackligKontaktHasErrors,
  useShouldShowFackligKontaktSaknas,
} from '@local/Views/UppdragFormWrapper/UppdragForm/hooks'

import FackligKontaktAdd from './FackligKontaktAdd'
import FackligKontaktList from './FackligKontaktList'
import FackligKontaktSaknas from './FackligKontaktSaknas'

const FackligKontakt = (): JSX.Element => {
  const { errors, values } = useFormikContext<IUppdragFormValues>()
  const shouldShowFackligKontaktSaknas = useShouldShowFackligKontaktSaknas()
  const fackligKontaktHasErrors = useFackligKontaktHasErrors()
  const fackligaKontakterIsAllowed =
    fackligaKontakerIsAllowedForOrsakskod(values)

  return (
    <>
      {fackligaKontakterIsAllowed && (
        <>
          <Gutter offset_xs={32} />

          <FackligKontaktIntro />

          <FackligKontaktInfoBox />

          <FieldArray name="fackligaKontakter">
            {(arrayHelpers) => (
              <>
                <div aria-label="Lista av fackliga kontakter">
                  <FackligKontaktList arrayHelpers={arrayHelpers} />
                </div>

                <FackligKontaktAdd arrayHelpers={arrayHelpers} />
              </>
            )}
          </FieldArray>

          {fackligKontaktHasErrors && (
            <>
              <Gutter offset_xs={16} />
              <FormErrorMessage>
                {String(errors.fackligaKontakter)}
              </FormErrorMessage>

              {shouldShowFackligKontaktSaknas && <Gutter offset_xs={32} />}
            </>
          )}

          <FackligKontaktSaknas />
        </>
      )}
    </>
  )
}

export default FackligKontakt
