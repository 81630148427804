import React from 'react'
import { Grid2 as Grid, Typography } from '@mui/material'
import {
  useIsMediumScreensOrAbove,
  usePickEpiContent,
} from '@local/Utils/hooks'
import { ISkapaAnsokningarContent } from '@local/Views/SkapaAnsokningar/types/types'
import {
  getFackligaKontakterFlatList,
  getFackligaKontakterNames,
} from '@local/Utils/helpers/FackligKontakt.helpers'
import { FackligKontaktOrsakerTillUppsagning } from '@local/Common.types'
import CompressedList from '@local/Components/CompressedList'
import { useGetUppdrag } from '@local/Utils/hooks/useGetUppdrag'

const Uppdragsinfo = (): JSX.Element => {
  const isMediumOrAbove = useIsMediumScreensOrAbove()

  const { data: uppdrag, isLoading } = useGetUppdrag()

  const {
    sammanfattning: { uppdragsinfo, orsakTillUppsagning },
  } = usePickEpiContent<ISkapaAnsokningarContent>()

  if (isLoading) {
    return null
  }

  const fackligaKontakterFlatList = getFackligaKontakterFlatList(
    uppdrag.fackligaKontakter
  )

  const flatAdministratorerList = uppdrag.administratorer.map(
    (admin) => admin.namn
  )

  return (
    <Grid
      container
      sx={{ justifyContent: isMediumOrAbove ? 'flex-end' : 'flex-start' }}
      columnSpacing={4}
      rowGap={2}
    >
      <Grid>
        <Typography variant="subtitle1">
          {uppdragsinfo.uppsagningsorsak}
        </Typography>
        <Typography variant="body2">
          {orsakTillUppsagning[uppdrag.orsakTillUppsagning]}
        </Typography>
      </Grid>

      {!uppdrag.fackligRepSaknas &&
        Object.values<string>(FackligKontaktOrsakerTillUppsagning).includes(
          uppdrag.orsakTillUppsagning
        ) && (
          <Grid>
            <Typography variant="subtitle1">
              {uppdragsinfo.fackligKontakt}
            </Typography>
            <Typography variant="body2">
              {
                <CompressedList
                  list={getFackligaKontakterNames(fackligaKontakterFlatList)}
                  maxNumberOfItems={1}
                />
              }
            </Typography>
          </Grid>
        )}

      <Grid>
        <Typography variant="subtitle1">
          {uppdragsinfo.administratorer}
        </Typography>
        <Typography variant="body2">
          {
            <CompressedList
              list={flatAdministratorerList}
              maxNumberOfItems={1}
            />
          }
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Uppdragsinfo
