import {
  ICreateAnsokanPayloadModel,
  IGetDinaUppdragProps,
  IPersonuppgifterApiModel,
  IPersonuppgifterPayloadModel,
  IUppdragApiModel,
  IUppdragPayloadModel,
} from '@local/Views/DinaUppdrag/DinaUppdrag.types'
import { baseApi } from '@local/services/API/baseApi'
import {
  IAnsokanUppdragApiModel,
  ISkickaInAnsokanApiModel,
} from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import { FullTagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import {
  IUppdragFormApiModel,
  IUppdragPatchApiModel,
} from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.types'
import { IFackforbundApiModel } from '@local/Views/UppdragFormWrapper/UppdragFormWrapper.types'

export const uppdragApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getPersonuppgifter: builder.query<
      IPersonuppgifterApiModel,
      IPersonuppgifterPayloadModel
    >({
      query: ({ uppdragsId, personnummer }) =>
        `ansokan/uppdrag/${uppdragsId}/personuppgifter/${personnummer}`,
      keepUnusedDataFor: 60 * 60,
      providesTags: ['personuppgifter'],
    }),

    createUppdrag: builder.mutation<void, IUppdragFormApiModel>({
      query: (apiModel) => ({
        url: 'ansokan/uppdrag',
        method: 'POST',
        body: apiModel,
      }),
      invalidatesTags: [{ type: 'uppdrag', id: 'LIST' }],
    }),
    getDinaUppdrag: builder.query<IUppdragApiModel[], IGetDinaUppdragProps>({
      query: ({ userSub, organisationsnummer }) =>
        `kund/${userSub}/organisation/${organisationsnummer}/v2/uppdrag`,
      providesTags: [{ type: 'uppdrag', id: 'LIST' }],
    }),
    getUppdrag: builder.query<IUppdragApiModel, IUppdragPayloadModel>({
      query: ({ uppdragId, userSub }) =>
        `kund/${userSub}/v2/uppdrag/${uppdragId}`,
      providesTags: (_response, _error, payload) => [
        { type: 'uppdrag', id: payload.uppdragId },
      ],
    }),
    patchUppdrag: builder.mutation<void, IUppdragPatchApiModel>({
      query: ({ uppdrag, values }) => ({
        url: `/ansokan/uppdrag/${uppdrag.id}`,
        method: 'PATCH',
        body: values,
      }),
      invalidatesTags: () => ['uppdrag', { type: 'uppdrag', id: 'LIST' }],
    }),
    getFackforbund: builder.query<IFackforbundApiModel[], void>({
      query: () => 'ansokan/uppdrag/fackforbund',
    }),
    getGroupedAnsokningar: builder.query<IAnsokanUppdragApiModel, string>({
      query: (uppdragsId) => `uppdrag/${uppdragsId}/ansokan`,
      providesTags: [{ type: 'ansokan', id: 'GROUPED_LIST' }],
    }),
    postCreateAnsokan: builder.mutation<string, ICreateAnsokanPayloadModel>({
      query: ({ uppdragsId, personData }) => ({
        url: `uppdrag/${uppdragsId}/ansokan`,
        method: 'POST',
        body: personData,
      }),
      invalidatesTags: (id) => [
        'personuppgifter',
        'uppdrag',
        { type: 'ansokan', id: 'GROUPED_LIST' },
        { type: 'ansokan', id },
      ],
    }),
    postSkickaInAnsokan: builder.mutation<void, ISkickaInAnsokanApiModel[]>({
      query: (ansokan) => ({
        url: `ansokan/skickain/arbetsgivare`,
        method: 'POST',
        body: ansokan,
      }),
      invalidatesTags: (_response, _error, ansokan) => [
        'personuppgifter',
        'uppdrag',
        { type: 'ansokan', id: 'GROUPED_LIST' },
        { type: 'ansokan', id: 'LIST' },
        ...ansokan.map(
          (ansokan) =>
            ({
              type: 'ansokan',
              id: ansokan.ansokanId,
            }) as FullTagDescription<'ansokan'>
        ),
      ],
    }),
  }),
})

export const {
  useCreateUppdragMutation,
  useGetDinaUppdragQuery,
  useGetUppdragQuery,
  useGetPersonuppgifterQuery,
  usePostCreateAnsokanMutation,
  usePatchUppdragMutation,
  useGetFackforbundQuery,
  useGetGroupedAnsokningarQuery,
  usePostSkickaInAnsokanMutation,
} = uppdragApi
