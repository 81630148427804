import { baseApi } from '@local/services/API/baseApi'
import {
  IAdminApiModel,
  IForetagApiModel,
} from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.types'

export const foretagApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getForetagByOrganisationsnummer: builder.query<IForetagApiModel[], string>({
      query: (organisationsnummer) =>
        `foretag/${organisationsnummer}?worksituation=unemployed`,
    }),
    getAdminsByOrganisationsnummer: builder.query<IAdminApiModel[], string>({
      query: (organisationsnummer) =>
        `foretag/${organisationsnummer}/administratorer`,
    }),
  }),
})

export const {
  useGetForetagByOrganisationsnummerQuery,
  useGetAdminsByOrganisationsnummerQuery,
} = foretagApi
