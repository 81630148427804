import React from 'react'
import { ISkapaAnsokanContent } from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'
import { usePickEpiContent } from '@local/Utils/hooks'
import Gutter from '@local/Components/Gutter'
import { Typography } from '@mui/material'

import Arbetstimmar from './Arbetstimmar'
import Anstallningsgrad from './Anstallningsgrad'
import Sjukersattningsgrad from './Sjukersattningsgrad'
import Uppsagningsgrad from './Uppsagningsgrad'

const Omfattning = (): JSX.Element => {
  const {
    anstallning: { omfattning: omfattningContent },
  } = usePickEpiContent<ISkapaAnsokanContent>()

  return (
    <div>
      <Typography gutterBottom variant="h5">
        {omfattningContent.heading}
      </Typography>

      <Arbetstimmar />

      <Gutter offset_xs={32} />

      <Anstallningsgrad />

      <Gutter offset_xs={32} />

      <Sjukersattningsgrad />

      <Gutter offset_xs={32} />

      <Uppsagningsgrad />
    </div>
  )
}

export default Omfattning
