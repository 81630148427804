import React from 'react'
import {
  RadioChoice,
  FilAnsokanKeys,
  ISelectOptionFormikWrapper,
  ISelectOption,
  IKeyValue,
  AnsokanKeys,
} from '@local/Common.types'
import NumberInput from '@local/Components/NumberInput'
import {
  formatCurrencySEK,
  formatDecimal,
  formatPercentageNumber,
  isStringAlsoNumber,
} from '@local/Utils/Helpers'
import { toTrrDateOrDefault, trrFormat } from '@local/Utils/Helpers/formatDate'
import {
  IColumnHeadersContent,
  IFilAnsokanFormValues,
  IFilAnsokanRowFormik,
  IFilansokanError,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/types/types'
import { ISkapaAnsokningarFormValues } from '@local/Views/SkapaAnsokningar/types/types'
import {
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro'
import { isValid } from 'date-fns'
import { FormikErrors, useFormikContext } from 'formik'
import { isNil } from 'ramda'
import TextInput from '@local/Components/TextInput'
import DateInput from '@local/Components/DateInput'
import SelectInput from '@local/Components/SelectInput'
import {
  IBaseDateInputCell,
  IBaseDropdownInputCell,
  IBaseNumberInputCell,
  IBaseTextInputCell,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/helpers/types/helpersTypes'
import { Box, Typography, useTheme } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import LockIcon from '@mui/icons-material/Lock'
import { IFilAnsokanRow } from '@local/services/API/filansokan/types'

export interface ICommonSettings {
  field: string
  headerName: string
  description: string
}

export const baseColumnSettings: Partial<GridColDef> = {
  editable: true,
  minWidth: 250,
  align: 'left',
}

export const getNextErrorIndex = (
  currentErrorIndex: number,
  errors: IFilansokanError[]
) => {
  if (currentErrorIndex < errors.length - 1) {
    return currentErrorIndex + 1
  } else {
    return 0
  }
}

export const getPreviousErrorIndex = (
  currentErrorIndex: number,
  errors: IFilansokanError[]
) => {
  if (currentErrorIndex > 0) {
    return currentErrorIndex - 1
  } else {
    return errors.length - 1
  }
}

export const getFilansokanKeyValue = (
  row: IFilAnsokanRow,
  filansokanKey: FilAnsokanKeys
): string =>
  row.columns.find((column) => column.trrKey === filansokanKey)?.value

export const getFilansokanKeyValueDate = (
  row: IFilAnsokanRow,
  filansokanKey: FilAnsokanKeys
): Date => {
  const date = getFilansokanKeyValue(row, filansokanKey)

  if (!isNil(date) && isValid(new Date(date))) {
    return toTrrDateOrDefault(date)
  } else {
    return null
  }
}

export const getFilansokanKeyValueNumber = (
  row: IFilAnsokanRow,
  filansokanKey: FilAnsokanKeys
): number => {
  const value = getFilansokanKeyValue(row, filansokanKey)

  if (!isNil(value) && isStringAlsoNumber(value)) {
    return Number(value)
  } else {
    return 0
  }
}

export const getFilansokanKeyValuePercentageNumber = (
  row: IFilAnsokanRow,
  filansokanKey: FilAnsokanKeys
): number => {
  const value = getFilansokanKeyValue(row, filansokanKey)

  if (!isNil(value) && isStringAlsoNumber(value)) {
    return Number(value)
  } else {
    return null
  }
}

export const getFilansokanKeyValueSjukersattningOption = (
  row: IFilAnsokanRow,
  filansokanKey: FilAnsokanKeys
): ISelectOption => {
  const value = getFilansokanKeyValue(row, filansokanKey)

  if (
    !isNil(value) &&
    isStringAlsoNumber(value) &&
    [0, 25, 50, 75, 100].includes(Number(value))
  ) {
    return { label: String(value), value: Number(value) }
  } else {
    return { label: '0', value: 0 }
  }
}

export const checkForBooleanValue = (
  value: string | boolean,
  shouldReturnSelectOption: boolean
): RadioChoice | ISelectOption => {
  if (isNil(value)) {
    return null
  } else if ([true, 'true'].includes(value)) {
    return shouldReturnSelectOption
      ? { label: 'Ja', value: RadioChoice.Ja }
      : RadioChoice.Ja
  } else if ([false, 'false'].includes(value)) {
    return shouldReturnSelectOption
      ? { label: 'Nej', value: RadioChoice.Nej }
      : RadioChoice.Nej
  } else {
    return null
  }
}

export const getFilansokanKeyValueSelectOption = (
  row: IFilAnsokanRow,
  filansokanKey: FilAnsokanKeys
): ISelectOption => {
  const value = getFilansokanKeyValue(row, filansokanKey)

  return checkForBooleanValue(value, true) as ISelectOption
}

export const getFilansokanKeyValueRadioChoice = (
  row: IFilAnsokanRow,
  filansokanKey: FilAnsokanKeys
): RadioChoice => {
  const value = getFilansokanKeyValue(row, filansokanKey)

  return checkForBooleanValue(value, false) as RadioChoice
}

export const getCommonColumnSettings = (
  ansokanKey: keyof Omit<
    IFilAnsokanFormValues,
    AnsokanKeys.HasMedarbetareAnsokanAtCurrentUppdrag
  >,
  columnHeadersContent: IColumnHeadersContent,
  values: ISkapaAnsokningarFormValues,
  errors: FormikErrors<ISkapaAnsokningarFormValues>,
  isEditable: boolean = true
) => ({
  field: ansokanKey,
  headerName: columnHeadersContent[ansokanKey],
  description: columnHeadersContent[ansokanKey],
  cellClassName: (params: GridCellParams) => {
    const currentRow: IFilAnsokanRowFormik = params.row as IFilAnsokanRowFormik

    const isError =
      errors.filansokningar?.length > currentRow.index &&
      !isNil(
        (
          errors.filansokningar[
            currentRow.index
          ] as FormikErrors<IFilAnsokanRowFormik>
        )?.[ansokanKey]
      )
    return isError ? 'is-error' : null
  },
  renderHeader: (params: GridColumnHeaderParams) => {
    let oneOrMoreErrorsExistsInColumn = false

    values.filansokningar.map((_, index) => {
      if (errors.filansokningar?.length > index) {
        if (
          typeof (errors?.filansokningar?.[index] as FormikErrors<IKeyValue>)?.[
            params.field
          ] === 'string'
        ) {
          oneOrMoreErrorsExistsInColumn = true
        }
      }
    })

    return (
      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
          {columnHeadersContent[ansokanKey]}
        </Typography>
        <Box sx={{ display: 'flex', minWidth: '32px' }}>
          {oneOrMoreErrorsExistsInColumn && (
            <ErrorIcon sx={{ marginLeft: '8px' }} color="error" />
          )}
          {!isEditable && <LockIcon sx={{ marginLeft: '8px' }} />}
        </Box>
      </Box>
    )
  },
})

export const getCommonCellSettings = (
  values: ISkapaAnsokningarFormValues,
  ansokanKey: keyof IFilAnsokanFormValues
): Partial<GridColDef> => ({
  renderCell: (params: GridRenderCellParams) => {
    const currentRow: IFilAnsokanRowFormik = params.row as IFilAnsokanRowFormik

    const currentValue = values.filansokningar[currentRow.index]?.[ansokanKey]

    if (!isNil(currentValue)) {
      return <Typography variant="body1">{String(currentValue)}</Typography>
    }
  },
})

export const getCommonCellDateSettings = (
  values: ISkapaAnsokningarFormValues,
  ansokanKey: keyof IFilAnsokanFormValues
): Partial<GridColDef> => ({
  type: 'date',
  renderCell: (params) => {
    const currentRow: IFilAnsokanRowFormik = params.row as IFilAnsokanRowFormik

    const currentValue = values.filansokningar[currentRow.index]?.[ansokanKey]

    if (isValid(currentValue)) {
      return (
        <Typography variant="body1">
          {trrFormat(currentValue as string)}
        </Typography>
      )
    }

    return null
  },
})

export const getCommonCellCurrencySettings = (
  values: ISkapaAnsokningarFormValues,
  ansokanKey: keyof IFilAnsokanFormValues
): Partial<GridColDef> => ({
  sortComparator: (option1: number, option2: number) => option1 - option2,
  type: 'number',
  renderCell: (params: GridRenderCellParams) => {
    const currentRow: IFilAnsokanRowFormik = params.row as IFilAnsokanRowFormik

    const currentValue = values.filansokningar[currentRow.index]?.[ansokanKey]

    if (!isNil(currentValue)) {
      return (
        <Typography variant="body1">
          {formatCurrencySEK(currentValue as number)}
        </Typography>
      )
    } else {
      return <Typography variant="body1">{formatCurrencySEK(0)}</Typography>
    }
  },
})

export const getCommonCellDecimalSettings = (
  values: ISkapaAnsokningarFormValues,
  ansokanKey: keyof IFilAnsokanFormValues
): Partial<GridColDef> => ({
  sortComparator: (option1: number, option2: number) => option1 - option2,
  type: 'number',
  renderCell: (params: GridRenderCellParams) => {
    const currentRow: IFilAnsokanRowFormik = params.row as IFilAnsokanRowFormik

    const currentValue = values.filansokningar[currentRow.index]?.[ansokanKey]

    if (!isNil(currentValue)) {
      return (
        <Typography variant="body1">
          {formatDecimal(Number(currentValue))}
        </Typography>
      )
    } else {
      return null
    }
  },
})

export const getCommonCellPercentageSettings = (
  values: ISkapaAnsokningarFormValues,
  ansokanKey: keyof IFilAnsokanFormValues
): Partial<GridColDef> => ({
  sortComparator: (option1: number, option2: number) => option1 - option2,
  type: 'number',
  renderCell: (params: GridRenderCellParams) => {
    const currentRow: IFilAnsokanRowFormik = params.row as IFilAnsokanRowFormik

    const currentValue = values.filansokningar[currentRow.index]?.[ansokanKey]

    if (!isNil(currentValue)) {
      return (
        <Typography variant="body1">
          {formatPercentageNumber(currentValue as number)}
        </Typography>
      )
    }
  },
})

export const getCommonCellRadioChoiceSettings = (
  values: ISkapaAnsokningarFormValues,
  ansokanKey: keyof IFilAnsokanFormValues
): Partial<GridColDef> => ({
  type: 'singleSelect',
  renderCell: (params: GridRenderCellParams) => {
    const currentRow: IFilAnsokanRowFormik = params.row as IFilAnsokanRowFormik

    const currentOption = values.filansokningar[currentRow.index]?.[
      ansokanKey
    ] as ISelectOption

    if (!isNil(currentOption)) {
      return (
        <Typography variant="body1">
          {currentOption.value === RadioChoice.Ja ? 'Ja' : 'Nej'}
        </Typography>
      )
    }
  },
})

export const BaseNumberInputCell = ({
  ansokanKey,
  currentRowIndex,
}: IBaseNumberInputCell) => {
  const formikContext = useFormikContext<ISkapaAnsokningarFormValues>()

  return (
    <NumberInput
      formikContext={formikContext}
      name={`filansokningar.${currentRowIndex}.${ansokanKey}`}
      value={
        formikContext.values.filansokningar[currentRowIndex]?.[
          ansokanKey
        ] as number
      }
      handleFocus={(e) => e.target.select()}
    />
  )
}

const MemoizedBaseNumberInputCell = React.memo(BaseNumberInputCell)

export const renderBaseNumberInput = (params: IBaseNumberInputCell) => (
  <MemoizedBaseNumberInputCell {...params} />
)

export const BaseTextInputCell = ({
  ansokanKey,
  inputMode,
  withTrim = false,
  currentRowIndex,
}: IBaseTextInputCell) => {
  const formikContext = useFormikContext<ISkapaAnsokningarFormValues>()

  return (
    <TextInput
      withTrim={withTrim}
      formikContext={formikContext}
      name={`filansokningar.${currentRowIndex}.${ansokanKey}`}
      value={
        formikContext.values.filansokningar[currentRowIndex]?.[
          ansokanKey
        ] as string
      }
      inputMode={inputMode}
      handleFocus={(e) => e.target.select()}
    />
  )
}

const MemoizedBaseTextInputCell = React.memo(BaseTextInputCell)

export const renderBaseTextInput = (params: IBaseTextInputCell) => (
  <MemoizedBaseTextInputCell {...params} />
)

export const BaseDateInputCell = ({
  ansokanKey,
  currentRowIndex,
  minDate,
  maxDate,
}: IBaseDateInputCell) => {
  const theme = useTheme()
  const formikContext = useFormikContext<ISkapaAnsokningarFormValues>()

  return (
    <DateInput
      formikContext={formikContext}
      formikFieldName={`filansokningar.${currentRowIndex}.${ansokanKey}`}
      value={
        formikContext.values.filansokningar[currentRowIndex]?.[
          ansokanKey
        ] as Date
      }
      minDate={minDate}
      maxDate={maxDate}
      noGutter
      handleFocus={(e) => e.target.select()}
      desktopModeMediaQuery={theme.breakpoints.up('md')}
    />
  )
}

const MemoizedBaseDateInputCell = React.memo(BaseDateInputCell)

export const renderBaseDateInput = (params: IBaseDateInputCell) => (
  <MemoizedBaseDateInputCell {...params} />
)

export const BaseDropdownInputCell = ({
  ansokanKey,
  currentRowIndex,
  selectOptions,
}: IBaseDropdownInputCell) => {
  const formikContext = useFormikContext<ISkapaAnsokningarFormValues>()

  return (
    <SelectInput
      formikContext={formikContext}
      name={`filansokningar.${currentRowIndex}.${ansokanKey}`}
      selectOptions={selectOptions}
      value={
        formikContext.values.filansokningar[currentRowIndex]?.[
          ansokanKey
        ] as ISelectOptionFormikWrapper
      }
    />
  )
}

const MemoizedBaseDropdownInputCell = React.memo(BaseDropdownInputCell)

export const renderBaseDropdownInput = (params: IBaseDropdownInputCell) => (
  <MemoizedBaseDropdownInputCell {...params} />
)
