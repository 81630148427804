import React from 'react'
import Divider from '@local/Components/Divider'
import { Typography, Grid } from '@mui/material'
import { useIsBelowMediumScreens, usePickEpiContent } from '@local/Utils/Hooks'
import { IDinaUppdragContent } from '@local/Views/DinaUppdrag/DinaUppdrag.types'
import { formatIdentityNumber } from '@local/Utils/Helpers'
import useGetDinaUppdrag from '@local/Views/DinaUppdrag/useGetDinaUppdrag'
import { useUser } from '@trr/app-shell-data'
import Alert from '@local/Components/Alert'

const Header = (): JSX.Element => {
  const isBelowMediumScreens = useIsBelowMediumScreens()
  const { nagotGickFel, header } = usePickEpiContent<IDinaUppdragContent>()

  const { kundProperties } = useUser()
  const { data: dinaUppdrag, isError } = useGetDinaUppdrag()

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }
  return (
    <header>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          item
          sx={{
            wordWrap: 'break-word',
            width: 'auto',
          }}
        >
          <Typography variant="h5">{kundProperties?.foretag?.name}</Typography>
          <Typography variant="body1">
            {kundProperties?.foretag?.organizationNumber &&
              formatIdentityNumber(kundProperties?.foretag?.organizationNumber)}
          </Typography>
        </Grid>
        {dinaUppdrag && (
          <Grid item display={'flex'} alignItems="center">
            <Typography variant="body1" mr={1}>
              {header.heading}
            </Typography>
            <Typography
              variant={isBelowMediumScreens ? 'h6' : 'h4'}
              aria-label="Antal uppdrag"
            >
              {dinaUppdrag.length}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Divider offset_xs={32} />
    </header>
  )
}

export default Header
