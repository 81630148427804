import { useEffect, useRef, useState } from 'react'
import { UUIDRegex, matchStringByRegex } from '@local/Utils/regexes'
import { useGetFilAnsokanQuery } from '@local/services/API/filAnsokanApi'

const useCheckFileTimestamp = () => {
  const uppdragsId = matchStringByRegex(location.pathname, UUIDRegex)

  const { data: filansokan } = useGetFilAnsokanQuery(uppdragsId)
  const [
    arbitraryTimeHasPassedSinceUpload,
    setArbitraryTimeHasPassedSinceUpload,
  ] = useState<boolean>(null)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }

    const uploadedAt = new Date(filansokan?.uploadedAt)
    const currentTime = Date.now()
    const timeDifference = 30000 - (currentTime - uploadedAt.getTime())

    if (timeDifference <= 0) {
      setArbitraryTimeHasPassedSinceUpload(true)
    } else {
      setArbitraryTimeHasPassedSinceUpload(false)
      timer.current = setTimeout(() => {
        setArbitraryTimeHasPassedSinceUpload(true)
      }, timeDifference)
    }
  }, [filansokan])

  return { arbitraryTimeHasPassedSinceUpload }
}

export default useCheckFileTimestamp
