import {
  Beslut,
  IArbetsgivaransokanLinks,
  IDateTimestring,
  IEditorialBlock,
  INagotGickFelContent,
  IPatchGickFel,
  IVarningLamnaSidan,
  Status,
  TypAvSamband,
} from '@local/Common.types'
import { IPersonuppgifterApiModel } from '@local/Views/DinaUppdrag/DinaUppdrag.types'
import {
  IAnstallningApiModel,
  IAnstallningContent,
} from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Anstallning/Anstallning.types'
import {
  ILonContent,
  ILonApiModel,
} from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Lon/Lon.types'
import {
  IMedarbetareApiModel,
  IMedarbetareContent,
} from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Medarbetare/Medarbetare.types'

export interface ISambandsanstallningApiModel {
  agarandel: number
  anstallningsdatum: IDateTimestring
  foretagsnamn: string
  sistaAnstallningsdatum: IDateTimestring
  typAvSamband: TypAvSamband
}

export type IPatchValuesTypes =
  | IAnstallningApiModel
  | IMedarbetareApiModel
  | ILonApiModel
  | ISambandsanstallningApiModel[]

export interface ISkapaAnsokanContent {
  tabbar: ITabbarContent
  medarbetare: IMedarbetareContent
  anstallning: IAnstallningContent
  lon: ILonContent
  sidfot: ISidfotContent
  varningLamnaSidan: IVarningLamnaSidan
  varningInskickadAnsokan: IVarningInskickadAnsokanContent
  ejRedigerbar: IEditorialBlock
  nagotGickFel: INagotGickFelContent
  patchGickFel: IPatchGickFel
  arbetsgivaransokanLinks: IArbetsgivaransokanLinks
}

export interface ITabbarContent {
  medarbetare: string
  anstallning: string
  lon: string
}

export interface ISidfotContent {
  avbryt: string
  nastaSteg: string
  foregaende: string
  slutfor: string
  felmeddelande: string
}

export interface IVarningContent {
  varningsmeddelande: string
}

export interface IVarningInskickadAnsokanContent {
  heading: string
}

// This model is for response from ansokan API
export interface IAnsokan {
  ansokanState: AnsokanState
  anstallningsuppgifter: IAnstallningsuppgifter
  beslutAge: Beslut
  beslutRadgivning: Beslut
  hasAvgangsvederlag: boolean
  hasSjukersattningsgrad: boolean
  id: string
  inskickadAv: string
  isRedigerbar: boolean
  isRisk: boolean
  klientId: string
  kraverOverenskommelseBilaga: boolean
  loneuppgifter: ILoneUppgifter
  personuppgifter: IPersonuppgifter
  sambandsanstallningar: ISambandsanstallningApiModel[]
  status: Status
  type: AnsokanType
  uppdragId: string
}

export interface IPersonuppgifter
  extends Omit<IPersonuppgifterApiModel, 'ansokanId' | 'medarbetareState'> {
  epostadress: string
}

export interface ILoneUppgifter extends ILonApiModel {
  sjukersattningsgrad: number
}

export interface IAnstallningsuppgifter
  extends Omit<IAnstallningApiModel, 'sjukersattningsgrad'> {
  agarandel: number
  orsakTillUppsagning: string
  isTjansteman: boolean
  isVdEllerUndantagsgrupp: boolean
}

export enum AnsokanType {
  omstallningTillsvidareanstallning = 'omstallningtillsvidareanstallning',
  omstallningTillsvidareanstallningRisk = 'omstallningtillsvidareanstallningrisk',
  omstallningTidsbegransadAnstallning = 'omstallningtidsbegransadanstallning',
}

export enum AnsokanState {
  Inskickad = 'inskickad',
  Utkast = 'utkast',
  Fullstandig = 'fullstandig',
}
