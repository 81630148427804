import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik'
import { isEmpty, isNil } from 'ramda'
import {
  fackligKontaktListItemHasErrors,
  getTouchedFackligKontaktList,
} from '@local/Utils/helpers/FackligKontakt.helpers'
import { usePickEpiContent } from '@local/Utils/hooks'
import {
  IFackligKontaktListItemProps,
  IUppdragFormValues,
} from '@local/Views/UppdragFormWrapper/UppdragForm/UppdragForm.types'
import { IUppdragFormWrapperContent } from '@local/Views/UppdragFormWrapper/UppdragFormWrapper.types'
import FackligKontaktUppgifter from '@local/Views/UppdragFormWrapper/UppdragForm/FackligKontakt/FackligKontaktList/FackligKontaktListItem/FackligKontaktUppgifter'
import { selectRemovableCard } from '@local/Components/RemovableCard/removableCardSlice'
import RemovableCard from '@local/Components/RemovableCard/RemovableCard'
import { IHeaderContent } from '@local/Components/RemovableCard/Header/types'
import { ISelectOption } from '@local/Common.types'

const FackligKontaktListItem = ({
  kontakt,
  index,
  arrayHelpers,
}: IFackligKontaktListItemProps): JSX.Element => {
  const { fackligKontaktListItem } =
    usePickEpiContent<IUppdragFormWrapperContent>()

  const { validateForm, setTouched, touched, errors } =
    useFormikContext<IUppdragFormValues>()

  const { shouldExpandCard, shouldScrollToCard } =
    useSelector(selectRemovableCard)

  const [isExpanded, setIsExpanded] = useState(shouldExpandCard)
  const [shouldScroll, setShouldScroll] = useState(shouldScrollToCard)

  const cardHasErrors = fackligKontaktListItemHasErrors(index, touched, errors)

  const toggleIsExpanded = useCallback(() => {
    setIsExpanded(!isExpanded)
  }, [isExpanded])

  const headerContent: IHeaderContent = {
    title:
      kontakt.fornamn || kontakt.efternamn
        ? `${kontakt.fornamn} ${kontakt.efternamn}`
        : null,
    subtitle: (kontakt.fackforbund as ISelectOption)?.label,
    titlePlaceholder: fackligKontaktListItem.header.titlePlaceholder,
    cardHasErrors,
  }

  const handleLaggTill = useCallback(() => {
    void (async () => {
      const error = await validateForm()

      if (
        isEmpty(error) ||
        isNil(error.fackligaKontakter) ||
        isNil(error.fackligaKontakter[index])
      ) {
        toggleIsExpanded()
        setTouched({}).catch((err) => console.log(err))
        setShouldScroll(true)
      } else {
        setTouched(
          setNestedObjectValues<FormikTouched<IUppdragFormValues>>(
            {
              ...touched,
              fackligaKontakter: getTouchedFackligKontaktList(
                index,
                touched,
                error
              ),
            },
            true
          )
        ).catch((err) => console.log(err))
      }
    })()
  }, [validateForm, toggleIsExpanded, setTouched, index, touched])

  const handleRemove = useCallback(() => {
    arrayHelpers.remove(index)

    setTimeout(() => {
      window.scroll({ behavior: 'smooth', top: 0 })
    }, 0)
  }, [arrayHelpers, index])

  return (
    <RemovableCard
      attributes={{ 'aria-label': 'Ny facklig kontakt', role: 'status' }}
      headerContent={headerContent}
      buttonAreaContent={fackligKontaktListItem.buttonArea}
      taBortModalContent={fackligKontaktListItem.taBortModal}
      isExpanded={isExpanded}
      shouldScroll={shouldScroll}
      handleLaggTill={handleLaggTill}
      handleRemove={handleRemove}
      toggleIsExpanded={toggleIsExpanded}
      setShouldScroll={setShouldScroll}
      cardHasErrors={cardHasErrors}
    >
      <FackligKontaktUppgifter index={index} />
    </RemovableCard>
  )
}

export default FackligKontaktListItem
