import * as Yup from 'yup'
import { IAnstallningFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Anstallning/Anstallning.types'
import {
  calculateDate,
  countDecimals,
  dayAfterDate,
  getEarliestOccuringDate,
  getLatestOccuringDate,
  octoberStart,
  startDate,
  today,
  yesterday,
} from '@local/Utils/Helpers'
import {
  AnsokanKeys,
  ICommonAnsokanValideringContent,
  ICommonDatumValideringContent,
  ISelectOption,
  RadioChoice,
} from '@local/Common.types'
import { getYupCurrentSchemaValues } from '@local/Utils/Helpers/Forms/Forms.helpers'
import { addDays, isAfter, isBefore, isValid, subDays } from 'date-fns'
import { toTrrDateOrDefault } from '@local/Utils/Helpers/formatDate'
import { ISkapaAnsokanFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import { anstallningRiskValidationSchema } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Anstallning/Anstallningrisk.schema'
import { anstallningTioTbValidationSchema } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Anstallning/AnstallningTioTb.schema'

export const anstallningValidationSchema = (
  ansokanValidering: ICommonAnsokanValideringContent,
  datumValidering: ICommonDatumValideringContent,
  isRisk: boolean,
  isTB: boolean
): Yup.ObjectSchema<IAnstallningFormValues> => {
  const anstallningschema = Yup.object().shape({
    [AnsokanKeys.Anstallningsdatum]: Yup.date()
      .typeError(ansokanValidering.anstallningsdatumSaknas)
      .required(ansokanValidering.anstallningsdatumSaknas)
      .test('validateMinDate', datumValidering.warningMinDate, (date) =>
        isAfter(date, subDays(startDate, 1))
      )
      .test(
        'maxDateMustBeBeforeToday',
        datumValidering.warningMaxDate,
        (date) => isBefore(date, today)
      )
      .test(
        'maxDateMustBeBeforeEarliestOccuringDate',
        datumValidering.warningMaxDate,
        (date, context) => {
          const schema =
            getYupCurrentSchemaValues<ISkapaAnsokanFormValues>(context)

          const datesToCompareWith = [
            toTrrDateOrDefault(schema.uppsagningsdatum),
            toTrrDateOrDefault(schema.sistaAnstallningsdatum),
          ]

          if (!isTB) {
            datesToCompareWith.push(
              toTrrDateOrDefault(addDays(schema.lonSenastAndrad, 1))
            )
          }

          const earliestDate = getEarliestOccuringDate(datesToCompareWith)

          return isValid(earliestDate) ? isBefore(date, earliestDate) : true
        }
      ),

    [AnsokanKeys.Uppsagningsdatum]: Yup.date()
      .typeError(ansokanValidering.uppsagningsdatumSaknas)
      .required(ansokanValidering.uppsagningsdatumSaknas)
      .test(
        'minDateMustBeAfterLatestOccuringDate',
        datumValidering.warningMinDate,
        (date, context) => {
          const schema =
            getYupCurrentSchemaValues<IAnstallningFormValues>(context)

          const datesToCompare: Date[] = [
            subDays(startDate, 1),
            schema.anstallningsdatum,
          ]

          return isAfter(date, getLatestOccuringDate(datesToCompare))
        }
      )

      .test(
        'maxDateMustBeBeforeEarliestOccuringDate',
        datumValidering.warningMaxDate,
        (date, context) => {
          const schema =
            getYupCurrentSchemaValues<IAnstallningFormValues>(context)

          const dateToCompare = getEarliestOccuringDate([
            toTrrDateOrDefault(addDays(schema.sistaAnstallningsdatum, 1)),
            calculateDate(today, { numberOfMonthsFromDate: 6 }),
          ])

          return isValid(dateToCompare) ? isBefore(date, dateToCompare) : true
        }
      ),

    [AnsokanKeys.SistaAnstallningsdatum]: Yup.date()
      .typeError(ansokanValidering.sistaAnstallningsdatumSaknas)
      .required(ansokanValidering.sistaAnstallningsdatumSaknas)
      .test(
        'minDateMustBeWithinTwoYearsFromToday',
        ansokanValidering.sistaAnstallningsdatumMaxAmountExceeded,
        (date) =>
          isAfter(date, calculateDate(yesterday, { numberOfYearsFromDate: -2 }))
      )
      .test(
        'minDateMustBeAfterLatestOccuringDate',
        datumValidering.warningMinDate,
        (date, context) => {
          const schema =
            getYupCurrentSchemaValues<ISkapaAnsokanFormValues>(context)

          const datesToCompare: Date[] = [
            startDate,
            dayAfterDate(toTrrDateOrDefault(schema.anstallningsdatum)),
            toTrrDateOrDefault(schema.uppsagningsdatum),
            toTrrDateOrDefault(schema.lonSenastAndrad),
          ]

          if (isTB) {
            datesToCompare.push(octoberStart)
          }

          return isAfter(
            date,
            subDays(getLatestOccuringDate(datesToCompare), 1)
          )
        }
      )
      .test(
        'maxDateMustBeBeforeGivenDate',
        datumValidering.warningMaxDate,
        (date) => {
          const dateToCompare = isTB
            ? today
            : calculateDate(today, {
                numberOfYearsFromDate: 3,
                numberOfDaysFromDate: 1,
              })

          return isBefore(date, dateToCompare)
        }
      ),

    [AnsokanKeys.ArbetstimmarPerVecka]: Yup.number()
      .nullable()
      .typeError(ansokanValidering.arbetstimmarSaknas)
      .required(ansokanValidering.arbetstimmarSaknas)
      .positive(ansokanValidering.arbetstimmarSaknas)
      .max(80, ansokanValidering.arbetstimmarMaxAmountExceeded)
      .test(
        'len',
        ansokanValidering.arbetstimmarWrongDecimals,
        (value: number) => countDecimals(value) <= 2
      ),

    [AnsokanKeys.Anstallningsgrad]: Yup.number()
      .nullable()
      .typeError(ansokanValidering.anstallningsgradSaknas)
      .required(ansokanValidering.anstallningsgradSaknas)
      .integer(ansokanValidering.anstallningsgradInvalidFormat)
      .min(5, ansokanValidering.anstallningsgradMinAmountRequired)
      .max(100, ansokanValidering.anstallningsgradMaxAmountRequired),

    [AnsokanKeys.HasSjukersattningsgrad]: Yup.mixed<RadioChoice>()
      .nullable()
      .required(ansokanValidering.sjukersattningsgradSaknas),

    [AnsokanKeys.Sjukersattningsgrad]: Yup.mixed<ISelectOption>()
      .nullable()
      .required(ansokanValidering.sjukersattningsgradBeloppSaknas)
      .when(['hasSjukersattningsgrad'], {
        is: RadioChoice.Nej,
        then: (value) => value.notRequired(),
      }),

    [AnsokanKeys.Uppsagningsgrad]: Yup.number()
      .nullable()
      .typeError(ansokanValidering.uppsagningsgradSaknas)
      .required(ansokanValidering.uppsagningsgradSaknas)
      .integer(ansokanValidering.uppsagningsgradInvalidFormat)
      .min(5, ansokanValidering.uppsagningsgradMinAmountRequired)
      .max(100, ansokanValidering.uppsagningsgradMaxAmountRequired),
  })

  if (isRisk) {
    const riskSchema = anstallningRiskValidationSchema(anstallningschema)
    return anstallningschema.concat(riskSchema)
  }

  if (isTB) {
    const tioTbSchema = anstallningTioTbValidationSchema(anstallningschema)
    return anstallningschema.concat(tioTbSchema)
  }

  return anstallningschema
}
