import { AnsokanKeys } from '@local/Common.types'
import { booleanToRadioChoice } from '@local/Utils/Helpers/Forms/Forms.helpers'
import { toTrrDateOrDefault } from '@local/Utils/Helpers/formatDate'
import { IAnsokan } from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'
import { ILonFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/Lon/Lon.types'

export const mapAnsokanToLonFormValues = (
  ansokan: IAnsokan
): ILonFormValues => ({
  [AnsokanKeys.Avgangsvederlag]: ansokan.loneuppgifter.avgangsvederlag,

  [AnsokanKeys.HasAvgangsvederlag]: booleanToRadioChoice(
    ansokan.hasAvgangsvederlag
  ),
  [AnsokanKeys.LonSenastAndrad]: toTrrDateOrDefault(
    ansokan.loneuppgifter?.lonerevisionsdatum
  ),
  [AnsokanKeys.Manadslon]: ansokan.loneuppgifter.manadslon,
  [AnsokanKeys.ProvisionTantiemBelopp]: ansokan.loneuppgifter.provisionBelopp,
  [AnsokanKeys.SkiftJourOBTillaggBelopp]: ansokan.loneuppgifter.jourBelopp,
})
