import React from 'react'
import { styled } from '@mui/material/styles'
import { AppBar, Grid, Toolbar, ToolbarProps } from '@mui/material'
import FormErrorMessage from '@local/Components/FormErrorMessage'
import { IStickyFooter } from '@local/Components/StickyMuiFooter/types'
import { useIsSmallScreensOrAbove } from '@local/Utils/Hooks'

const StyledToolbar = styled(({ ...props }: ToolbarProps) => (
  <Toolbar {...props} />
))(({ theme }) => ({
  '&.MuiToolbar-root': {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(2)} 0`,

    '&.MuiToolbar-fixed': {
      padding: `${theme.spacing(2)}`,
    },

    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(3)} 0`,

      '&.MuiToolbar-fixed': {
        padding: `${theme.spacing(3)}`,
      },
    },
    '& .MuiGrid-item > *': {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 'unset',
      },
    },

    '& > .MuiGrid-root > .MuiGrid-item:first-of-type > *': {
      marginBottom: theme.spacing(1),

      '&.MuiToolbar-fixed': {
        marginBottom: 0,
      },

      [theme.breakpoints.up('sm')]: {
        marginBottom: 0,
      },
    },
  },
}))

const StickyFooter = ({
  leftElements,
  rightElements,
  isError = false,
  errorMsg,
  position = 'sticky',
}: IStickyFooter): JSX.Element => {
  const isSmallScreensOrAbove = useIsSmallScreensOrAbove()

  return (
    <>
      {position === 'fixed' && <Toolbar sx={{ marginBottom: 6 }} />}

      <AppBar
        position={position}
        color="transparent"
        sx={(theme) => ({
          top: 'auto',
          bottom: 0,
          backgroundColor: theme.palette.common.white,
        })}
        elevation={0}
      >
        <StyledToolbar
          disableGutters
          className={position === 'fixed' && 'MuiToolbar-fixed'}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sm={rightElements ? true : 'auto'}
              order={isSmallScreensOrAbove ? 0 : 1}
            >
              {leftElements}
            </Grid>
            {isError && (
              <Grid
                item
                xs={12}
                sm={rightElements ? 'auto' : true}
                order={isSmallScreensOrAbove ? 1 : 0}
                sx={(theme) => ({
                  display: 'flex',
                  marginRight: rightElements ? theme.spacing(2) : 0,
                  marginLeft: rightElements ? 0 : theme.spacing(2),
                  marginBottom: isSmallScreensOrAbove ? 0 : theme.spacing(1),
                })}
                alignItems="center"
              >
                <FormErrorMessage>{errorMsg}</FormErrorMessage>
              </Grid>
            )}
            <Grid item xs={12} sm="auto" order={2}>
              {rightElements}
            </Grid>
          </Grid>
        </StyledToolbar>
      </AppBar>
    </>
  )
}

export default StickyFooter
