import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import sv from 'date-fns/locale/sv'

type trrDate = (date?: Date) => Date
type trrDateString = (date?: Date | string) => Date

type baseTrrFormat = (formatStr: string) => (date?: Date | string) => string
type DateFormatType = (date?: Date | number) => string

const stockholmTimeZone = 'Europe/Stockholm'

export const toTrrDateOrTrrNow: trrDate = (date) =>
  utcToZonedTime(
    date ? date.toISOString() : new Date().toISOString(),
    stockholmTimeZone
  )

export const toTrrDateOrDefault: trrDateString = (date) =>
  date ? utcToZonedTime(date, stockholmTimeZone) : null

export const trrDateUTC: trrDate = (date) =>
  zonedTimeToUtc(date, stockholmTimeZone)

export const baseTrrFormat: baseTrrFormat =
  (formatStr: string) =>
  (date: Date = new Date()) =>
    format(toTrrDateOrDefault(date), formatStr, {
      timeZone: stockholmTimeZone,
      locale: sv,
    })

export const getDate: DateFormatType = (date: Date | number = new Date()) =>
  format(date, 'yyyy-MM-dd')

export const trrFormat = baseTrrFormat('yyyy-MM-dd')
export const trrFormatOccasionDate = baseTrrFormat('d MMM yyyy')
export const trrFormatTime = baseTrrFormat('H:mm')
export const trrFormatMeetingDateSummary = baseTrrFormat('EEEE d MMMM yyyy')
export const trrFormatMeetingTimeSummary = baseTrrFormat('HH:mm')
