import React from 'react'
import {
  Box,
  Stepper as MuiStepper,
  Step,
  StepButton,
  StepLabel,
} from '@mui/material'
import Gutter from '@local/Components/Gutter'
import { useIsBelowMediumScreens } from '@local/Utils/Hooks'

import { IStepper } from './Stepper.types'

const Stepper = ({
  steps,
  activeStep,
  setActiveStep,
  orientation = 'vertical',
}: IStepper): JSX.Element => {
  const isBelowMediumScreens = useIsBelowMediumScreens()

  return (
    <div role="navigation" aria-label="Steg i formuläret">
      <Box sx={{ width: '100%' }}>
        <MuiStepper nonLinear activeStep={activeStep} orientation={orientation}>
          {steps.map((step) => (
            <Step key={step.title}>
              <StepButton
                disableRipple
                color="inherit"
                onClick={() => setActiveStep(step.step)}
              >
                <StepLabel error={step.stepHasErrors}>{step.title}</StepLabel>
              </StepButton>
            </Step>
          ))}
        </MuiStepper>
      </Box>

      {isBelowMediumScreens && <Gutter offset_xs={24} />}
    </div>
  )
}

export default Stepper
