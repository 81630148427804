import React, { Dispatch, SetStateAction } from 'react'
import { Button, Grid, Tab, Tabs } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { AnsokanState } from '@local/Common.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IDetaljvyContent } from '@local/Views/Detaljvy/Detaljvy.types'
import {
  useCanAccessSkapaAnsokningar,
  useGetUppdrag,
} from '@local/Views/Detaljvy/hooks'

const AnsokanTabs = ({
  activeTab,
  setActiveTabCallback,
  ansokningarPerTab,
}: {
  activeTab: AnsokanState
  setActiveTabCallback: Dispatch<SetStateAction<AnsokanState>>
  ansokningarPerTab: Record<string, number>
}): JSX.Element => {
  const canAccessSkapaAnsokningar = useCanAccessSkapaAnsokningar()
  const { data: uppdrag } = useGetUppdrag()

  const {
    ansokanState,
    ansokanList,
    skapaAnsokningarLank,
    arbetsgivaransokanLinks: { laggTillMedarbetareUrl },
  } = usePickEpiContent<IDetaljvyContent>()

  const tabs: AnsokanState[] = [
    AnsokanState.Utkast,
    AnsokanState.Inskickad,
    AnsokanState.Bedomd,
  ]

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      height={72}
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.palette.neutral?.divider}`,
      })}
    >
      <Grid item>
        <Tabs
          value={activeTab}
          onChange={(e, filterName) => {
            setActiveTabCallback(filterName as AnsokanState)
          }}
          style={{ marginBottom: -24 }}
        >
          {tabs.map((tabName) => (
            <Tab
              label={`${ansokanState[tabName]} (${ansokningarPerTab?.[tabName] || 0})`}
              value={tabName}
              key={tabName}
            />
          ))}
        </Tabs>
      </Grid>
      <Grid item>
        <Grid container columnSpacing={2}>
          <Button
            variant={'contained'}
            startIcon={<AddIcon />}
            sx={{ ml: 2 }}
            href={
              canAccessSkapaAnsokningar
                ? `${skapaAnsokningarLank.url}${uppdrag?.id}/`
                : `${laggTillMedarbetareUrl}${uppdrag?.id}/`
            }
          >
            {ansokanList.filterButtonLaggTillMedarbetareLabel}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AnsokanTabs
