import BeslutChip from '@local/Components/BeslutChip'
import { formatIdentityNumber } from '@local/Utils/Helpers'
import { IAnsokan } from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'
import { AnsokanColumns } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import { Typography } from '@mui/material'
import React from 'react'

export const getAnsokanColumns = (ansokan: IAnsokan): AnsokanColumns => [
  <Typography
    key="personnummer"
    variant="body1"
    noWrap
    sx={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}
  >
    {formatIdentityNumber(ansokan.personuppgifter.personnummer)}
  </Typography>,
  <BeslutChip key="radgivning" beslut={ansokan.beslutRadgivning} />,
  <BeslutChip key="age" beslut={ansokan.beslutAge} />,
  <Typography key="inskickadAv" variant="body1" noWrap>
    {ansokan.inskickadAv}
  </Typography>,
]
