import React from 'react'
import { isNil } from 'ramda'
import { Grid, Typography } from '@mui/material'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { IAnstallningProps } from '@local/Views/UppdragDetaljvy/AnsokanCard/Anstallning/Anstallning.types'
import { IUppdragDetaljvyContent } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import { formatDecimal, formatPercentageNumber } from '@local/Utils/Helpers'
import useGetUppdragIsRisk from '@local/Views/UppdragDetaljvy/useGetUppdragIsRisk'
import { AnsokanType } from '@local/Common.types'

const Omfattning = ({ ansokan }: IAnstallningProps): JSX.Element => {
  const { anstallningsinformation } =
    usePickEpiContent<IUppdragDetaljvyContent>()

  const isRisk = useGetUppdragIsRisk()

  const isTioTbAnsokan =
    ansokan.type === AnsokanType.omstallningTidsbegransadAnstallning

  return (
    <>
      <Grid item py={2}>
        <Typography variant="subtitle1">
          {anstallningsinformation.arbetstimmarPerVecka}
        </Typography>
        <Typography variant="subtitle2">
          {!isNil(ansokan.anstallningsuppgifter.arbetstimmarPerVecka) &&
            `${formatDecimal(ansokan.anstallningsuppgifter.arbetstimmarPerVecka)}${anstallningsinformation.timmar}`}
        </Typography>
      </Grid>

      <Grid item py={2}>
        <Typography variant="subtitle1">
          {anstallningsinformation.anstallningsgrad}
        </Typography>
        <Typography variant="subtitle2">
          {!isNil(ansokan.anstallningsuppgifter.anstallningsgrad) &&
            formatPercentageNumber(
              ansokan.anstallningsuppgifter.anstallningsgrad
            )}
        </Typography>
      </Grid>
      <Grid item py={2}>
        <Typography variant="subtitle1">
          {anstallningsinformation.sjukersattningsgrad}
        </Typography>
        <Typography variant="subtitle2">
          {!isNil(ansokan.loneuppgifter.sjukersattningsgrad) &&
            formatPercentageNumber(ansokan.loneuppgifter.sjukersattningsgrad)}
        </Typography>
      </Grid>
      {!isTioTbAnsokan && (
        <Grid item py={2}>
          <Typography variant="subtitle1">
            {anstallningsinformation.uppsagningsgrad}
          </Typography>
          <Typography variant="subtitle2">
            {isRisk
              ? anstallningsinformation.ejFaststalltLabel
              : !isNil(ansokan.anstallningsuppgifter.uppsagningsgrad) &&
                formatPercentageNumber(
                  ansokan.anstallningsuppgifter.uppsagningsgrad
                )}
          </Typography>
        </Grid>
      )}
    </>
  )
}

export default Omfattning
