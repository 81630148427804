import React from 'react'
import { isNil } from 'ramda'
import { Typography } from '@mui/material'
import { ISkapaAnsokanContent } from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'
import { usePickEpiContent } from '@local/Utils/hooks'
import { useFormikContext } from 'formik'
import { ISkapaAnsokanFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import Gutter from '@local/Components/Gutter'
import NumberInput from '@local/Components/NumberInput/NumberInput'
import Tooltip from '@local/Components/Tooltip'
import HTMLMapper from '@local/Components/HTMLMapper'
import Alert from '@local/Components/Alert'
import { AnsokanKeys } from '@local/Common.types'

const TillaggsBelopp = (): JSX.Element => {
  const formikContext = useFormikContext<ISkapaAnsokanFormValues>()
  const { errors, touched, values } = formikContext

  const {
    lon: {
      provisionTantiemBelopp,
      skiftJourOBTillaggBelopp,
      tooltipProvisionTantiem,
    },
  } = usePickEpiContent<ISkapaAnsokanContent>()

  return (
    <div>
      <Tooltip
        variant="subtitle1"
        heading={provisionTantiemBelopp.heading}
        tooltipContent={<HTMLMapper body={tooltipProvisionTantiem.mainBody} />}
      />

      <Gutter offset_xs={8} />

      <Typography variant="body2">{provisionTantiemBelopp.label}</Typography>

      <Gutter offset_xs={16} />

      <NumberInput
        formikContext={formikContext}
        label={provisionTantiemBelopp.heading}
        placeholder={provisionTantiemBelopp.placeholder}
        name={AnsokanKeys.ProvisionTantiemBelopp}
        value={values.provisionTantiemBelopp}
        error={
          touched?.provisionTantiemBelopp &&
          !isNil(errors?.provisionTantiemBelopp)
        }
        errorMsg={errors?.provisionTantiemBelopp}
      />

      {values.provisionTantiemBelopp >= 20000 && (
        <>
          <Gutter offset_xs={16} />
          <Alert severity="warning">{provisionTantiemBelopp.warning}</Alert>
          <Gutter offset_xs={16} />
        </>
      )}

      <Gutter offset_xs={32} />

      <Typography variant="subtitle1">
        {skiftJourOBTillaggBelopp.heading}
      </Typography>

      <Gutter offset_xs={8} />

      <Typography variant="body2">{skiftJourOBTillaggBelopp.label}</Typography>

      <Gutter offset_xs={16} />

      <NumberInput
        formikContext={formikContext}
        label={skiftJourOBTillaggBelopp.heading}
        placeholder={skiftJourOBTillaggBelopp.placeholder}
        name={AnsokanKeys.SkiftJourOBTillaggBelopp}
        value={values.skiftJourOBTillaggBelopp}
        error={
          touched?.skiftJourOBTillaggBelopp &&
          !isNil(errors?.skiftJourOBTillaggBelopp)
        }
        errorMsg={errors?.skiftJourOBTillaggBelopp}
      />

      {values.skiftJourOBTillaggBelopp >= 20000 && (
        <>
          <Gutter offset_xs={16} />
          <Alert severity="warning">{skiftJourOBTillaggBelopp.warning}</Alert>
          <Gutter offset_xs={16} />
        </>
      )}
    </div>
  )
}

export default TillaggsBelopp
