import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import useGetGroupedAnsokningar from '@local/Views/UppdragDetaljvy/useGetGroupedAnsokningar'
import { IUppdragDetaljvyContent } from '@local/Views/UppdragDetaljvy/UppdragDetaljvy.types'
import SkickaInModal from '@local/Views/UppdragDetaljvy/SkickaIn/SkickaInModal'
import { ISkickaInProps } from '@local/Views/UppdragDetaljvy/SkickaIn/SkickaIn.types'
import { usePostSkickaInAnsokanMutation } from '@local/services/API/uppdrag/uppdragApi'
import { decode } from 'html-entities'
import { removeHtmlTags } from '@local/Utils/Helpers'
import { usePickEpiContent } from '@local/Utils/Hooks'
import Gutter from '@local/Components/Gutter'
import Alert from '@local/Components/Alert'
import { ISkickaInAnsokanApiModel } from '@local/services/API/uppdrag/types'

const SkickaIn = ({ checkedState }: ISkickaInProps): JSX.Element => {
  const { skickaIn, skickaInJuridiskText, nagotGickFel } =
    usePickEpiContent<IUppdragDetaljvyContent>()

  const [postSkickaInAnsokanMutation, { isLoading: isLoadingSkickaInAnsokan }] =
    usePostSkickaInAnsokanMutation()
  const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState(false)
  const [skickaInModalOpen, setSkickaInModalOpen] = useState(false)
  const [shouldResetModalContent, setShouldResetModalContent] = useState(false)

  const { data: groupedAnsokningar, isError } = useGetGroupedAnsokningar()

  useEffect(() => {
    const atLeastOneIsChecked = checkedState.some((checked) => checked)

    if (atLeastOneIsChecked) {
      setShouldShowErrorMessage(false)
    }
  }, [checkedState])

  const handleSend = async () => {
    const checkedAnsokningar = groupedAnsokningar?.fullstandiga?.filter(
      (_, index) => checkedState[index]
    )

    const ansokningar: ISkickaInAnsokanApiModel[] = checkedAnsokningar.map(
      (ansokan) => ({
        ansokanId: ansokan.id,
        villkorstext: decode(removeHtmlTags(skickaInJuridiskText.mainBody)),
      })
    )
    await postSkickaInAnsokanMutation(ansokningar).unwrap()
  }

  const openSkickaInModal = () => {
    if (checkedState.every((checked) => !checked)) {
      setShouldShowErrorMessage(true)
      return
    }

    setShouldResetModalContent(true)
    setShouldShowErrorMessage(false)
    setSkickaInModalOpen(true)
  }

  const closeSkickaInModal = () => {
    setSkickaInModalOpen(false)
    setShouldResetModalContent(false)
  }

  if (isError) {
    return <Alert severity="error">{nagotGickFel.heading}</Alert>
  }

  return (
    <>
      <Button onClick={openSkickaInModal}>{skickaIn.heading}</Button>

      {shouldShowErrorMessage && (
        <>
          <Gutter offset_xs={24} />
          <Alert severity="error">{skickaIn.felmeddelande}</Alert>
        </>
      )}

      <SkickaInModal
        shouldResetModalContent={shouldResetModalContent}
        modalOpen={skickaInModalOpen}
        closeModal={closeSkickaInModal}
        handleSend={handleSend}
        isLoadingSkickaInAnsokan={isLoadingSkickaInAnsokan}
      />
    </>
  )
}

export default SkickaIn
