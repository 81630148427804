import React from 'react'
import { Beslut } from '@local/Common.types'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import ErrorIcon from '@mui/icons-material/Error'

export declare type ChipColor =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'

interface IBeslutChipsColorAndIconReturnProps {
  color: ChipColor
  icon: JSX.Element
}
export const getBeslutColorAndIcon = (
  beslut: Beslut
): IBeslutChipsColorAndIconReturnProps => {
  switch (beslut) {
    case Beslut.BedomningPagar:
    case Beslut.Inskickad:
      return {
        color: 'info',
        icon: <WatchLaterIcon />,
      }
    case Beslut.Godkand:
      return {
        color: 'success',
        icon: <CheckCircleIcon />,
      }
    case Beslut.Avslag:
    case Beslut.AnnanOmstallningsleverantor:
    default:
      return {
        color: 'error',
        icon: <ErrorIcon />,
      }
  }
}
