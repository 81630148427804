import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { ILaggTillMedarbetareContent } from '@local/Views/LaggTillMedarbetare/LaggTillMedarbetare.types'
import { useAppDispatch } from '@local/Store/configureStore'
import Gutter from '@local/Components/Gutter'
import {
  useGetPersonuppgifterQuery,
  usePostCreateAnsokanMutation,
} from '@local/services/API/uppdragApi'
import Spinner from '@local/Components/Spinner'
import { Button, Typography, Grid } from '@mui/material'
import Highlightbox from '@local/Components/Highlightbox/Highlightbox'
import { MedarbetareState, OrsakerTillUppsagning } from '@local/Common.types'
import {
  formatIdentityNumber,
  matchStringByRegex,
  UUIDRegex,
  getAnsokantypByOrsakerTillUppsagning,
  isFetchBaseQueryError,
} from '@local/Utils/Helpers'
import Alert from '@local/Components/Alert'

import {
  selectPersonuppgifterState,
  setShouldGetPersonuppgifter,
} from './personuppgifterSlice'

export interface IPersonUppgifter {
  personnummer: string
  orsak: OrsakerTillUppsagning
}
export interface IHighlightBoxListItem {
  label: string
  value: string
}

const Personuppgifter = ({
  personnummer,
  orsak,
}: IPersonUppgifter): JSX.Element => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const currentUppdragsId = matchStringByRegex(
    window.location.pathname,
    UUIDRegex
  )

  const [postCreateAnsokan] = usePostCreateAnsokanMutation()

  const {
    personnummer: personnummerContent,
    felmeddelandePersonnummer,
    skapaAnsokanKnapp,
    inskickadAnsokanInforuta,
    utkastAnsokanInforuta,
    arbetsgivaransokanLinks: { skapaAnsokanUrl },
  } = usePickEpiContent<ILaggTillMedarbetareContent>()

  const { shouldGetPersonuppgifter } = useSelector(selectPersonuppgifterState)

  const {
    currentData: personData,
    isError,
    isFetching,
    error,
  } = useGetPersonuppgifterQuery(
    {
      uppdragsId: currentUppdragsId,
      personnummer,
    },
    {
      skip: !shouldGetPersonuppgifter,
    }
  )

  const createAnsokan = () => {
    void (async () => {
      dispatch(setShouldGetPersonuppgifter(false))

      const ansokanId = await postCreateAnsokan({
        uppdragsId: currentUppdragsId,
        personData: {
          ...personData,
          ansokanTyp: getAnsokantypByOrsakerTillUppsagning(orsak),
        },
      }).unwrap()
      history.push(`${skapaAnsokanUrl}${ansokanId}/`)
    })()
  }

  const highlightBoxItems: IHighlightBoxListItem[] = [
    {
      label: personnummerContent.personnummer,
      value: personData && formatIdentityNumber(personData.personnummer),
    },
    {
      label: personnummerContent.fornamn,
      value: personData?.fornamn,
    },
    {
      label: personnummerContent.efternamn,
      value: personData?.efternamn,
    },
  ]

  if (isFetching) {
    return <Spinner centered />
  }

  if (isError) {
    if (isFetchBaseQueryError(error, 503)) {
      return (
        <>
          <Gutter offset_xs={24} />
          <Alert severity="error">
            {felmeddelandePersonnummer.sparApiError}
          </Alert>
        </>
      )
    } else
      return (
        <>
          <Gutter offset_xs={24} />
          <Alert severity="error">{felmeddelandePersonnummer.heading}</Alert>
        </>
      )
  }

  return (
    <>
      {personData && (
        <>
          <Gutter offset_xs={24} />
          <Highlightbox>
            {highlightBoxItems.map((item) => (
              <Grid container spacing={1} key={item.value}>
                <Grid item xs="auto">
                  <Typography variant="subtitle1">{item.label}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="body1">{item.value}</Typography>
                </Grid>
              </Grid>
            ))}
          </Highlightbox>

          <Gutter offset_xs={16} />

          {personData.medarbetareState === MedarbetareState.UtkastAnsokan && (
            <>
              <Alert severity="info">{utkastAnsokanInforuta.heading}</Alert>
              <Gutter offset_xs={16} />

              <Button href={`${skapaAnsokanUrl}${personData.ansokanId}/`}>
                {skapaAnsokanKnapp.utkastAnsokanButtonText}
              </Button>
            </>
          )}

          {personData.medarbetareState ===
            MedarbetareState.InskickadAnsokan && (
            <>
              <Alert severity="error">{inskickadAnsokanInforuta.heading}</Alert>
            </>
          )}

          {personData.medarbetareState === MedarbetareState.IngenAnsokan && (
            <Button size="large" onClick={createAnsokan}>
              {skapaAnsokanKnapp.heading}
            </Button>
          )}
        </>
      )}
    </>
  )
}

export default Personuppgifter
