import React from 'react'
import { Typography } from '@mui/material'
import { ISkapaAnsokanContent } from '@local/Views/SkapaAnsokan/SkapaAnsokan.types'
import { usePickEpiContent } from '@local/Utils/Hooks'
import { useFormikContext } from 'formik'
import { ISkapaAnsokanFormValues } from '@local/Views/SkapaAnsokan/SkapaAnsokanForm/SkapaAnsokanForm.types'
import Gutter from '@local/Components/Gutter'
import { getEarliestOccuringDate, today } from '@local/Utils/Helpers'
import { toTrrDateOrDefault } from '@local/Utils/Helpers/formatDate'
import useGetAnsokan from '@local/Views/SkapaAnsokan/useGetAnsokan'
import DateInput from '@local/Components/DateInput/DateInput'
import { isNil } from 'ramda'
import NumberInput from '@local/Components/NumberInput/NumberInput'
import Alert from '@local/Components/Alert'
import { AnsokanKeys, AnsokanType } from '@local/Common.types'

const Loneuppgifter = (): JSX.Element => {
  const formikContext = useFormikContext<ISkapaAnsokanFormValues>()
  const { errors, touched, values } = formikContext

  const {
    lon: { manadslon, lonSenastAndrad, tooltipLonSenastAndrad },
  } = usePickEpiContent<ISkapaAnsokanContent>()

  const { data: ansokan } = useGetAnsokan()

  return (
    <div>
      <Typography variant="subtitle1">{manadslon.heading}</Typography>

      <Gutter offset_xs={8} />

      <NumberInput
        formikContext={formikContext}
        label={manadslon.heading}
        placeholder={manadslon.placeholder}
        name={AnsokanKeys.Manadslon}
        value={values.manadslon}
        error={touched?.manadslon && !isNil(errors?.manadslon)}
        errorMsg={errors?.manadslon}
      />

      {values.manadslon >= 150000 && (
        <>
          <Gutter offset_xs={16} />
          <Alert severity="warning">{manadslon.warning}</Alert>
          <Gutter offset_xs={16} />
        </>
      )}

      {ansokan.type !== AnsokanType.omstallningTidsbegransadAnstallning && (
        <>
          <Gutter offset_xs={32} />

          <DateInput
            value={values.lonSenastAndrad}
            error={touched?.lonSenastAndrad && !isNil(errors?.lonSenastAndrad)}
            errorMsg={errors?.lonSenastAndrad as string}
            tooltipContent={tooltipLonSenastAndrad}
            tooltipVariant="subtitle1"
            formikFieldName={AnsokanKeys.LonSenastAndrad}
            formikContext={formikContext}
            minDate={toTrrDateOrDefault(values.anstallningsdatum)}
            maxDate={getEarliestOccuringDate([
              today,
              toTrrDateOrDefault(values.sistaAnstallningsdatum),
            ])}
            title={lonSenastAndrad.heading}
          />
        </>
      )}
    </div>
  )
}

export default Loneuppgifter
