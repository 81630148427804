import React, { useEffect, useMemo } from 'react'
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-pro'
import { FormikErrors, useFormikContext } from 'formik'
import { usePickEpiContent } from '@local/Utils/hooks'
import { AnsokanKeys } from '@local/Common.types'
import {
  ISkapaAnsokningarContent,
  ISkapaAnsokningarFormValues,
} from '@local/Views/SkapaAnsokningar/types/types'
import {
  baseColumnSettings,
  getCommonCellSettings,
  getCommonColumnSettings,
  renderBaseTextInput,
} from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/helpers/common'
import { Box, Typography } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import { IFilAnsokanRowFormik } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/types/types'
import SkeletonLoading from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/SkeletonLoading/SkeletonLoading'
import { useCheckFileTimestamp } from '@local/Views/SkapaAnsokningar/SkapaAnsokningarForm/Sammanfattning/hooks'
import { isEmpty, isNil, omit } from 'ramda'

const useGetTextCells = () => {
  const {
    sammanfattning: { columnHeaders: columnHeadersContent },
  } = usePickEpiContent<ISkapaAnsokningarContent>()

  const { values, errors, validateForm } =
    useFormikContext<ISkapaAnsokningarFormValues>()
  const { arbitraryTimeHasPassedSinceUpload } = useCheckFileTimestamp()

  useEffect(() => {
    if (arbitraryTimeHasPassedSinceUpload) {
      validateForm(omit(['file', 'laggTillMedarbetareChoice'], values)).catch(
        (err) => console.log(err)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arbitraryTimeHasPassedSinceUpload, validateForm])

  const namn: GridColDef = useMemo(
    () => ({
      ...baseColumnSettings,
      ...getCommonColumnSettings(
        AnsokanKeys.Namn,
        columnHeadersContent,
        values,
        errors,
        false
      ),
      editable: false,
      cellClassName: (params: GridCellParams) => {
        const currentRow: IFilAnsokanRowFormik =
          params.row as IFilAnsokanRowFormik
        const currentValue =
          values.filansokningar[currentRow.index]?.[AnsokanKeys.Namn]

        if (currentValue === '') {
          return arbitraryTimeHasPassedSinceUpload ? 'is-error' : null
        }
      },
      renderCell: (params: GridRenderCellParams) => {
        const currentRow: IFilAnsokanRowFormik =
          params.row as IFilAnsokanRowFormik

        const currentValue =
          values.filansokningar[currentRow.index]?.[AnsokanKeys.Namn]

        let numberOfRowErrors = 0

        const oneOrMoreErrorsExistsInRow =
          errors.filansokningar?.length > currentRow.index &&
          !isNil(errors.filansokningar[currentRow.index])

        if (oneOrMoreErrorsExistsInRow) {
          numberOfRowErrors = Object.keys(
            errors.filansokningar[currentRow.index]
          ).length
        }

        const namnError = (errors?.filansokningar &&
          (errors?.filansokningar[
            currentRow.index
          ] as FormikErrors<IFilAnsokanRowFormik>))?.[AnsokanKeys.Namn]

        const shouldShowErrorIcon =
          (namnError && numberOfRowErrors > 1) ||
          (isNil(namnError) && numberOfRowErrors >= 1)

        return (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {!isNil(currentValue) && !isEmpty(currentValue) ? (
              <Typography
                variant="body1"
                sx={{
                  flex: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {String(currentValue)}
              </Typography>
            ) : (
              <SkeletonLoading />
            )}
            {shouldShowErrorIcon && (
              <ErrorIcon sx={{ marginLeft: '8px' }} color="error" />
            )}
          </Box>
        )
      },
    }),
    [columnHeadersContent, errors, arbitraryTimeHasPassedSinceUpload, values]
  )

  const personnummer: GridColDef = useMemo(
    () => ({
      ...baseColumnSettings,
      ...getCommonColumnSettings(
        AnsokanKeys.Personnummer,
        columnHeadersContent,
        values,
        errors,
        false
      ),
      ...getCommonCellSettings(values, AnsokanKeys.Personnummer),
      renderEditCell: (params: GridRenderEditCellParams) =>
        renderBaseTextInput({
          ansokanKey: AnsokanKeys.Personnummer,
          inputMode: 'numeric',
          currentRowIndex: (params.row as IFilAnsokanRowFormik).index,
        }),

      editable: false,
    }),
    [columnHeadersContent, errors, values]
  )

  const epostadress: GridColDef = useMemo(
    () => ({
      ...baseColumnSettings,
      ...getCommonColumnSettings(
        AnsokanKeys.Epostadress,
        columnHeadersContent,
        values,
        errors
      ),
      ...getCommonCellSettings(values, AnsokanKeys.Epostadress),
      renderEditCell: (params: GridRenderEditCellParams) =>
        renderBaseTextInput({
          ansokanKey: AnsokanKeys.Epostadress,
          inputMode: 'email',
          currentRowIndex: (params.row as IFilAnsokanRowFormik).index,
          withTrim: true,
        }),
    }),
    [columnHeadersContent, errors, values]
  )

  return { namn, personnummer, epostadress }
}

export default useGetTextCells
