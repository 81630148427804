import React, { useCallback, useEffect, useState } from 'react'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import {
  IUppdragContent,
  IUppdragsAnsokanApiModel,
} from '@local/Views/Uppdrag/Uppdrag.types'
import { useGetAnsokanListQuery } from '@local/services/API/ansokan/skapaAnsokanApi'
import { UUIDRegex, matchStringByRegex } from '@local/Utils/helpers'
import Gutter from '@local/Components/Gutter'
import AnsokanTabs from '@local/Views/Uppdrag/AnsokanList/AnsokanTabs'
import AnsokanCard from '@local/Views/Uppdrag/AnsokanList/AnsokanCard'
import IngaRaderOverlay from '@local/Views/Uppdrag/AnsokanList/IngaRaderOverlay'
import {
  useGetColumnHeaders,
  useResetSelectedRowsOnTabChange,
  useSkickaInModal,
  useTaBortModal,
} from '@local/Views/Uppdrag/AnsokanList/hooks'
import { AnsokanState } from '@local/Common.types'
import {
  GridRowParams,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import MarkeradeAnsokningar from '@local/Views/Uppdrag/AnsokanList/MarkeradeAnsokningar'
import SkickaInModal from '@local/Views/Uppdrag/AnsokanList/SkickaInModal'
import { StyledDataGrid } from '@local/Views/Uppdrag/AnsokanList/styles'
import { getRowsPerTab } from '@local/Views/Uppdrag/AnsokanList/helpers'
import RedigeraInskickadAnsokanModal from '@local/Views/Uppdrag/AnsokanList/RedigeraInskickadAnsokanModal'
import { usePickEpiContent } from '@local/Utils/hooks'
import { useHistory } from 'react-router-dom'
import TabortModal from '@local/Views/Uppdrag/AnsokanList/TaBortModal'
import { useSelector } from 'react-redux'
import {
  resetActiveTab,
  selectAnsokanList,
} from '@local/Views/Uppdrag/AnsokanList/ansokanListSlice'
import { useAppDispatch } from '@local/Store/configureStore'
import Toaster from '@local/Components/Toaster'

const AnsokanList = (): JSX.Element => {
  const apiRef = useGridApiRef()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [toasterOpen, setToasterOpen] = useState(false)

  const { activeTab } = useSelector(selectAnsokanList)

  const { arbetsgivaransokanLinks, nagotGickFel } =
    usePickEpiContent<IUppdragContent>()

  const [markeradeAnsokningar, setMarkeradeAnsokningar] = useState<
    IUppdragsAnsokanApiModel[]
  >([])
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)
  const [redigeraModalOpen, setRedigeraModalOpen] = useState<boolean>(false)
  const [currentAnsokanId, setCurrentAnsokanId] = useState<string>(null)

  const [ansokningarPerTab, setAnsokningarPerTab] = useState<Record<
    string,
    number
  > | null>(null)

  const currentUppdragsId = matchStringByRegex(location.pathname, UUIDRegex)
  const {
    data: rows,
    isLoading,
    isError,
  } = useGetAnsokanListQuery({
    uppdragId: currentUppdragsId,
  })

  useEffect(() => {
    if (isError) {
      setToasterOpen(true)
    } else {
      setToasterOpen(false)
    }
  }, [isError])

  const {
    skickaInModalIsOpen,
    openSkickaInModal,
    shouldResetModalContent,
    closeSkickaInModal,
  } = useSkickaInModal()

  const { taBortModalIsOpen, openTaBortModal, closeTaBortModal } =
    useTaBortModal()

  useResetSelectedRowsOnTabChange({ apiRef, activeTab })

  const getAnsokningarPerTab = useCallback(() => {
    if (rows) {
      return getRowsPerTab(rows)
    }
    return {}
  }, [rows])

  useEffect(
    () => () => {
      dispatch(resetActiveTab())
    },
    [dispatch]
  )

  useEffect(() => {
    const counted = getAnsokningarPerTab()
    setAnsokningarPerTab(counted)
  }, [getAnsokningarPerTab, setAnsokningarPerTab])

  const redigeraButtonCallback = useCallback(
    (ansokan: IUppdragsAnsokanApiModel) => {
      setCurrentAnsokanId(ansokan.id)
      if (
        ansokan.ansokanState === AnsokanState.Inskickad ||
        ansokan.ansokanState === AnsokanState.KompletteraRisk
      ) {
        setRedigeraModalOpen(true)
      } else {
        history.push(`${arbetsgivaransokanLinks.skapaAnsokanUrl}${ansokan.id}/`)
      }
    },
    [arbetsgivaransokanLinks.skapaAnsokanUrl, history]
  )

  const { columnHeaders } = useGetColumnHeaders(redigeraButtonCallback)

  const onRowsSelectionHandler = useCallback(
    (ids: GridRowSelectionModel) => {
      const selectedRowsData = ids.map((id) =>
        rows.find((row) => row.id === id)
      )

      setMarkeradeAnsokningar(selectedRowsData)
    },
    [rows]
  )

  useEffect(() => {
    if (markeradeAnsokningar.length > 0 && !skickaInModalIsOpen) {
      setSnackbarOpen(true)
    } else {
      setSnackbarOpen(false)
    }
  }, [markeradeAnsokningar, skickaInModalIsOpen])

  return (
    <>
      <Toaster
        message={nagotGickFel.heading}
        open={toasterOpen}
        setOpen={setToasterOpen}
      />
      <AnsokanTabs ansokningarPerTab={ansokningarPerTab} />

      <Gutter offset_xs={24} />

      <StyledDataGrid
        apiRef={apiRef}
        loading={isLoading}
        rows={rows ?? []}
        columns={columnHeaders}
        checkboxSelection={activeTab === AnsokanState.Utkast}
        checkboxSelectionVisibleOnly
        isRowSelectable={(params: GridRowParams<IUppdragsAnsokanApiModel>) =>
          params.row.ansokanState === AnsokanState.Fullstandig ||
          params.row.ansokanState === AnsokanState.Utkast
        }
        onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
        pagination
        pageSizeOptions={[5, 10, 25, 50]}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
          columns: {
            columnVisibilityModel: { ansokanState: false },
          },
        }}
        slots={{
          detailPanelExpandIcon: ExpandMoreRoundedIcon,
          detailPanelCollapseIcon: ExpandLessRoundedIcon,
          noRowsOverlay: () => <IngaRaderOverlay />,
          noResultsOverlay: () => <IngaRaderOverlay />,
        }}
        filterModel={{
          items: [
            { field: 'ansokanState', operator: 'contains', value: activeTab },
          ],
        }}
        getDetailPanelContent={({ row }: { row: IUppdragsAnsokanApiModel }) => (
          <AnsokanCard
            row={row}
            redigeraButtonCallback={redigeraButtonCallback}
          />
        )}
        getDetailPanelHeight={() => 'auto'}
        autosizeOnMount={false}
        autoHeight
      />

      <MarkeradeAnsokningar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        markeradeAnsokningar={markeradeAnsokningar}
        openSkickaInModal={openSkickaInModal}
        openTaBortModal={openTaBortModal}
      />

      <SkickaInModal
        apiRef={apiRef}
        skickaInModalIsOpen={skickaInModalIsOpen}
        shouldResetModalContent={shouldResetModalContent}
        markeradeAnsokningar={markeradeAnsokningar}
        setMarkeradeAnsokningar={setMarkeradeAnsokningar}
        closeSkickaInModal={closeSkickaInModal}
      />

      <TabortModal
        apiRef={apiRef}
        taBortModalIsOpen={taBortModalIsOpen}
        markeradeAnsokningar={markeradeAnsokningar}
        setMarkeradeAnsokningar={setMarkeradeAnsokningar}
        closeTaBortModal={closeTaBortModal}
      />

      <RedigeraInskickadAnsokanModal
        modalOpen={redigeraModalOpen}
        setModalOpen={setRedigeraModalOpen}
        ansokanId={currentAnsokanId}
      />
    </>
  )
}

export default AnsokanList
