import React from 'react'
import { AppShellRouter, KeyRoute } from '@local/Router'
import DinaUppdrag from '@local/Views/DinaUppdrag'
import LaggTillMedarbetare from '@local/Views/LaggTillMedarbetare'
import KompletteraRiskansokan from '@local/Views/KompletteraRiskansokan'
import SkapaAnsokan from '@local/Views/SkapaAnsokan'
import Uppdrag from '@local/Views/Uppdrag'
import { RedigeraUppdrag, SkapaUppdrag } from '@local/Views/UppdragFormWrapper'
import { AppShellDataProps } from '@trr/app-shell-data'
import SkapaAnsokningar from '@local/Views/SkapaAnsokningar'
import { usePickEpiContent } from '@local/Utils/hooks'
import { UUIDRegex, matchStringByRegex } from '@local/Utils/regexes'
import { IUppdragContent } from '@local/Views/Uppdrag/Uppdrag.types'
import { Redirect } from 'react-router-dom'

const Views = ({
  appShellData,
}: {
  appShellData: AppShellDataProps
}): JSX.Element => {
  const { arbetsgivaransokanLinks } = usePickEpiContent<IUppdragContent>()
  const uppdragsId = matchStringByRegex(location.pathname, UUIDRegex)

  return (
    <AppShellRouter currentKey={appShellData.currentKey}>
      <KeyRoute urlKey="dina-uppdrag">
        <DinaUppdrag />
      </KeyRoute>
      <KeyRoute urlKey="uppdrag">
        <Uppdrag />
      </KeyRoute>
      <KeyRoute urlKey="detaljvy">
        <Redirect to={`${arbetsgivaransokanLinks.uppdragUrl}${uppdragsId}/`} />
      </KeyRoute>
      <KeyRoute urlKey="lagg-till-medarbetare">
        <LaggTillMedarbetare />
      </KeyRoute>
      <KeyRoute urlKey="skapa-ansokan">
        <SkapaAnsokan />
      </KeyRoute>
      <KeyRoute urlKey="skapa-uppdrag">
        <SkapaUppdrag />
      </KeyRoute>
      <KeyRoute urlKey="redigera-uppdrag">
        <RedigeraUppdrag />
      </KeyRoute>
      <KeyRoute urlKey="komplettera-riskansokan">
        <KompletteraRiskansokan />
      </KeyRoute>
      <KeyRoute urlKey="skapa-ansokningar">
        <SkapaAnsokningar />
      </KeyRoute>
    </AppShellRouter>
  )
}
export default Views
