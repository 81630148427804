import { OrsakerTillUppsagning } from '@local/Common.types'
import {
  useIsMediumScreensOrAbove,
  usePickEpiContent,
} from '@local/Utils/Hooks'
import { IDetaljvyContent } from '@local/Views/Detaljvy/Detaljvy.types'
import useGetUppdrag from '@local/Views/UppdragDetaljvy/useGetUppdrag'
import { isNil } from 'ramda'

const useCanAccessSkapaAnsokningar = (): boolean => {
  const isMediumScreensOrAbove = useIsMediumScreensOrAbove()

  const { data: uppdrag } = useGetUppdrag()

  const { skapaAnsokningarLank } = usePickEpiContent<IDetaljvyContent>()

  const isArbetsbrist =
    uppdrag?.orsakTillUppsagning === OrsakerTillUppsagning.Arbetsbrist

  const isOverenskommelseWithFacklig =
    uppdrag?.orsakTillUppsagning === OrsakerTillUppsagning.Overenskommelse &&
    uppdrag?.fackligaKontakter?.length > 0

  if (
    isMediumScreensOrAbove &&
    !isNil(skapaAnsokningarLank) &&
    (isArbetsbrist || isOverenskommelseWithFacklig)
  ) {
    return true
  }

  return false
}

export default useCanAccessSkapaAnsokningar
